<template>
  <div class="text-center">
    <v-avatar
      size="35"
      :class="activeClass('de') + ' language-flag ma-1'"
      @click="changeLanguage('de')"
    >
      <img
        src="@/assets/de.png"
        alt="German flag"
        title="Inhalte auf Deutsch zeigen"
      />
    </v-avatar>

    <v-avatar
      size="35"
      :class="activeClass('en') + ' language-flag ma-1'"
      @click="changeLanguage('en')"
    >
      <img
        src="@/assets/en.png"
        alt="English flag"
        title="Show content in English"
      />
    </v-avatar>

    <v-avatar
      size="35"
      :class="activeClass('nl') + ' language-flag ma-1'"
      @click="changeLanguage('nl')"
    >
      <img
        src="@/assets/nl.png"
        alt="Dutch flag"
        title="Inhoud in het Nederlands weergeven"
      />
    </v-avatar>
  </div>
</template>

<script>
export default {
  data: () => ({
    items: [
      { title: "Deutsch", lang: "de" },
      { title: "English", lang: "en" },
      { title: "Nederlandse", lang: "nl" },
    ],
  }),

  watch: {
    "$store.state.auth.lang"() {
      this.currentLanguage();
    },
  },

  methods: {
    activeClass(lang) {
      if (this.$store.getters["auth/lang"] === lang) {
        return "active";
      }
    },

    changeLanguage(language) {
      this.$store.commit("auth/SET_LANG", language);
      this.$i18n.locale = language; // change the locale: https://kazupon.github.io/vue-i18n/guide/locale.html
    },

    currentLanguage() {
      let curLang = this.$store.getters["auth/lang"];

      if (curLang === "de") {
        return "Deutsch";
      } else if (curLang === "en") {
        return "English";
      } else if (curLang === "nl") {
        return "Nederlandse";
      }
    },
  },
};
</script>

<style scoped>
.language-flag {
  cursor: pointer;
  opacity: 0.2;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

.language-flag.active {
  opacity: 1;
}
</style>
