<template>
  <v-container>
    <PageHeader
      :pageTitle="pageTitle"
      :pageDescription="$t('pageDescriptions.dashboard')"
    />

    <v-row>
      <v-col cols="12" v-if="showSpecialNotification">
        <SpecialNotification />
      </v-col>

      <v-col cols="12">
        <FaceforceNews :highlightsOnly="true" />
      </v-col>

      <v-col cols="12" md="6" lg="6">
        <ChartLeadsLast5 />
      </v-col>

      <v-col cols="12" md="6" lg="6">
        <Last5Campaigns />
      </v-col>

      <v-col cols="12" md="6" lg="6">
        <LastLeads />
      </v-col>

      <v-col cols="12" md="6" lg="6">
        <FaceforceNews />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader.vue";
import FaceforceNews from "@/components/dashboard/FaceforceNews.vue";
import ChartLeadsLast5 from "@/components/dashboard/ChartLeadsLast5.vue";
import Last5Campaigns from "@/components/dashboard/Last5Campaigns.vue";
import LastLeads from "@/components/dashboard/LastLeads.vue";
import SpecialNotification from "@/components/dashboard/SpecialNotification.vue";

export default {
  name: "Dashboard",
  components: {
    PageHeader,
    FaceforceNews,
    ChartLeadsLast5,
    Last5Campaigns,
    LastLeads,
    SpecialNotification,
  },
  data: () => ({
    showSpecialNotification: false,
    pageTitle: "Dashboard",
    pageDescription: null,
    loading: false,
    selection: 1,
  }),

  created: function() {
    // allow to change company?
    this.$store.commit("companyChangeOption/SET_ALLOWED", true);
  },

  methods: {
    //
  },
};
</script>
