<template>
  <v-card :loading="fetchingData">
    <v-toolbar dense dark color="dark">
      <v-toolbar-title> {{ $t("ObaResults") }} </v-toolbar-title>
    </v-toolbar>

    <v-card-text v-if="!fetchingData">
      <h3 class="my-4">{{ $t("poll") }}: {{ data[0].obaName }}</h3>

      <v-simple-table dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">
                {{ $t("question") }}
              </th>
              <th class="text-left">
                {{ $t("answer") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in data" :key="item.uuid">
              <td>{{ item.question }}</td>
              <td>{{ answerOutput(item.answer) }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>

    <v-card-text v-else>
      <v-skeleton-loader
        type="list-item-avatar-three-line, image, article"
      ></v-skeleton-loader>
    </v-card-text>
  </v-card>
</template>

<script>
import store from "@/store";
import manageApi from "@/mixins/manageApi"; // import mixin (for api requests)
import helpers from "@/mixins/helpers"; // import mixin (for api requests)
export default {
  name: "ObaResults",

  components: {},

  props: ["leadId"],

  data() {
    return {
      fetchingData: false, // should be true when fetching data
      data: [],
      companyID: store.getters["auth/current_sid"],
    };
  },

  created: function () {
    // is a company changer allowed?
    this.$store.commit("companyChangeOption/SET_ALLOWED", false);
    this.getData();
  },

  methods: {
    async getData() {
      this.fetchingData = true;
      let response = await this.getRequest(`obas/leads/${this.leadId}`);
      this.fetchingData = false;

      if (response.status === 200) {
        this.data = response.data.data;
      }
    },

    answerOutput(answer) {
      if (Array.isArray(answer)) {
        let answerString = "";

        answer.forEach((item) => {
          if (answerString === "") {
            answerString = item;
          } else {
            answerString += ", " + item;
          }
        });

        return answerString;
      } else {
        return answer;
      }
    },
  },

  computed: {},

  mixins: [manageApi, helpers],
};
</script>
