<template>
  <div class="mt-4" v-if="!fetchingData">
    <v-row>
      <v-col class="col-12 col-md-6 col-lg-6">
        <LeadBaseDataCard
          :id="id"
          :data="leadData"
          @updatedBaseData="componentKey += 1"
        ></LeadBaseDataCard>
      </v-col>

      <v-col class="col-12 col-md-6 col-lg-6" style="flex-direction: column">
        <LeadNotesCard :id="id" :key="componentKey"></LeadNotesCard>
      </v-col>

      <v-col class="col-12" style="flex-direction: column">
        <LeadActivityCard :id="id" :leadData="leadData"></LeadActivityCard>
        <!-- prop has to be "leadData", bcause "data" is used otherwise in component -->
      </v-col>
    </v-row>
  </div>
  <div v-else>
    <v-row class="my-6">
      <v-col cols="12" md="6">
        <v-skeleton-loader type="card"></v-skeleton-loader>
      </v-col>
      <v-col cols="12" md="6">
        <v-skeleton-loader type="card"></v-skeleton-loader>
      </v-col>
      <v-col cols="12">
        <v-skeleton-loader type="card"></v-skeleton-loader>
      </v-col>
    </v-row>
  </div>
</template>

<script>
/* Old version of LeadDetail */
import store from "@/store";
import LeadBaseDataCard from "@/components/lead/old/LeadBaseDataCard";
import LeadNotesCard from "@/components/lead/old/LeadNotesCard";
import LeadActivityCard from "@/components/lead/old/LeadActivityCard";
export default {
  name: "LeadDetailOld",

  components: {
    LeadBaseDataCard,
    LeadNotesCard,
    LeadActivityCard,
  },

  data() {
    return {
      companyID: store.getters["auth/current_sid"],
      newAdmin: store.getters["auth/newAdmin"],
      pageTitle: "Title",
      pageDescription: "",
      fetchingData: false, // should be true when fetching data
      componentKey: 0,
    };
  },

  props: ["id", "leadData"],
  methods: {},
};
</script>
