<template>
  <v-card :loading="loading" class="mx-auto">
    <template slot="progress">
      <v-progress-linear
        color="primary"
        height="5"
        indeterminate
      ></v-progress-linear>
    </template>
    <v-card-title
      v-if="highlightsOnly"
      class="green white--text caption font-weight-bold text-uppercase"
    >
      Highlights
    </v-card-title>
    <v-card-title
      v-else
      class="mitfitDark white--text caption font-weight-bold text-uppercase"
    >
      {{ $t("news") }}
    </v-card-title>

    <v-card-text class="mt-4">
      <v-list two-line>
        <v-list-item
          v-for="item in data"
          :key="item.id"
          @click="openNews(item)"
          :class="readBefore(item.id)"
        >
          <v-list-item-avatar v-if="item.mediaUrl" size="50">
            <v-img :src="item.mediaUrl"></v-img>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title
              :class="
                highlightsOnly ? 'text-h7 text-md-h6 text-lg-h6' : 'text-h7'
              "
            >
              {{ item.title }}
            </v-list-item-title>
            <p v-if="item.exerpt && highlightsOnly">{{ item.exerpt }}</p>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-text>

    <v-dialog
      v-model="dialog"
      max-width="1024px"
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card>
        <v-toolbar dark color="primary" elevation="0">
          <v-btn icon dark @click="dialog = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
          <v-toolbar-title>News</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-container style="max-width: 1500px; min-height: 90vh" fluid>
            <NewsDetail
              ref="news_detail"
              :data="newsData"
              :id="newsId"
              :key="componentKey"
            ></NewsDetail>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import manageApi from "@/mixins/manageApi";
import NewsDetail from "@/components/dashboard/NewsDetail";
export default {
  name: "FaceforceNews",

  components: {
    NewsDetail,
  },

  data() {
    return {
      loading: false,
      dialog: false,
      data: [],
      componentKey: 0,
      newsId: null,
      newsData: [],
      readList: [],
      item: null,
    };
  },

  props: ["highlightsOnly"],

  created: function () {
    this.$store.watch(
      () => this.$store.getters["auth/current_sid"],
      (value) => {
        this.currentCompanyId = value;
        this.getData();
      }
    );

    this.getData();
  },

  methods: {
    readBefore(id) {
      const readArticles = this.$store.getters["news/readArticles"];

      if (readArticles.includes(id)) {
        return "read";
      }

      return "";
    },

    openNews(item) {
      this.newsId = item.id;
      this.newsData = item;

      /* VUEX news (readList) */
      const readArticles = this.$store.getters["news/readArticles"];
      if (!readArticles.includes(item.id)) {
        this.$store.commit("news/ADD_READ_ARTICLE", item.id); // add id to readArticles
      }

      this.dialog = true;
      this.componentKey += 1; // forces the component (here LeadDetail) to re-render -> https://michaelnthiessen.com/force-re-render/
    },

    async getData() {
      let url = "public/ffnews";

      if (this.highlightsOnly) {
        url = "public/ffnews?filter[isFeatured]=1";
      }

      this.loading = true;
      let response = await this.getRequest(url);
      this.loading = false;

      this.data = response.data.data.reverse();
      this.readList = this.$store.getters["news/readArticles"];
    },
  },

  computed: {},

  mixins: [manageApi],
};
</script>

<style scoped>
.read .v-list-item__title {
  color: #aaaaaa !important;
}
</style>
