import Vue from 'vue';
import Vuetify from 'vuetify/lib'
import store from '../store';

Vue.use(Vuetify);

import de from 'vuetify/lib/locale/de'
import en from 'vuetify/lib/locale/en'
import nl from 'vuetify/lib/locale/nl'
/* https://vuetifyjs.com/en/features/internationalization/#vue-i18n */

function envColor() {

  const env = process.env.VUE_APP_ENVIROMENT;

  if (env === "devel") {
    return "#E91E63";
  } else if (env === "test") {
    return "#FF9800";
  } else if (env === "preprod") {
    return "#009688";
  } else {
    return "#7CAF2A";
  }
}

export default new Vuetify({
  lang: {
    locales: { de, en, nl },
    current: store.getters['auth/lang'],
  },
  theme: {
    dark: false,
    themes: {
      light: {
        primary: envColor(),
        secondary: '#b0bec5',
        accent: '#8c9eff',
        error: '#b71c1c',
        facebook: '#2D88FF',
        lightgray: '#eeeeee',
        white: '#ffffff',
        mitfitDark: '#282D35',
        dark: '#282D35',
        green: '#63A33C',
      },
    }
  }

});