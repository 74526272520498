<template>
  <div class="mt-4">
    <template v-if="!requested">
      <h3>Passwort vergessen?</h3>
      <p>
        Kein Problem. Trage einfach deine E-Mail Adresse in das Feld und klicke
        auf "Passwort zurücksetzen"
      </p>

      <v-form ref="form" v-model="isFormValid">
        <v-row>
          <v-col cols="12">
            <v-text-field
              label="Mail"
              v-model="email"
              type="email"
              prepend-inner-icon="mdi-at"
              :rules="emailRules"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <div class="d-flex align-end flex-column">
              <v-row>
                <v-btn
                  v-if="isFormValid"
                  color="green"
                  dark
                  @click="sendData"
                  :loading="fetchinData"
                  >Passwort zurücksetzen</v-btn
                >
                <v-btn v-else disabled>Passwort zurücksetzen</v-btn>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </v-form>
    </template>

    <template v-else>
      <template v-if="!requestError">
        <h3>Geschafft!</h3>
        <p>
          Sofern die E-Mail-Adresse zu einem gültigen Konto gehört, wurde eine
          Nachricht mit weiteren Anweisungen zur Passwort-Rücksetzung an diese
          geschickt.
        </p>
        <v-btn color="green" dark @click="$emit('requested', true)">OK</v-btn>
      </template>

      <template v-else>
        <h3>Geschafft!</h3>
        <p>
          Sofern die E-Mail-Adresse zu einem gültigen Konto gehört, wurde eine
          Nachricht mit weiteren Anweisungen zur Passwort-Rücksetzung an diese
          geschickt.
        </p>
        <v-btn color="green" dark @click="$emit('requested', true)">OK</v-btn>
      </template>
    </template>
  </div>
</template>

<script>
import manageApi from "@/mixins/manageApi"; // import mixin (for api requests)
export default {
  name: "LeadAddManual",

  data() {
    return {
      isFormValid: false,
      requested: false,
      requestError: false,
      fetchinData: false, // should be true when fetching data
      email: "",
      emailRules: [
        (v) => !!v || this.$t("required"),
        (v) => /.+@.+\..+/.test(v) || this.$t("mailMustBeValid"),
      ],
    };
  },

  methods: {
    async sendData() {
      this.fetchinData = true;
      let response = await this.getRequest(
        "public/forgotPassword/" + this.email
      ); // await data from mixin
      this.fetchinData = false;

      if (response.status === 200) {
        this.requested = true;
      } else {
        this.requested = true;
        this.requestError = true;
      }
    },
  },

  mixins: [manageApi],
};
</script>
