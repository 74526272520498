<template>
  <div>
    <table class="default">
      <thead>
        <tr>
          <th>{{ $t("question") }}</th>
          <th>{{ $t("answer") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="set in data" :key="set.id">
          <td>{{ set.question }}</td>
          <td>{{ set.answer }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import store from "@/store";
export default {
  name: "PollResults",

  components: {
    //
  },

  data() {
    return {
      companyID: store.getters["auth/current_sid"],
    };
  },

  props: ["data"],

  methods: {
    //
  },

  computed: {
    //
  },
};
</script>
