<template>
  <v-card :loading="fetchingData">
    <v-toolbar dark color="dark">
      <v-toolbar-title>{{ $t("activity") }}</v-toolbar-title>
    </v-toolbar>

    <v-card-text v-if="!fetchingData">
      <v-row>
        <v-col
          class="col-12 col-md-6 col-lg-4"
          v-for="activity in activityData"
          :key="activity.id"
        >
          <LeadActivityDetailsCard
            :data="activity"
            :leadData="leadData"
            :id="id"
            @emitted="emitted"
          />
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-text v-else>
      <v-row>
        <v-col class="col-12 col-md-6 col-lg-4">
          <v-skeleton-loader type="article, actions"></v-skeleton-loader>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import store from "@/store";
import LeadActivityDetailsCard from "@/components/lead/old/LeadActivityDetailsCard";
import apiRequest from "@/mixins/apiRequest"; // import mixin (for api requests)

export default {
  name: "LeadActivityCard",

  components: {
    LeadActivityDetailsCard,
  },

  data() {
    return {
      fetchingData: true,
      pageTitle: "LeadBaseData",
      pageDescription:
        "Pellentesque in ipsum id orci porta dapibus. Cras ultricies ligula sed magna dictum porta.",
      fetchinData: false, // should be true when fetching data
      companyID: store.getters["auth/current_sid"],
      activityData: [], // wrapper for fetched data
    };
  },

  props: ["id", "leadData"],

  created: function () {
    /* starts immediatly when component was created */

    /* VUEX store watcher. It fires when current_sid (current studioID) changes */
    this.$store.watch(
      () => store.getters["auth/current_sid"],
      (value) => {
        this.companyID = value;
        this.getData();
      }
    );

    this.getData();
  },

  methods: {
    emitted(value) {
      /* kommt von ActivityNotes > LeadActivityDetailsCard. via emit gebe ich das event zurück, da ich mangels daten den array nicht mutieren kann.
        durch das emit (2 ebenen) starte ich den fetch erneut */

      if (value === "noteAdded") {
        //this.getData();
      }
    },

    async getData() {
      this.fetchingData = true;
      let response = await this.getRequest(
        "companies/" + this.companyID + "/legacy-personActivity/" + this.id
      );
      this.fetchingData = false;
      this.activityData = response.data.data.person_game_data;
    },
  },
  /* all requests by mixin */
  mixins: [apiRequest],
};
</script>
