<template>
  <v-dialog v-model="dialog" width="500" v-if="buttonVisible">
    <template v-slot:activator="{ on, attrs }">
      <v-fab-transition>
        <v-btn
          v-bind="attrs"
          v-on="on"
          color="primary"
          dark
          fixed
          bottom
          right
          fab
        >
          <v-icon>mdi-help</v-icon>
        </v-btn>
      </v-fab-transition>
    </template>

    <v-card>
      <v-toolbar dark color="primary" elevation="0">
        <v-toolbar-title>{{ title }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="dialog = false"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </v-toolbar>

      <v-card-text v-html="content" class="mt-4"></v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
/* Benutzt wird das meta.help value im router index.js 
Dort ist entweder false - oder der Key der Übersetzung (translations.js) angegeben.
Der Watcher feuert, wenn die Route geändert wird.
*/
export default {
  data() {
    return {
      buttonVisible: true,
      dialog: false,
      title: "",
      content: "",
    };
  },

  created() {
    this.getData();
  },

  watch: {
    // watch if route change
    $route() {
      this.getData();
    },
  },

  methods: {
    getData() {
      if (this.currentRouteHelp) {
        this.buttonVisible = true;
        this.title = this.$t(this.currentRouteHelp + ".title");
        this.content = this.$t(this.currentRouteHelp + ".content");
      } else {
        this.buttonVisible = false;
        this.title = "";
        this.content = "";
      }
    },
  },

  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    currentRouteHelp() {
      return this.$route.meta.help;
    },
  },
};
</script>
