<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn fab dark x-small color="green" v-bind="attrs" v-on="on">
        <v-icon dark>mdi-grease-pencil</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title>{{ $t("baseData") }}</v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-select
                v-model="gender"
                :items="genderItems"
                item-text="name"
                item-value="value"
                :label="$t('gender')"
                required
              ></v-select>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <v-text-field label="Vorname" v-model="firstName"></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <v-text-field label="Nachname" v-model="lastName"></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field label="Telefon" v-model="phone"></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-select
                v-model="isClient"
                :items="statusItems"
                item-text="name"
                item-value="value"
                label="Status"
                required
              ></v-select>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn color="grey" text @click="dialog = false">
          {{ $t("cancel") }}
        </v-btn>

        <v-btn color="green" dark @click="safeData">
          {{ $t("safe") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import manageApi from "@/mixins/manageApi"; // import mixin (for api requests)
import helpers from "@/mixins/helpers"; // import mixin (for api requests)
import store from "@/store";
export default {
  props: ["data"],

  data() {
    return {
      person_id: this.data.id,
      firstName: this.data.firstName,
      lastName: this.data.lastName,
      phone: this.data.phone,
      isClient: this.transformToTrueFalseState(this.data.identityIsClient),
      gender: this.data.gender,
      birthday: this.data.birthday,
      companyID: store.getters["auth/current_sid"],
      genderItems: [
        {
          name: this.$t("female"),
          value: "female",
        },
        {
          name: this.$t("male"),
          value: "male",
        },
        {
          name: this.$t("diverse"),
          value: "diverse",
        },
      ],
      statusItems: [
        {
          name: this.$t("participant"),
          value: "0",
        },
        {
          name: this.$t("client"),
          value: "1",
        },
      ],
      dialog: false,
    };
  },

  methods: {
    async updateClientStatus(value) {
      const params = new URLSearchParams();
      params.append("action", "updateClientStatus");
      params.append("value", value);

      this.fetchinData = true;
      let response = await this.putRequest("person/" + this.person_id, params); // await data from mixin
      this.fetchinData = false;

      if (response.data.status === "ok") {
        this.data.identityIsClient = value;

        /* if (value === '0') {
            this.data.identityIsClient = 'Nein';
          } else {
            this.data.identityIsClient = 'Ja';
          } */

        this.$emit("updatedBaseData", true);
      }
    },

    async safeData() {
      const params = new URLSearchParams();
      params.append("action", "updateClientDetails");
      params.append("gender", this.gender);
      params.append("firstName", this.firstName);
      params.append("lastName", this.lastName);
      params.append("phone", this.phone);
      params.append("isClient", this.isClient);

      this.fetchinData = true;
      let response = await this.putRequest(
        `companies/${this.companyID}/leads/${this.data.id}`,
        params
      ); // await data from mixin
      this.fetchinData = false;

      /* MUTATE the Base-Data directly here.
        The reactive Data transfered as PROP straight to this Component */

      if (response.status === 200) {
        this.data.gender = response.data.data.gender;
        this.data.firstName = response.data.data.firstName;
        this.data.lastName = response.data.data.lastName;
        this.data.phone = response.data.data.phone;
        this.data.identityIsClient = this.isClient;
        this.dialog = false;
      }

      //this.updateClientStatus(this.identityIsClient);

      /* CALL SNACKBAR */
      this.$root.snackbarGlobal.snackbar = true;
      this.$root.snackbarGlobal.snackbarText = this.$t("messageUpdated");
      this.$root.snackbarGlobal.errorMessage = "";
      this.$root.snackbarGlobal.snackbarColor = "primary";
      this.$root.snackbarGlobal.snackbarTimeout = 5000;
    },

    transformToTrueFalseState(value) {
      if (!value) {
        return "0";
      } else {
        if (
          value === "nein" ||
          value === "Nein" ||
          value === "no" ||
          value == null ||
          value == 0
        ) {
          return "0";
        } else {
          return "1";
        }
      }
    },
  },

  mixins: [manageApi, helpers],
};
</script>
