<template>
  <div>
    <v-row justify="center" no-gutters>
      <v-col class="col-12">
        <v-switch
          false-value="nein"
          true-value="ja"
          v-model="data.games_data_gamer_contact"
          inset
          :disabled="newAdmin === '1' ? true : false"
          :label="$t('Contacted')"
          @change="toggleChange('contacted')"
        ></v-switch>
      </v-col>

      <v-col class="col-12">
        <v-switch
          false-value="nein"
          true-value="ja"
          v-model="data.games_data_gamer_scheduled"
          inset
          :disabled="newAdmin === '1' ? true : false"
          :label="$t('Scheduled')"
          @change="toggleChange('scheduled')"
        ></v-switch>
      </v-col>

      <v-col class="col-12">
        <v-switch
          false-value="nein"
          true-value="ja"
          v-if="pay && pay !== '0' && pay != null && pay !== 0"
          v-model="data.games_data_gamer_begun"
          inset
          :disabled="newAdmin === '1' ? true : false"
          :label="$t('Bought')"
          @change="toggleChange('begun')"
        ></v-switch>
        <v-switch
          false-value="nein"
          true-value="ja"
          v-else
          v-model="data.games_data_gamer_begun"
          inset
          :disabled="newAdmin === '1' ? true : false"
          :label="$t('Redeemed')"
          @change="toggleChange('begun')"
        ></v-switch>
      </v-col>

      <v-col class="col-12" v-if="!confettiActive">
        <v-switch
          false-value="0"
          true-value="1"
          v-model="leadData.person_client"
          inset
          :disabled="newAdmin === '1' ? true : false"
          :label="$t('client')"
          @change="toggleChange('client')"
        ></v-switch>
      </v-col>

      <v-col class="col-12" v-else>
        <v-switch
          false-value="0"
          true-value="1"
          v-model="leadData.person_client"
          inset
          :label="$t('client')"
          :disabled="newAdmin === '1' ? true : false"
        ></v-switch>
      </v-col>

      <v-col class="col-12">
        <v-switch
          false-value="nein"
          true-value="ja"
          v-model="data.games_data_gamer_lost"
          inset
          :disabled="newAdmin === '1' ? true : false"
          :label="$t('Lost')"
          @change="toggleChange('lost')"
        ></v-switch>
      </v-col>
      <v-col v-if="newAdmin === '1'" class="col-12 caption">
        {{ $t("legacyBlockNote") }}
      </v-col>
    </v-row>

    <v-dialog v-model="dialog" persistent max-width="600">
      <v-card>
        <v-card-title class="text-h5">
          {{ $t("areYouSure") }}
        </v-card-title>

        <v-card-text v-html="$t('lostHint')"></v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="updateToLost()">{{
            $t("markAsLost")
          }}</v-btn>
          <v-btn color="green darken-1" text @click="cancelLost()">{{
            $t("cancel")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import apiRequest from "@/mixins/apiRequest"; // import mixin (for api requests)
import helpers from "@/mixins/helpers"; // import mixin (for api requests)
import store from "@/store";
export default {
  name: "StateToggles",

  components: {
    //
  },

  data() {
    return {
      confettiActive: false,
      fetchinData: false, // should be true when fetching data
      companyID: store.getters["auth/current_sid"],
      newAdmin: store.getters["auth/newAdmin"],
      dialog: false,
    };
  },

  props: ["data", "pay", "leadData"],

  methods: {
    startConfetti() {
      this.confettiActive = true;
      this.$confetti.start();
      document.getElementById("confetti-canvas").style.zIndex = "99999999";
    },

    stopConfetti() {
      let that = this;
      this.$confetti.stop();
      setTimeout(function () {
        const elem = document.getElementById("confetti-canvas");
        elem.parentNode.removeChild(elem);
        that.confettiActive = false;
      }, 3500);
    },

    cancelLost() {
      this.data.games_data_gamer_lost = "nein";
      this.dialog = false;
    },

    updateToLost() {
      this.updateState("lost", "updateLost", 1);
      this.dialog = false;
    },

    async addNote(note) {
      const formData = new FormData();
      formData.append("note", note);

      this.fetchinData = true;
      let response = await this.postRequest(
        `companies/${this.companyID}/legacy-personActivity/${this.data.games_data_gamerId}/${this.data.games_data_gameid}`,
        formData
      ); // await data from mixin
      this.fetchinData = false;

      if (response.data.status === "ok") {
        /* emit, da ich mangels daten den array nicht mutieren kann.
        durch das emit starte ich den fetch erneut */
        this.$emit("emitted", "noteAdded");
      }
    },

    async updateState(type, action, value) {
      const params = new URLSearchParams();
      params.append("action", action);
      params.append("value", value);
      params.append("campaignId", this.data.games_data_gameid);

      let response = null;

      if (action === "updateClientStatus") {
        this.fetchinData = true;
        response = await this.putRequest(
          "person/" + this.data.games_data_gamerId,
          params
        ); // await data from mixin
        this.fetchinData = false;

        if (response.data.status === "ok") {
          //value = response.data.result.updateFields.value;
          /* MUTATE the Data directly here.
          The reactive Data transfered as PROP straight to this Component */
          this.$set(this.leadData, this.leadData.person_client, value);
          this.$set(this.data, this.data.person_client, value);

          /* Write a note */
          let Noteaction = this.$t("marked");
          if (value === "0") {
            Noteaction = this.$t("demarked");
          }

          let note = `Diese Person wurde mit &raquo;${this.$t(
            type
          )}&laquo; ${Noteaction}`;
          this.addNote(note);
        }
      } else {
        this.fetchinData = true;

        response = await this.putRequest(
          `companies/${this.companyID}/legacy-personActivity/${this.data.games_data_gamerId}/${this.data.games_data_gameid}`,
          params
        ); // await data from mixin
        this.fetchinData = false;

        if (response.data.status === "ok") {
          /* MUTATE the Base-Data directly here.
          The reactive Data transfered as PROP straight to this Component 
          Die API Response beinhaltet das Value wie es sein muss (response.data.result.value).
          Warum wir nicht immer 1/0 genommen haben, weiß ich nicht ...
          */

          if (type === "contacted") {
            this.leadData.games_data_gamer_contact = response.data.result.value;
          } else if (type === "scheduled") {
            this.leadData.games_data_gamer_scheduled =
              response.data.result.value;
          } else if (type === "begun") {
            this.leadData.games_data_gamer_begun = response.data.result.value;
          } else if (type === "lost") {
            this.leadData.games_data_gamer_lost = response.data.result.value;
          }

          /* CALL SNACKBAR */
          this.$root.snackbarGlobal.snackbar = true;
          this.$root.snackbarGlobal.snackbarText = this.$t("messageUpdated");
          this.$root.snackbarGlobal.errorMessage = "";
          this.$root.snackbarGlobal.snackbarColor = "primary";
          this.$root.snackbarGlobal.snackbarTimeout = 5000;

          let Noteaction = this.$t("marked");

          if (value === "0") {
            Noteaction = this.$t("demarked");
          }

          let note = `Diese Person wurde mit &raquo;${this.$t(
            type
          )}&laquo; ${Noteaction}`;
          this.addNote(note);
        }
      }
    },

    toggleChange(type) {
      let value = null;
      let action = null;

      if (type === "contacted") {
        value = this.data.games_data_gamer_contact;

        /* for what reason ever we use here 'ja'/'nein' in GET request */
        if (value === "ja") {
          value = "1";
        } else {
          value = "0";
        }

        action = "updateContacted";
      } else if (type === "scheduled") {
        value = this.data.games_data_gamer_scheduled;

        /* for what reason ever we use here 'ja'/'nein' in GET request */
        if (value === "ja") {
          value = "1";
        } else {
          value = "0";
        }

        action = "updateScheduled";
      } else if (type === "begun") {
        value = this.data.games_data_gamer_begun;

        /* for what reason ever we use here 'ja'/'nein' in GET request */
        if (value === "ja") {
          value = "1";
        } else {
          value = "0";
        }

        action = "updateBegun";
      } else if (type === "client") {
        value = this.leadData.person_client;
        action = "updateClientStatus";
      } else if (type === "lost") {
        value = this.data.games_data_gamer_lost;

        /* for what reason ever we use here 'ja'/'nein' in GET request */
        if (value === "ja") {
          value = "1";
        } else {
          value = "0";
        }

        action = "updateLost";
      }

      if (type === "lost" && value === "1") {
        // ask
        this.dialog = true;
      } else {
        if (type === "client" && value === "1") {
          // Yeah, its a client now. Show confetti!
          this.startConfetti();

          let that = this;

          setTimeout(function () {
            that.stopConfetti();
          }, 2000);
        }

        // do it
        this.updateState(type, action, value);
      }
    },
  },

  computed: {
    //
  },

  mixins: [apiRequest, helpers],
};
</script>
