<template>
  <v-card :loading="loading" class="mx-auto">
    <template slot="progress">
      <v-progress-linear
        color="primary"
        height="5"
        indeterminate
      ></v-progress-linear>
    </template>

    <v-card-title
      class="mitfitDark white--text caption font-weight-bold text-uppercase"
      >{{ $t("leadsLast5Campaigns") }}</v-card-title
    >

    <v-card-text class="mt-4" v-if="hasPermission">
      <Bar
        :chartData="chartData"
        :chartLabels="chartLabels"
        :backgroundColor="backgroundColor"
        :chartLabel="chartLabel"
        v-if="dataProcessed && chartData.length > 0"
      /><!-- wenn daten für chart aufbereitet wurden -->

      <p v-else>{{ $t("noData") }}</p>
    </v-card-text>

    <v-card-text class="mt-4" v-else>
      {{ $t("noPermissions") }}
    </v-card-text>
  </v-card>
</template>

<script>
import store from "@/store";
import apiRequest from "@/mixins/apiRequest";
import Bar from "@/components/charts/Bar";

export default {
  name: "ChartLeadsLast5",

  components: {
    Bar,
  },

  data() {
    return {
      hasPermission: false,
      dataProcessed: false,
      chartData: [],
      chartLabels: [],
      backgroundColor: [],
      chartLabel: this.$t("leadDevelopment"),
      companyID: store.getters["auth/current_sid"],
    };
  },

  created: function () {
    this.$store.watch(
      () => this.$store.getters["auth/current_sid"],
      (value) => {
        this.companyID = value;

        this.dataProcessed = false;
        this.chartData = [];
        this.chartLabels = [];
        this.backgroundColor = [];

        this.getData();
      }
    );

    this.getData();
  },

  methods: {
    async getData() {
      /* USEING REUSEABLE MIXIN METHOD */
      this.loading = true;
      let response = await this.getRequest(
        "companies/" + this.companyID + "/campaigns"
      ); // await data from mixin
      this.loading = false;

      if (response.status === 200) {
        this.hasPermission = true;

        let result = response.data.data;

        if (Object.keys(result.campaign).length > 0) {
          const campaign1 = result.campaign[0];
          const campaign2 = result.campaign[1];
          const campaign3 = result.campaign[2];
          const campaign4 = result.campaign[3];
          const campaign5 = result.campaign[4];

          let c1Title = "";
          let c2Title = "";
          let c3Title = "";
          let c4Title = "";
          let c5Title = "";

          let c1MemberCount = 0;
          let c2MemberCount = 0;
          let c3MemberCount = 0;
          let c4MemberCount = 0;
          let c5MemberCount = 0;

          if (campaign1) {
            c1Title = campaign1.page_title.substr(0, 10) + "...";
            c1MemberCount = campaign1.memberCount;
          }
          if (campaign2) {
            c2Title = campaign2.page_title.substr(0, 10) + "...";
            c2MemberCount = campaign2.memberCount;
          }
          if (campaign3) {
            c3Title = campaign3.page_title.substr(0, 10) + "...";
            c3MemberCount = campaign3.memberCount;
          }
          if (campaign4) {
            c4Title = campaign4.page_title.substr(0, 10) + "...";
            c4MemberCount = campaign4.memberCount;
          }
          if (campaign5) {
            c5Title = campaign5.page_title.substr(0, 10) + "...";
            c5MemberCount = campaign5.memberCount;
          }

          this.chartLabels = [c1Title, c2Title, c3Title, c4Title, c5Title];
          this.chartData = [
            c1MemberCount,
            c2MemberCount,
            c3MemberCount,
            c4MemberCount,
            c5MemberCount,
          ];
          this.backgroundColor = [
            "#8BC34A",
            "#8BC34A",
            "#8BC34A",
            "#8BC34A",
            "#8BC34A",
          ];
          this.borderColor = [
            "#8BC34A",
            "#8BC34A",
            "#8BC34A",
            "#8BC34A",
            "#8BC34A",
          ];

          this.dataProcessed = true;
        } else {
          this.dataProcessed = true;
        }
      } else {
        this.hasPermission = false;
        this.dataProcessed = true;
      }
    },
  },

  computed: {
    //
  },

  mixins: [apiRequest],
};
</script>
