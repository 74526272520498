<script>
/* Bar Chart
Props: chartData, chartColors, chartLabels (Arrays) 
*/
import { Bar } from "vue-chartjs"; /* https://vue-chartjs.org/guide/#browser */

export default {
  extends: Bar,
  data() {
    return {
      data: {
        labels: this.chartLabels,
        datasets: [
          {
            label: this.chartLabel,
            data: this.chartData,
            backgroundColor: this.backgroundColor,
            borderWidth: 1,
          },
        ],
      },
      // beginAtZero: true, https://stackoverflow.com/questions/37922518/how-to-set-start-value-as-0-in-chartjs
      options: {
        responsive: true,
        plugins: {
          legend: {
            position: "top",
          },
          title: {
            display: true,
            text: "Chart.js Line Chart",
          },
        },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
      },
    };
  },

  props: ["chartData", "chartLabels", "backgroundColor", "chartLabel"],

  mounted() {
    this.renderChart(this.data, this.options);
  },
};
</script>
