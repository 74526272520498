<template>
  <div>
    <v-row no-gutters class="mt-4">
      <v-col cols="11">
        <v-form
          ref="form"
          v-model="isFormValid"
          v-on:submit.prevent="safeNote()"
        >
          <v-text-field
            v-model="note"
            :label="$t('addNote')"
            :rules="[rules.required, rules.min]"
            outlined
            clearable
          ></v-text-field>
        </v-form>
      </v-col>
      <v-col cols="1">
        <v-btn
          v-if="isFormValid"
          class="mx-2"
          fab
          dark
          small
          color="green"
          @click="safeNote"
        >
          <v-icon dark>mdi-floppy</v-icon>
        </v-btn>

        <v-btn v-else class="mx-2" fab small color="green" disabled>
          <v-icon dark>mdi-floppy</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-list
      v-if="notes.length > 0"
      max-height="300px"
      min-height="300px"
      style="overflow: auto"
    >
      <template v-for="(note, index) in notes">
        <v-list-item :key="note.id" v-if="showNote(note)">
          <v-list-item-content>
            <v-list-item-title class="font-weight-bold">
              {{ note.firstname }} {{ note.lastname }}, {{ note.created }}
              {{ $t("oClock") }}
            </v-list-item-title>
            <span v-html="note.note" class="mt-1"></span>
          </v-list-item-content>
        </v-list-item>
        <v-divider :key="index"></v-divider>
      </template>
    </v-list>
    <span v-else>
      {{ $t("noNotesExist") }}
    </span>
  </div>
</template>

<script>
import manageApi from "@/mixins/manageApi"; // import mixin (for api requests)
import helpers from "@/mixins/helpers"; // import mixin (for api requests)
import store from "@/store";
export default {
  name: "ActivityNotes",

  props: ["leadId"],

  data() {
    return {
      fetchingData: false, // should be true when fetching data
      companyID: store.getters["auth/current_sid"],
      addNote: this.$t("addNote"),
      note: "",
      isFormValid: false,
      notes: [],
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 3 || "Min 3 characters",
      },
    };
  },

  created: function () {
    this.getData();
  },

  methods: {
    async getData() {
      this.fetchingData = true;
      let response = await this.getRequest(
        "companies/" + this.companyID + "/leads/" + this.leadId + "/notes"
      );
      this.fetchingData = false;

      if (response.status === 200) {
        this.notes = response.data.data;
      }
    },

    async safeNote() {
      if (this.isFormValid) {
        const formData = new FormData();
        formData.append("note", this.note);

        this.fetchingData = true;
        let response = await this.postRequest(
          "companies/" + this.companyID + "/leads/" + this.leadId + "/notes",
          "",
          formData
        );
        this.fetchingData = false;

        if (response.status === 200) {
          /* CALL SNACKBAR */
          this.$root.snackbarGlobal.snackbar = true;
          this.$root.snackbarGlobal.snackbarText = this.$t("messageUpdated");
          this.$root.snackbarGlobal.errorMessage = "";
          this.$root.snackbarGlobal.snackbarColor = "primary";
          this.$root.snackbarGlobal.snackbarTimeout = 5000;

          this.note = "";
          this.$refs.form.resetValidation();

          this.notes.unshift(response.data.data);

          /* via emit gebe ich das event zurück, da ich mangels daten den array nicht mutieren kann.
          durch das emit (2 ebenen) starte ich den fetch erneut */
          //this.$emit("emitted", "noteAdded");
        }
      }
    },

    transformToTrueFalseState(value) {
      if (!value) {
        return false;
      } else {
        if (value === "nein" || value === "no" || value == null || value == 0) {
          return false;
        } else {
          return true;
        }
      }
    },

    showNote(note) {
      // check if note starts with "Leaddaten geändert". If yes return false
      if (!note.admins_id || note.note.startsWith("Leaddaten geändert")) {
        return false;
      } else {
        return true;
      }
    },
  },

  mixins: [manageApi, helpers],
};
</script>
