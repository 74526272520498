<template>
  <v-dialog v-model="dialog" max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn fab dark x-small color="green" v-bind="attrs" v-on="on">
        <v-icon dark>mdi-grease-pencil</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>{{ $t("baseData") }}</v-toolbar-title>
      </v-toolbar>

      <template v-if="newAdmin !== '1'">
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-select
                  v-model="person_sex"
                  :items="genderItems"
                  item-text="name"
                  item-value="value"
                  :label="$t('gender')"
                  required
                ></v-select>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  label="Vorname"
                  v-model="person_firstname"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  label="Nachname"
                  v-model="person_lastname"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  label="Telefon"
                  v-model="person_telefon"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-select
                  v-model="person_client"
                  :items="statusItems"
                  item-text="name"
                  item-value="value"
                  label="Status"
                  required
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="grey" text @click="dialog = false">
            {{ $t("cancel") }}
          </v-btn>

          <v-btn color="green" dark @click="safeData">
            {{ $t("safe") }}
          </v-btn>
        </v-card-actions>
      </template>

      <template v-else>
        <v-card-text class="mt-4">
          {{ $t("legacyBlockNote") }}
        </v-card-text>
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
import apiRequest from "@/mixins/apiRequest"; // import mixin (for api requests)
import helpers from "@/mixins/helpers"; // import mixin (for api requests)
export default {
  props: ["data"],

  data() {
    return {
      newAdmin: this.$store.getters["auth/newAdmin"],
      person_id: this.data.id,
      person_firstname: this.data.person_firstname,
      person_lastname: this.data.person_lastname,
      person_telefon: this.data.person_telefon,
      person_client: this.transformToTrueFalseState(this.data.person_client),
      person_sex: this.data.person_sex,
      genderItems: [
        {
          name: this.$t("female"),
          value: "female",
        },
        {
          name: this.$t("male"),
          value: "male",
        },
        {
          name: this.$t("diverse"),
          value: "diverse",
        },
      ],
      statusItems: [
        {
          name: this.$t("participant"),
          value: "0",
        },
        {
          name: this.$t("client"),
          value: "1",
        },
      ],
      dialog: false,
    };
  },

  methods: {
    async updateClientStatus(value) {
      const params = new URLSearchParams();
      params.append("action", "updateClientStatus");
      params.append("value", value);

      this.fetchinData = true;
      let response = await this.putRequest("person/" + this.person_id, params); // await data from mixin
      this.fetchinData = false;

      if (response.data.status === "ok") {
        this.data.person_client = value;

        /* if (value === '0') {
            this.data.person_client = 'Nein';
          } else {
            this.data.person_client = 'Ja';
          } */

        this.$emit("updatedBaseData", true);
      }
    },

    async safeData() {
      const params = new URLSearchParams();
      params.append("action", "updateClientDetails");
      params.append("person_sex", this.person_sex);
      params.append("person_firstname", this.person_firstname);
      params.append("person_lastname", this.person_lastname);
      params.append("person_telefon", this.person_telefon);

      this.fetchinData = true;
      let response = await this.putRequest("person/" + this.person_id, params); // await data from mixin
      this.fetchinData = false;

      /* MUTATE the Base-Data directly here.
        The reactive Data transfered as PROP straight to this Component */

      if (response.data.status === "ok") {
        this.data.person_sex = this.person_sex;
        this.data.person_firstname = this.person_firstname;
        this.data.person_lastname = this.person_lastname;
        this.data.person_telefon = this.person_telefon;
        this.dialog = false;
      }

      this.updateClientStatus(this.person_client);

      /* CALL SNACKBAR */
      this.$root.snackbarGlobal.snackbar = true;
      this.$root.snackbarGlobal.snackbarText = this.$t("messageUpdated");
      this.$root.snackbarGlobal.errorMessage = "";
      this.$root.snackbarGlobal.snackbarColor = "primary";
      this.$root.snackbarGlobal.snackbarTimeout = 5000;
    },

    transformToTrueFalseState(value) {
      if (!value) {
        return "0";
      } else {
        if (
          value === "nein" ||
          value === "Nein" ||
          value === "no" ||
          value == null ||
          value == 0
        ) {
          return "0";
        } else {
          return "1";
        }
      }
    },
  },

  mixins: [apiRequest, helpers],
};
</script>
