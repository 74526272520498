 /* 
POWERED BY:
https://www.smashingmagazine.com/2020/10/authentication-in-vue-js/
https://www.youtube.com/watch?v=Vd1YwbHrY1Q&list=PLfdtiltiRHWF1jqLcNO_2jWJXj9RuSDvY&t=0s
*/

const state = {
  readArticles: [],
};

const getters = {

  /* Getters are functionalities to get the state. It can be used in multiple components to get the current state. 
  The isAuthenticatated function checks if the state.user is defined or null and returns true or false respectively. 
  StatePosts and StateUser return state.posts and state.user respectively value. */

  readArticles(state) {
    return state.readArticles
  },

};

const actions = {

  /* Actions are functions that are used to commit a mutation to change the state or can be used to dispatch i.e calls another action. 
  It can be called in different components or views and then commits mutations of our state; */

};

const mutations = {

  ADD_READ_ARTICLE(state, data) {
    state.readArticles.push(data) /* add article to read-array */
  }

};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};