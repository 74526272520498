<template>
  <div>
    <h3>{{ $t("Appointment") }}</h3>

    <v-datetime-picker
      :label="$t('chooseDateTimeOfMeeting')"
      v-model="dateTimeValue"
      :timePickerProps="{ format: '24hr' }"
      :text-field-props="textFieldProps"
      @input="dateTimeChanged"
    >
      <template v-slot:dateIcon>
        <v-icon large color="primary darken-2">mdi-calendar</v-icon>
      </template>

      <template v-slot:timeIcon>
        <v-icon large color="primary darken-2">mdi-clock-time-two</v-icon>
      </template>
    </v-datetime-picker>
  </div>
</template>

<script>
import store from "@/store";
import manageApi from "@/mixins/manageApi"; // import mixin (for api requests)
import helpers from "@/mixins/helpers"; // import mixin (for api requests)
export default {
  name: "LeadAppointment",
  components: {},
  props: ["isScheduledDate", "leadId"],
  data() {
    return {
      fetchinData: false, // should be true when fetching data
      companyID: store.getters["auth/current_sid"],
      textFieldProps: {
        prependIcon: "mdi-calendar-month",
      },
      theDate: this.isScheduledDate,
    };
  },

  created: function() {},

  methods: {
    async dateTimeChanged(d) {
      let day = d.getDate();
      let month = d.getMonth() + 1; // January is 0!
      let year = d.getFullYear();
      let h = d.getHours(); //Get the hour (0-23)
      let m = d.getMinutes(); //Get the minute (0-59)
      let s = d.getSeconds(); //Get the second (0-59)

      // leading zero
      if (month.toString().length < 2) month = "0" + month;
      if (day.toString().length < 2) day = "0" + day;
      if (h.toString().length < 2) h = "0" + h;
      if (m.toString().length < 2) m = "0" + m;
      if (s.toString().length < 2) s = "0" + s;

      const dateTime =
        year + "-" + month + "-" + day + " " + h + ":" + m + ":" + s;

      const params = new URLSearchParams();
      params.append("isScheduledDate", dateTime);

      this.fetchinData = true;
      const response = await this.patchRequest(
        `companies/${this.companyID}/leads/${this.leadId}`,
        params
      );
      this.fetchinData = false;

      if (response.status === 200) {
        this.theDate = response.data.data.isScheduledDate;

        this.$emit("dateChange", this.theDate);

        /* CALL SNACKBAR */
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText = this.$t("messageUpdated");
        this.$root.snackbarGlobal.errorMessage = "";
        this.$root.snackbarGlobal.snackbarColor = "primary";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      }
    },
  },

  computed: {
    dateTimeValue: {
      get() {
        /* 
        We got: 2021-09-28 15:50:00
        We need: Tue Sep 28 2021 15:50:00 GMT+0200 (Mitteleuropäische Sommerzeit) 
        */

        const value = this.theDate;

        if (!value || value === "") {
          return null;
        }
        const dateTimeValue = new Date(value);
        return dateTimeValue;
      },

      set: function() {
        return this.theDate;
      },
    },
  },

  mixins: [manageApi, helpers],
};
</script>
