<template>
  <v-card v-if="!fetchingData && data" class="my-2">
    <v-card-text>
      <h2 class="mb-2">Gebuchtes Event</h2>
      <strong>Titel:</strong> {{ data.eventTitle }}<br />
      <strong>Datum:</strong> {{ this.formatDateNoTime(data.eventDate) }}<br />
      <strong>Uhrzeit:</strong> {{ data.eventStartTime }} -
      {{ data.eventEndTime }}<br />
      <strong>Datum der Buchung:</strong> {{ this.formatDate(data.created)
      }}<br />
      <strong>Datum der Bestätigung:</strong>
      {{ this.formatDate(data.confirmationDate) }}
    </v-card-text>
  </v-card>
</template>

<script>
import manageApi from "@/mixins/manageApi"; // import mixin (for api requests)
import helpers from "@/mixins/helpers"; // import mixin (for api requests)
export default {
  name: "LeadBookedDates",
  props: {
    uuid: {
      type: String,
      required: false,
      default: "leads",
    },
    id: {
      type: String,
      required: false,
    },
  },

  data() {
    return {
      data: [],
      fetchingData: false,
    };
  },

  created: async function () {
    this.fetchingData = true;
    let response = await this.getRequest(`leads/${this.id}/bookedDates`);

    if (response.status === 200) {
      this.fetchingData = false;
      this.data = response.data.data[0];
    }
    this.fetchingData = false;
  },

  mixins: [manageApi, helpers],
};
</script>
