<template>
  <div>
    <v-card v-if="!fetchinData">
      <v-card-text>
        <v-row>
          <v-col class="col-12">
            <h3>{{ data.game_title }}</h3>
            <table class="default-table">
              <tbody>
                <tr>
                  <td class="font-weight-bold">{{ $t("campaign") }}</td>
                  <td>
                    {{ data.game_title }}
                    <v-chip
                      v-if="
                        page_has_mitfitpay &&
                        page_has_mitfitpay !== '0' &&
                        page_has_mitfitpay != null &&
                        page_has_mitfitpay !== 0
                      "
                      class="ma-2"
                      color="green"
                      text-color="white"
                    >
                      <v-icon left>mdi-credit-card</v-icon>
                      PAY
                    </v-chip>
                  </td>
                </tr>

                <tr
                  v-if="
                    page_has_mitfitpay &&
                    page_has_mitfitpay !== '0' &&
                    page_has_mitfitpay != null &&
                    page_has_mitfitpay !== 0 &&
                    !fetchinOrderData
                  "
                >
                  <td class="font-weight-bold">{{ $t("payProduct") }}</td>

                  <td v-if="this.games_data_hasOrdered === '1'">
                    <strong>Status:</strong>
                    <v-icon small color="green">mdi-check-circle</v-icon>
                    <span class="green--text">{{ $t("Closed") }}</span
                    ><br />
                    <strong>Vertrag:</strong> {{ boughtItemTitle }}<br />
                    <span v-if="boughtItemPromotionalPrice"
                      ><strong>PROMO:</strong>:
                      {{ boughtItemPromotionalTitle }}
                      {{ boughtItemPromotionalPrice }}
                      {{ boughtItemCurrencyCode }}<br
                    /></span>
                    <strong>Bruttopreis:</strong> {{ boughtItemPriceGross }}
                    {{ boughtItemCurrencyCode }}<br />
                    <strong>Vertrag:</strong>
                    <a :href="boughtItemFileLink" target="_blank" rel="nofollow"
                      >Download</a
                    >
                  </td>

                  <td v-else>
                    <strong>Status:</strong>
                    <v-icon small color="red">mdi-close-circle</v-icon>
                    <span class="red--text">{{ $t("Open") }}</span
                    ><br />
                    <strong>{{ $t("contract") }}:</strong> {{ boughtItemTitle
                    }}<br />
                    <span v-if="boughtItemPromotionalPrice"
                      ><strong>PROMO:</strong>:
                      {{ boughtItemPromotionalTitle }}
                      {{ boughtItemPromotionalPrice }}
                      {{ boughtItemCurrencyCode }}<br
                    /></span>
                    <strong>{{ $t("grossPrice") }}:</strong>
                    {{ boughtItemPriceGross }} {{ boughtItemCurrencyCode
                    }}<br />
                  </td>
                </tr>

                <tr>
                  <td class="font-weight-bold">
                    {{ $t("participationDate") }}
                  </td>
                  <td>
                    {{ data.games_data_participationdate }} {{ $t("oClock") }}
                  </td>
                </tr>

                <tr v-if="data.games_data_gamer_company">
                  <td class="font-weight-bold">{{ $t("company") }}</td>
                  <td>{{ data.games_data_gamer_company }}</td>
                </tr>

                <tr v-if="data.games_data_gamer_custominput">
                  <td class="font-weight-bold">
                    [{{ $t("question") }}]
                    {{ data.page_form_custominput_label }}
                  </td>
                  <td>
                    [{{ $t("answer") }}] {{ data.games_data_gamer_custominput }}
                  </td>
                </tr>

                <tr v-if="data.games_data_gamer_imageriddleanswer">
                  <td class="font-weight-bold">
                    {{ $t("imageriddleanswer") }}
                  </td>
                  <td>{{ data.games_data_gamer_imageriddleanswer }}</td>
                </tr>

                <tr v-if="data.games_data_gamer_pollwin">
                  <td class="font-weight-bold">{{ $t("win") }}</td>
                  <td>{{ data.games_data_gamer_pollwin }}</td>
                </tr>

                <tr v-if="data.games_data_gamer_accessibility">
                  <td class="font-weight-bold">{{ $t("accessibility") }}</td>
                  <td>{{ data.games_data_gamer_accessibility }}</td>
                </tr>

                <tr v-if="data.games_data_gamer_customneeds">
                  <td class="font-weight-bold">{{ $t("customneeds") }}</td>
                  <td>{{ data.games_data_gamer_customneeds }}</td>
                </tr>

                <tr v-if="data.games_data_gamer_customneeds2">
                  <td class="font-weight-bold">{{ $t("customneeds") }} #2</td>
                  <td>{{ data.games_data_gamer_customneeds2 }}</td>
                </tr>

                <tr v-if="data.polls">
                  <td class="font-weight-bold">{{ $t("pollresults") }}</td>
                  <td><PollResults :data="data.polls" /></td>
                </tr>
              </tbody>
            </table>
          </v-col>

          <v-col class="col-12">
            <StateToggles
              :data="data"
              :pay="page_has_mitfitpay"
              :leadData="leadData"
              @emitted="emitted"
            />
          </v-col>

          <v-col class="col-12" v-if="data.games_data_gamer_scheduled === 'ja'">
            <h3>{{ $t("Appointment") }}</h3>

            <v-datetime-picker
              :label="$t('chooseDateTimeOfMeeting')"
              v-model="dateTimeValue"
              :timePickerProps="{ format: '24hr' }"
              :text-field-props="textFieldProps"
              :disabled="newAdmin === '1' ? true : false"
              @input="dateTimeChanged"
            >
              <template v-slot:dateIcon>
                <v-icon large color="primary darken-2">mdi-calendar</v-icon>
              </template>

              <template v-slot:timeIcon>
                <v-icon large color="primary darken-2"
                  >mdi-clock-time-two</v-icon
                >
              </template>
            </v-datetime-picker>

            <v-col v-if="newAdmin === '1'" class="col-12 caption">
              {{ $t("legacyBlockNote") }}
            </v-col>
          </v-col>

          <v-col class="col-12">
            <h3>{{ $t("notes") }}</h3>
            <ActivityNotes
              :notes="data.gameNotes"
              :data="data"
              :id="id"
              :key="componentKey"
              @emitted="emitted"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import store from "@/store";
import apiRequest from "@/mixins/apiRequest"; // import mixin (for api requests)
import helpers from "@/mixins/helpers"; // import mixin (for api requests)
import StateToggles from "@/components/lead/old/StateToggles";
import PollResults from "@/components/lead/old/PollResults";
import ActivityNotes from "@/components/lead/old/ActivityNotes";
export default {
  name: "LeadBaseDataCard",

  components: {
    StateToggles,
    PollResults,
    ActivityNotes,
  },

  data() {
    return {
      componentKey: 0,
      fetchinOrderData: false,
      page_has_mitfitpay: null,
      games_data_hasOrdered: null,
      games_data_orderId: null,
      fetchinData: false, // should be true when fetching data
      companyID: store.getters["auth/current_sid"],
      newAdmin: store.getters["auth/newAdmin"],
      textFieldProps: {
        prependIcon: "mdi-calendar-month",
      },
      boughtItemTitle: null,
      boughtItemPromotionalTitle: null,
      boughtItemPriceGross: null,
      boughtItemFileLink: null,
      boughtItemPromotionalPrice: null,
      boughtItemPromotionalCurrency: null,
      boughtItemCurrencyCode: null,
    };
  },

  props: ["data", "leadData", "id"],

  created: function () {
    // mitfitPAY
    this.page_has_mitfitpay = this.data.game_has_mitfitpay;
    this.games_data_hasOrdered = this.data.games_data_hasOrdered;
    this.games_data_orderId = this.data.games_data_orderId;

    if (
      this.page_has_mitfitpay &&
      this.page_has_mitfitpay !== "0" &&
      this.page_has_mitfitpay != null &&
      this.page_has_mitfitpay !== 0
    ) {
      /* Fetching bought product data */
      this.getBoughtProduct();
    }
  },

  methods: {
    async dateTimeChanged(d) {
      let day = d.getDate();
      let month = d.getMonth() + 1; // January is 0!
      let year = d.getFullYear();
      let h = d.getHours(); //Get the hour (0-23)
      let m = d.getMinutes(); //Get the minute (0-59)
      let s = d.getSeconds(); //Get the second (0-59)

      // leading zero
      if (month.toString().length < 2) month = "0" + month;
      if (day.toString().length < 2) day = "0" + day;
      if (h.toString().length < 2) h = "0" + h;
      if (m.toString().length < 2) m = "0" + m;
      if (s.toString().length < 2) s = "0" + s;

      const dateTime =
        year + "-" + month + "-" + day + " " + h + ":" + m + ":" + s;

      const params = new URLSearchParams();
      params.append("action", "updateScheduled");
      params.append("value", "1");
      params.append("date", dateTime);

      this.fetchinData = true;

      // `companies/${this.companyID}/legacy-personActivity/${this.data.games_data_gamerId}/${this.data.games_data_gameid}`
      const response = await this.putRequest(
        `companies/${this.companyID}/legacy-personActivity/${this.data.games_data_gamerId}/${this.data.games_data_gameid}`,
        params
      );
      this.fetchinData = false;

      if (response.data.status === "ok") {
        this.data.games_data_gamer_scheduled_date =
          response.data.result.dateAfter;

        /* CALL SNACKBAR */
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText = this.$t("messageUpdated");
        this.$root.snackbarGlobal.errorMessage = "";
        this.$root.snackbarGlobal.snackbarColor = "primary";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      }
    },

    emitted(value) {
      /* kommt von ActivityNotes. via emit gebe ich das event zurück, da ich mangels daten den array nicht mutieren kann.
        durch das emit (2 ebenen) starte ich den fetch erneut */

      if (value === "noteAdded") {
        this.componentKey += 1;
        this.$emit("emitted", "noteAdded");
      }
    },

    async getBoughtProduct() {
      this.fetchinOrderData = true;
      let response = await this.getPayRequest(
        "orders/" + this.games_data_orderId
      ); // await data from mixin
      this.fetchinOrderData = false;

      this.boughtItemTitle = response.data.data.items[0].title;
      this.boughtItemPromotionalTitle =
        response.data.data.items[0].promotionalTitle;
      this.boughtItemPriceGross = response.data.data.items[0].priceGross;
      this.boughtItemFileLink = response.data.data.items[0].fileLink;
      this.boughtItemPromotionalPrice =
        response.data.data.items[0].promotionalPrice;
      this.boughtItemCurrencyCode = response.data.data.currencyCode;

      this.fetchinOrderData = false;
    },
  },

  computed: {
    dateTimeValue: {
      get() {
        /* 
        We got: 2021-09-28 15:50:00
        We need: Tue Sep 28 2021 15:50:00 GMT+0200 (Mitteleuropäische Sommerzeit) 
        */

        const value = this.data.games_data_gamer_scheduled_date;

        if (!value || value === "") {
          return null;
        }
        const dateTimeValue = new Date(value);
        return dateTimeValue;
      },

      set: function () {
        return this.data.games_data_gamer_scheduled_date;
      },
    },
  },

  mixins: [apiRequest, helpers],
};
</script>
