<template>
  <v-card class="mx-auto">
    <v-card-title
      class="red white--text caption font-weight-bold text-uppercase"
    >
      <v-icon dark left>
        mdi-gift
      </v-icon>
      Ho, Ho, Ho ...
    </v-card-title>

    <v-card-text
      class="mt-4"
      v-html="$t('plugins.xmasPresent.dashboardMessage')"
    >
    </v-card-text>
    <v-card-actions>
      <router-link to="/xmas">
        <v-btn dark depressed color="green">
          <v-icon left>
            mdi-gift
          </v-icon>
          {{ $t("plugins.xmasPresent.GetYourCodes") }}
        </v-btn>
      </router-link>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "SpecialNotification",

  components: {},

  data() {
    return {};
  },

  props: [],

  created: function() {
    this.$store.watch(
      () => this.$store.getters["auth/current_sid"],
      (value) => {
        this.currentCompanyId = value;
      }
    );
  },

  methods: {},

  computed: {},
};
</script>
