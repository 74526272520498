<template>
  <div class="login">
    <v-layout
      flex
      align-center
      justify-center
      style="height: 100vh; width: 100vw"
      class="blue-grey darken-4 splash"
    >
      <v-card v-if="!fetchingData" max-width="90%" width="600px">
        <v-toolbar class="primary">
          <v-toolbar-title class="white--text" style="width: 100%"
            >Willkommen bei <em><strong>mitfit</strong></em></v-toolbar-title
          >
        </v-toolbar>

        <v-card-text v-if="!systemRunning" class="pt-4">
          <h1 class="my-3">MAINTENANCE MODUS</h1>
          <p>
            Zur Zeit arbeiten wir, damit mitfit noch besser funktioniert. Du
            wirst dich aktuell nicht einloggen können. In Kürze sind wir aber
            wieder für dich da.
          </p>
        </v-card-text>

        <v-card-text class="pt-4">
          <p>Melde dich mit deinen Zugangsdaten an.</p>

          <v-form ref="form" v-model="isFormValid">
            <v-text-field
              label="Mail"
              v-model="form.username"
              prepend-inner-icon="mdi-at"
              :rules="emailRules"
            ></v-text-field>

            <v-text-field
              label="Passwort"
              v-model="form.password"
              :type="show1 ? 'text' : 'password'"
              prepend-inner-icon="mdi-shield-key"
              :rules="[rules.required]"
            ></v-text-field>

            <v-alert dense type="error" v-if="loginError">
              Login-Versuch gescheitert. Bitte überprüfe deine Zugangsdaten.
            </v-alert>

            <v-btn
              v-if="isFormValid"
              large
              @click="submit"
              class="primary white--text"
              style="width: 100%"
            >
              <v-icon left>mdi-lock</v-icon>Login
            </v-btn>

            <v-btn v-else style="width: 100%" disabled>
              <v-icon left>mdi-lock</v-icon>Login
            </v-btn>

            <v-btn
              x-small
              @click="lostPasswordDialog = true"
              elevation="0"
              class="white my-4"
              style="width: 100%"
            >
              Passwort vergessen
            </v-btn>
          </v-form>
        </v-card-text>

        <v-card-actions class="caption text-center justify-center">
          ©FACEFORCE |
          <a
            href="https://www.face-force.de/impressum/"
            target="_blank"
            class="mx-2"
            >Impressum</a
          >
          |
          <a
            href="https://www.face-force.de/datenschutz/"
            target="_blank"
            class="mx-2"
            >Datenschutz</a
          >
          |
          <span class="caption mx-2">
            App-Version: {{ this.$appVersion }}
          </span>
        </v-card-actions>
      </v-card>

      <v-dialog
        v-model="lostPasswordDialog"
        max-width="90%"
        width="500px"
        transition="dialog-bottom-transition"
        scrollable
      >
        <v-card>
          <v-toolbar dark color="primary" elevation="0">
            <v-toolbar-title>Passwort vergessen</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="lostPasswordDialog = false"
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </v-toolbar>
          <v-card-text>
            <LostPassword
              @requested="lostPasswordDialog = false"
            ></LostPassword>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-layout>
  </div>
</template>

<script>
import { mapActions } from "vuex"; // import actions from vuex (eg. store/modules/auth.js)
import apiRequest from "@/mixins/apiRequest";
import companyData from "@/mixins/companyData";
import LostPassword from "@/components/user/LostPassword";
export default {
  name: "AuthForm",
  components: {
    LostPassword,
  },
  data() {
    return {
      loginError: false,
      lostPasswordDialog: false,

      form: {
        username: "",
        password: "",
      },

      show1: false,
      show2: true,
      show3: false,
      show4: false,
      password: "Password",

      showError: false,
      emailRules: [
        (v) => !!v || this.$t("required"),
        (v) => /.+@.+\..+/.test(v) || this.$t("mailMustBeValid"),
      ],
      rules: {
        required: (value) => !!value || this.$t("required"),
      },
      isFormValid: false,
      fetchingData: false,
      systemRunning: false,
    };
  },

  mounted() {
    this.checkStatus();
  },

  methods: {
    ...mapActions({
      // spread actions in to component methods (LogIn is a method in auth.js actions)
      LogIn: "auth/LogIn",
    }),

    async checkStatus() {
      this.fetchingData = true;
      let response = await this.getRequest("public/status"); // await data from mixin
      if (response.status === 200) {
        const result = response.data.data;
        const mode = result.mode;
        const status = result.status;
        if (
          mode.admin === "regular" &&
          mode.api === "regular" &&
          status === 200
        ) {
          this.systemRunning = true;
        }
        this.fetchingData = false;
      } else {
        this.systemRunning = false;
        this.fetchingData = false;
      }
    },

    submit() {
      this.loginError = false;

      const User = new FormData();
      User.append("loginname", this.form.username);
      User.append("password", this.form.password);

      this.LogIn(User)
        .then(() => {
          this.showError = false;

          this.$root.snackbarGlobal.snackbar = true;
          this.$root.snackbarGlobal.snackbarText =
            "Schön, dass Du wieder da bist!";
          this.$root.snackbarGlobal.errorMessage = "";
          this.$root.snackbarGlobal.snackbarColor = "primary";
          this.$root.snackbarGlobal.snackbarTimeout = 5000;

          /* REUSEABLE MIXIN METHOD 
          VUEX: Setting up the company data (logo, types etc.) */
          console.log("CALL COMPANY SETUP");
          this.companySetup();
        })
        .catch(() => {
          this.loginError = true;
        });
    },
  },

  mixins: [companyData, apiRequest],
};
</script>

<style scoped>
.splash {
  background: url("../../assets/splash.jpg");
  background-size: cover;
}
</style>
