export default {
  de: {
    legacyBlockNote:
      "Du besitzst einen Admin 3.0 Account. 3.0 Admins ist es nicht möglich, Daten zu erstellen oder zu bearbeiten, die in der veralteten Version 2.0 erstellt wurden. Bitte wende dich an uns, um weitere Informationen zu erhalten.",
    hello: "hallo welt",
    $vuetify: {
      dataFooter: {
        rowsPerPageText: "Items per page:",
        pageText: "{0}-{1} von {2}",
      },
    },

    plugins: {
      xmasPresent: {
        name: "Weihnachtsgeschenk",
        title: "Dein Weihnachtsgeschenk der FACEFORCE",
        description:
          "Das besondere Geschenk für deine Mitglieder: outa - Meditation & Schlaf, 1 Monat Premium-Mitgliedschaft geschenkt!",
        dashboardMessage:
          "<h2 class='mb-2'>@:plugins.xmasPresent.title</h2><p>Das besondere Geschenk für deine Mitglieder: outa - Meditation & Schlaf, 1 Monat Premium-Mitgliedschaft geschenkt!<br/>Dir stehen 100 Gutschein-Codes zur freien Verfügung.</p>",
        infoText: `<p>Die hier aufgeführten Codes kannst du direkt an deine Mitglieder verschenken.<br/>Jeder Code beinhaltet eine 30-tägige kostenlose Premium-Mitgliedschaft <strong>für die brandneue Meditation & Schlaf - App "outa".</strong>
        <br/>Nachdem du einen Code mit "@:plugins.xmasPresent.Assign" markiert hast, kannst du dir das passende Info-PDF für den Code herunterladen und deinem Mitglied zukommen lassen.</p>`,
        Code: "Code",
        YourCodes: "Deine Codes",
        Assign: "Vergeben",
        PdfDownload: "PDF downloaden",
        GetYourCodes: "Sichere dir deine Gutschein-Codes",
        Tutorial: "Anleitung",
      },
    },
    funnel: {
      sent: "Versendet",
      open: "Wartet",
      canceled: "Abgebrochen",
      goalReached: "Ziel erreicht",
      branchSwitch: "Zweigwechsel",
      optOut: "Opt-Out",
      error: "Fehler",
    },
    /* Page descriptions */
    pageDescriptions: {
      eventBookings: "Hier findest du alle Buchungen für deine Events.",
      dashboard: "Alles Wesentliche auf einem Blick.",
      am: "Hier findest du deine persönliche Jahresmarketing-Planung. Sofern sich deine nächste anstehende Kampagne noch nicht in Produktion befindet, kannst du jederzeit Änderungen an deiner Jahresplanung vornehmen.",
      amTemplates:
        "Hier findest du deine persönlichen Jahresmarketing Vorlagen. Diese Vorlagen kannst du in die Pläne der Unternehmen, die du betreust kopieren.",
      amTemplate:
        "Hier kannst du deine Jahresmarketing-Plan Vorlage bearbeiten. Wenn du fertig kannst du diese dann auf deine bestehenden Pläne übertragen.",
      leads:
        "Hier findest du die Übersicht der durch die Kampagnen generierten Kontakte.",
      campaigns: "Deine Kampagnen in der Übersicht.",
      campaignsStatistik: "Aktuelle Statistiken zur Kampagne",
      payProducts:
        "Hier kannst du deine Produkte, deine Bestellungen, die PayPal-Daten und Angebote verwalten.",
      payContracts: "Hier kannst du deine Verträge und die Verkäufe verwalten.",
      addonsShops:
        "Hier kannst du deine Verkäufe über das Shops-Addon verwalten.",
      payEmbedCodes:
        "Um deine Verträge auf deiner Homepage anbieten zu können, kopiere einfach den untenstehenden Code und füge diesen an einer geeigneten Stelle deiner Webseite ein.",
      company:
        "Hier findest du alle relevanten Einstellungen zu deinem Unternehmen.",
      companyTeam: "Hier kannst du Mitarbeiter anlegen und verwalten.",
      companyData:
        "Hier kannst du die Basisdaten zu deinem Unternehmen verwalten.",
      companyTerms:
        "Hier kannst du die Allgemeine Geschäftsbedingungen zu deinem Unternehmen hinterlegen.",
      companyApis:
        "Hier können externe Anbieter verbunden werden. Die Auswahl wächst stetig.",
      gdpr: "Hier findest du Möglichkeiten, Auskunft über personenbezogene Daten zu geben und Daten zu löschen.",
      service:
        "Hier bekommst du die geballte Ladung an Informationen sowie exklusive Service-Leistungen für deinen Unternehmenserfolg.",
      serviceInfo:
        "Hier findest du alle wichtigen Informationen, damit du das Maximum aus deinen Kampagnen herausholen kannst.",
      serviceCc:
        "Eine stetig gepflegte Social Media Präsenz (z.B. facebook oder Instagram) trägt heute maßgeblich zum Unternehmenserfolg bei. Deshalb ist es sehr wichtig, die richtigen Beiträge auf deinen Social Media Kanälen zu platzieren. Die Content Cloud liefert dir hierzu eine Vielzahl an handerlesenen Best-Practice-Beispielen, um erfolgreiches Content Marketing für dein Unternehmen zu betreiben.",
      settings: "Hier kannst du Einstellungen an deinem Account machen.",
      settingsPassword: "Hier kannst du dir ein neues Passwort vergeben",
      settingsAvatar: "Hier kannst du dir ein neues Profilbild hochladen.",
      planHelp:
        '<strong>Unser Support bei Rückfragen.</strong> Solltet ihr bei der Bearbeitung eures Marketing-Planers Hilfe benötigen, so könnt ihr über die hinterlegte Support-Mail <a href="mailto:support@face-force.de">support@face-force.de</a> jederzeit Kontakt mit uns aufnehmen. Von euch gewünschte Anpassungen, z.B. bei der Gewinnfestlegung in Kampagnen oder im zeitlichen Umfang von Testpersonen-Kampagnen können bei Bedarf ebenfalls über die Support-Mail kommuniziert werden.',
      xmasPresent:
        "Hier steht der Introtext für das Weihnachtsgeschenk 2021 ... die Voucher-Codes für die Meditations-App.",
      connections:
        "Hier kannst du deine Verbindungen zu externen Anbietern verwalten.",
    },
    annualMarketingAd: {
      title: "Jahresmarketing Flex — jetzt ein- oder umsteigen!",
      text: "GANZHEITLICHE LÖSUNGEN - STATE OF THE ART! Der Erfolg des Jahresmarketing beruht dabei auf dem Einsatz und der Kopplung diverser entwickelter Mechaniken und Strategien, die das Jahresmarketing-Paket zu einer wirklichen All-in-One-Lösung für Gesundheitsanbieter machen.",
    },
    branches: {
      fitness: "Fitness Branche",
      physio: "Physio Branche",
      ems: "EMS Branche",
      golf: "Golf Branche",
    },
    /* ROUTES */
    dashboard: "Dashboard",
    jmp_fitness: "Jahresmarketing",
    mitfitSHOPS: "mitfitSHOPS",
    EventBookings: "Eventbuchungen",
    jmp_fitness_desc:
      "Hier findest du deine persönliche Jahresmarketing-Planung. Sofern sich deine nächste anstehende Kampagne noch nicht in Produktion befindet, kannst du jederzeit Änderungen an deiner Jahresplanung vornehmen.",
    jmp_templates_desc:
      "Hier findest du deine persönlichen Jahresmarketing Vorlagen. Diese Vorlagen kannst du in die Pläne der Unternehmen, die du betreust kopieren.",
    jmp_plan_desc:
      "Hier findest du deine persönliche Jahresmarketing-Planung. Sofern sich deine nächste anstehende Kampagne noch nicht in Produktion befindet, kannst du jederzeit Änderungen an deiner Jahresplanung vornehmen.",
    jmp_template_desc:
      "Hier kannst du deine Jahresmarketing-Plan Vorlage bearbeiten. Wenn du fertig kannst du diese dann auf deine bestehenden Pläne übertragen.",

    jmp_golf: "Jahresmarketing",
    leads: "Leads",
    campaigns: "Kampagnen",
    mitfitMEMBER: "mitfitMEMBER",
    shop: "Shop",
    company: "Mein Unternehmen",
    gdpr: "DSGVO",
    service: "Service",
    settings: "Einstellungen",
    mitfitPAY: "mitfitPAY",
    xmas: "Weihnachtsgeschenk",
    connections: "Verbindungen",
    /* JMP */
    annualMarketingPlan: "Jahres-Marketing Plan",
    planInformation: "Informationen zum Plan",
    jmp: "Jahresmarketing-Paket",
    jmppd: "Jahresmarketing-Paket Premium Dynamic",
    raffleCampaign: "Gewinnspiel-Kampagne",
    bonusCampaign: "Bonus-Kampagne",
    dynamicCampaign: "Dynamic-Kampagne",
    testersCampaign: "Testpersonen-Kampagne",
    physiodynamic: "Physio-Dynamic",
    physioeventkampagne: "Physio-Eventkampagne",
    physiooptional: "Optional-Kampagne",
    nocampaign: "Pause",
    advertisingBudget: "Werbebudget",
    inactivePlanMessage:
      "<strong>PLAN INAKTIV:</strong> Durch das Aktivieren des Marketingplans werden alle deine Kampagnen inklusive der Startzeitpunkte nach unseren Empfehlungen verbindlich eingetragen und zum jeweils hinterlegten Datum erstellt. Sofern du Änderungen vornehmen möchtest, kannst du nun im Anschluss der Aktivierung deinen Marketingplan manuell bearbeiten und Kampagnen sowie Startzeitpunkte jederzeit verändern.",
    activatePlanMessage:
      "<strong>Achtung:</strong> Dein Marketingplan wird nun verbindlich aktiviert. du kannst jedoch direkt im Anschluss deinen Plan ansehen sowie jederzeit Änderungen vornehmen.",
    activatePlan: "Den Plan aktivieren",
    messages: "Nachrichten",
    /* CONTROLS */
    edit: "Bearbeiten",
    search: "Suche",
    searchterm: "Suchbegriff",
    status: "Status",
    updatedate: "Letzte Aktualisierung",
    actions: "Aktionen",
    createNewTemplate: "Neue Vorlage anlegen",
    createNewTerms: "Neue AGB anlegen",
    getMore: "Mehr erfahren",
    /* PHRASES */
    bonusCalendarBonus: "Bonus-Kalender Bonus",
    cancelOrder: "Bestellung stornieren",
    orderIsCancelled: "Bestellung wurde storniert",
    cancelOrderMessage: "Möchtest du die Bestellung wirklich stornieren?",
    cancelSuccessMessage: "Die Bestellung wurde erfolgreich storniert.",
    cancelReason: "Stornierungsgrund",
    leadIsOptOutMessage:
      "Der Lead wurde aus allen Mail-Verteilern ausgetragen.",
    furtherParticipations: "Weitere Teilnahmen",
    furtherParticipationsInfo: "Die aktuelle Teilnahme ist nicht inkludiert.",
    mitfitLeads: "mitfitLEADS",
    Persons: "Personen",
    Activity: "Aktivität",
    underageParticipant: "Minderjährig",
    underage: "Minderjährig",
    adSource: "Werbekanal",
    address: "Adresse",
    deleted: "gelöscht",
    Promotion: "Angebot",
    promoTitle: "Titel des Angebots",
    promoPrice: "Angebots-Preis",
    Meta: "Meta",
    Details: "Details",
    UseAnExternProvider: "Verwende einen externen Anbieter",
    startAfterPromo: "Start nach Ablauf des Angebots",
    apiProviders_id: "Anbieter ID",
    apiProvidersContractId: "Anbieter-Vertrags-ID",
    externalProvider: "Externer Anbieter",
    Payments: "Zahlungen",
    Payment: "Zahlung",
    PaymentStatus: "Zahlungsstatus",
    PaymentReminder: "Zahlungserinnerung",
    membershipGrowth: "Mitglieder Wachstum",
    allowSepa: "SEPA-Zahlungen erlauben",
    noPayment: "Keine Zahlung",
    noPaymentText: "Text, der angezeigt wird, wenn keine Zahlung möglich ist.",
    allowDirectDebit: "Lastschrift-Zahlungen erlauben",
    allowInvoice: "Rechnungs-Zahlungen erlauben",
    allowCreditCard: "Kreditkarten-Zahlungen erlauben",
    allowPayPal: "PayPal-Zahlungen erlauben",
    externalProviderInfo: `<p>
    Hier hast du die Möglichkeit, den Kauf mit einem externen
    Anbieter zu synchronisieren.<br />Hierzu musst du die
    API-Credentials des jeweiligen Anbieters im API-Bereich (Mein
    Unternehmen -> API) hinterlegen.</p>`,
    promotionInfo: `<p>Möchtest du den Vertrag für eine gewisse Zeitspanne für einen vergünstigten Preis verkaufen? Hier hast du die Gelegenheit dazu.</p>`,
    Contract: "Vertrag",
    Subject: "Betreff",
    version: "Version",
    All: "Alle",
    yes: "Ja",
    no: "Nein",
    by: "von",
    campaign: "Kampagne",
    companyPhrase: "Firma",
    oclock: "Uhr",
    plannedInfo: "Es sind {planned} von {active} Monaten geplant",
    plannedInfoVideos: "Es sind {planned} von {active} Videos geplant",
    pleaseChoose: "Bitte wählen",
    regularType: "Regulär",
    manualType: "Manuell",
    baseData: "Stammdaten",
    manuallyCreatedBy: "Manuell angelegt von",
    registrationDate: "Registrierungsdatum",
    participationDate: "Teilnahmedatum",
    dateOfPurchase: "Kaufdatum",
    source: "Quelle",
    participation: "Teilnahme",
    notes: "Notizen",
    payProduct: "mitfitPAY Produkt",
    addProduct: "Produkt hinzufügen",
    addOffer: "Angebot hinzufügen",
    addContract: "Vertrag hinzufügen",
    finePrint: "Kleingedrucktes",
    vatRate: "Steuersatz",
    currency: "Währung",
    image: "Bild",
    additionalCosts: "Zusatzkosten",
    contract: "Vertrag",
    contracts: "Verträge",
    grossPrice: "Bruttopreis",
    totalPrice: "Gesamtpreis",
    netPrice: "Netto-Preis",
    vatValue: "Mehrwertsteuer",
    paymentMethod: "Zahlungsart",
    onlyForOffers: "Nur für Angebote",
    isB2B: "B2B",
    active: "Aktiv",
    inactive: "Inaktiv",
    oClock: "Uhr",
    question: "Frage",
    answer: "Antwort",
    imageriddleanswer: "Antwort Bilderrätsel",
    accessibility: "Erreichbarkeit",
    win: "Gewinn",
    customneeds: "Benutzerdefinierte Bedürfnisse",
    pollresults: "Umfrage Ergebnisse",
    poll: "Umfrage",
    addNote: "Notiz hinzufügen",
    marked: "markiert",
    demarked: "demarkiert",
    areYouSure: "Bist du dir sicher?",
    pleaseKeepInMind: "Bitte beachte",
    lostHint: `Du möchtest die Person als "Verloren/Lost" markieren. Bevor du diesen Interessenten aufgibst, hast du diese drei Punkte bei der Bearbeitung berücksichtigt?
    <ul>
    <li>Mindestens 3 Mal zu verschiedenen Uhrzeiten angerufen</li>
    <li>Double call-Technik angewendet</li>
    <li>Außer E-Mail auch über andere Kanäle kontaktiert (Facebook, Whatsapp)</li>
    </ul>`,
    markAsLost: "Als Verloren markieren",
    Cancel: "Abbrechen",
    cancel: "abbrechen",
    participant: "Teilnehmer",
    participants: "Teilnehmer",
    activity: "Aktivität",
    tool: "Tool",
    info: "Information",
    leadProcessingStatus: "Lead Bearbeitungsstatus",
    payProcessingStatus: "Zahlungsbearbeitungsstatus",
    Unpayed: "Nicht bezahlt",
    Payed: "Bezahlt",
    complete: "Fertig",
    notComplete: "Nicht fertig",
    leadDevelopment: "Lead Entwicklung",
    genderDistribution: "Geschlechterverteilung",
    runtime: "Laufzeit",
    newLeads: "Neue Leads",
    contacts: "Kontakte",
    safe: "Speichern",
    safed: "gespeichert",
    Safed: "Gespeichert",
    lastActivity: "Letzte Aktivität",
    latestLeads: "Die aktuellsten Leads",
    last5campaigns: "Die letzten 5 Kampagnen",
    leadsLast5Campaigns: "Die Leads der letzten 5 Kampagnen",
    news: "Neuigkeiten",
    employee: "Mitarbeiter",
    employees: "Mitarbeiter",
    companyData: "Unternehmensdaten",
    apis: "APIs",
    companyTerms: "AGB (mitfitPAY)",
    contractForOrderProcessing: "Auftragsdatenverarbeitung",
    avatar: "Profilbild",
    permission: "Berechtigung",
    permissions: "Berechtigungen",
    employeePermission: "Berechtigungen des Mitarbeiters",
    employeePermissionInfo:
      "Welche Plugins darf der Mitarbeiter verwalten? Jede Berechtigung umfasst jedwede Berechtigung innerhalb des Plugins.",
    required: "Pflichtfeld",
    Accept: "Akzeptieren",
    accept: "akzeptieren",
    Reject: "Ablehnen",
    reject: "ablehnen",
    deleteItemConfirmationText:
      "Ich bin mir darüber bewusst, dass die Daten endgültig und nicht wiederherstellbar gelöscht werden. Eine spätere Reaktivierung ist nicht möglich.",
    exportItemConfirmationText:
      "Ich versichere, dass die Daten datenschutzkonform (siehe DSGVO) behandelt und verwaltet werden. Die exportierten personenbezogenen Daten obliegen in vollem Umfang meiner Verantwortung.",
    error: "Fehler",
    emailIsAlreadyInUse:
      "Die E-Mail Adresse ist bereits in Verwendung. Bitte verwende eine andere.",
    mailMustBeValid: "Die E-Mail Adresse  muss gültig sein",
    city: "Stadt",
    zipCode: "Postleitzahl",
    businessName: "Geschäftsbezeichnung",
    corporateForm: "Unternehmensform",
    authorizedToRepresent: "Vertretungsberechtigt",
    taxId: "Umsatzsteuer-ID",
    responsibleForTheContent: "Verantwortlich für den Inhalt",
    dataProtectionOfficer: "Datenschutzbeauftragter",
    dataProtectionMail: "E-Mail Adresse für Datenschutzfragen",
    adress: "Adresse",
    legalInformation: "Rechtliches",
    username: "Benutzername",
    password: "Passwort",
    url: "Webadresse (URL)",
    studioNumber: "Studio-Nummer",
    title: "Titel",
    thumbnail: "Vorschaubild",
    visibility: "Sichtbarkeit",
    thankYou: "Vielen Dank",
    thankYouMessage: "Dankestext",
    textInMail: "Text in Mail",
    emailCustomerIsActive: "Produkt hat eigene Dankestexte",
    thankYouTextFromOffer: "Die Dankestexte werden aus dem Angebot übernommen.",
    placeholder: "Platzhalter",
    offerProductsInfo: "Produkte, die in diesem Angebot enthalten sind.",
    contractsEmbedCodeIntro:
      "Um deine Verträge auf deiner Homepage anbieten zu können, kopiere einfach den untenstehenden Code und füge diesen an einer geeigneten Stelle deiner Webseite ein.",
    contractsEmbedCodeOutro:
      "Wenn du nur bestimmte Verträge zur Auswahl stellen möchtest, kannst du dafür das Data-Attribut 'data-contractIds=' nutzen. Füge hier einfach die IDs der Verträge ein, die zur Auswahl gestellt werden sollen. Mehrere IDs müssen mit einem Komma getrennt werden.",
    changePassword: "Passwort ändern",
    changeAvatar: "Profilbild ändern",
    passwordsMustMatch: "Die Passwörter müssen übereinstimmen",
    repeatPassword: "Passwort wiederholen",
    minimum8Chars: "Mindestens 8 Zeichen verwenden",
    itemsPerPage: "Elemente pro Seite",
    of: "von",
    watch: "anschauen",
    Watch: "Anschauen",
    Duplicate: "Duplizieren",
    Activate: "Aktivieren",
    duplicate: "duplizieren",
    showLeads: "Leads anzeigen",
    preview: "Vorschau",
    statistics: "Statistiken",
    Export: "Exportieren",
    export: "exportieren",
    exportLeads: "Leads exportieren",
    exportLeadsMessage: "Hier kannst du Teilnehmerdaten exportieren.",
    exportLeadsWhichData: "Welche Daten möchtest du exportieren?",
    passwordProtection: "Passwortschutz",
    Delete: "Löschen",
    delete: "Löschen",
    createLeadManually: "Einen Lead manuell anlegen",
    walkin: "Walk-In",
    socialmedia: "Social-Media",
    custom: "Sonstiges",
    note: "Notiz",
    manualLeadOptIn:
      "Die Erlaubnis zum speichern der personenbezogenen Daten habe ich von der betroffenen Person eingeholt und dokumentiert.",
    alreadyClient: "Bereits Kunde",
    mailAlreadyExists: "E-Mail Adresse existiert bereits!",
    mailAlreadyExistsInfo:
      "Ein Nutzer mit dieser E-Mailadresse existiert bereits im System. Bitte überprüfe, ob dieser Lead bereits angelegt wurde.",
    noPermissions: "Du hast aktuell keine Berechtigung diese Daten einzusehen.",
    noData: "Hier gibt es aktuell noch keine Daten.",
    noAdditionalData: "Es sind keine zusätzlichen Daten verfügbar.",
    quarterVideo: "Quartalsvideo",
    season: "Saison",
    yearplanTemplates: "Jahresmarketing Vorlagen",
    template: "Vorlage",
    AdditionalData: "Zusätzliche Daten",
    ObaResults: "Ergebnisse der online Bedarfsanalyse",
    /* GENDER */
    salutation: "Anrede",
    gender: "Geschlecht",
    mr: "Herr",
    mrs: "Frau",
    Male: "Männlich",
    male: "männlich",
    Female: "Weiblich",
    female: "weiblich",
    Diverse: "Divers",
    diverse: "divers",
    divers: "Divers",
    /* REACHABILLITY */
    inTheMorning: "Morgens",
    noon: "Mittags",
    afternoon: "Nachmittags",
    atEvening: "Abends",
    /* PEOPLE */
    fullname: "Name",
    firstName: "Vorname",
    lastName: "Nachname",
    email: "E-Mail",
    phone: "Telefon",
    mobile: "Mobil",
    reachability: "Erreichbarkeit",
    contactType: "Kontaktart",
    client: "Kunde",
    contacted: "kontaktiert",
    Contacted: "Kontaktiert",
    notContacted: "Nicht kontaktiert",
    scheduled: "terminiert",
    Scheduled: "Terminiert",
    redeemed: "eingelöst",
    Redeemed: "Eingelöst",
    begun: "Eingelöst",
    bought: "gekauft",
    Bought: "Gekauft",
    lost: "verloren",
    Lost: "Verloren",
    Appointment: "Terminvereinbarung",
    Closed: "Abgeschlossen",
    closed: "abgeschlossen",
    Open: "Offen",
    open: "offen",
    incomplete: "unvollständig",
    Incomplete: "Unvollständig",
    SepaMissing: "SEPA fehlt",
    Complete: "Vollständig",
    Close: "Schließen",
    close: "schließen",
    unedited: "unbearbeitet",
    Unedited: "Unbearbeitet",
    unknown: "unbekannt",
    Unknown: "Unbekannt",
    addEmployee: "Mitarbeiter anlegen",
    rating: "Bewertung",
    Anybody: "Jeder",
    anybody: "jeder",
    Nobody: "Niemand",
    nobody: "niemand",
    AnybodyWithPassword: "Jeder mit Passwort",
    uploadAVideo: "Ein Video hochladen",
    description: "Beschreibung",
    videoFile: "Videodatei",
    file: "Datei",
    files: "Dateien",
    products: "Produkte",
    orders: "Bestellungen",
    order: "Bestellung",
    product: "Produkt",
    invoice: "Rechnung",
    sales: "Verkäufe",
    campaignOffers: "Kampagnen-Angebote",
    campaignOffer: "Kampagnen-Angebot",
    environment: "Umgebung",
    liveEnvironment: "Live-Umgebung",
    sandboxEnvironment: "Sandbox-Umgebung",
    accessData: "Zugangsdaten",
    emdedCode: "Einbettungscode",
    addons: "Addons",
    billingPeriod: "Abrechnungszeitraum",
    promotion: "Werbeaktion",
    features: "Merkmale",
    onePerRow: "Eins pro Zeile",
    bestOffer: "Bestes Angebot",
    periodOfTime: "Zeitspanne",
    factor: "Faktor",
    message: "Nachricht",
    remarks: "Anmerkungen",
    annotation: "Anmerkung",
    extraCosts400: "(Zusatzkosten: 400 EUR)",
    NONE: "Uploading",
    TRANSCODING: "Daten werden umgewandelt",
    DELETE: "Wird gelöscht",
    THUMBNAIL: "Erstelle Vorschaubild",
    accountHolder: "Kontoinhaber",
    Birthday: "Geburtstag",
    Terminated: "Gekündigt",
    recommendationUrl: "Weiterempfehlungs-URL",
    recommendationUrlHint:
      "Hinterlege hier eine URL, die deine Kunden an ihre Familie, Freunde und Bekannten weitergeben können.",
    bankData: "Bankdaten",
    bankName: "Name des Kreditinstituts",
    creditorId: "Gläubiger-Identifikationsnummer",
    ageVerification: "Altersverifikation",
    minimumAge: "Mindestalter",
    excludeUnderage: "Minderjährige ausschließen",
    underageText: "Text für Minderjährige",
    /* Date Time */
    date: "Datum",
    startDate: "Startdatum",
    startTime: "Startzeit",
    endTime: "Endzeit",
    activationDate: "Aktivierungsdatum",
    creationDate: "Erstellungsdatum",
    month: "Monat",
    day: "Tag",
    week: "Woche",
    year: "Jahr",
    quarter: "Quartal",
    halfYear: "Halbjahr",
    january: "Januar",
    february: "Februar",
    march: "März",
    april: "April",
    may: "Mai",
    june: "Juni",
    july: "Juli",
    august: "August",
    september: "September",
    october: "Oktober",
    november: "November",
    december: "Dezember",
    once: "einmalig",
    daily: "täglich",
    weekly: "wöchentlich",
    monthly: "monatlich",
    quarterly: "vierteljährlich",
    halfYearly: "halbjährlich",
    yearly: "jährlich",
    chooseDateTimeOfMeeting: "Wähle den Zeitpunkt des Termins",
    type: "Typ",
    /* MESSAGES */
    messageUpdated: "Der Datensatz wurde erfolgreich geupdated!",
    messageCreated: "Der Datensatz wurde erfolgreich erstellt!",
    messageDeleted: "Der Datensatz wurde erfolgreich gelöscht!",
    messageExported: "Der Datensatz wurde erfolgreich exportiert!",
    messageError:
      "Es ist etwas schiefgelaufen. Bitte versuche es noch einmal oder wende dich direkt an beratung@face-force.de",
    messagePlanActivated: "Der Plan wurde erfolgreich aktiviert!",
    /* COUNTRIES */
    country: "Land",
    germany: "Deutschland",
    austria: "Österreich",
    swizerland: "Schweiz",
    netherland: "Niederlande",
    belgium: "Belgien",
    france: "Frankreich",
    poland: "Polen",
    hungary: "Ungarn",
    denmark: "Dänemark",
    england: "England",
    usa: "USA",
    /* BUSINESS-FORMS */
    bfCorporation: "Aktiengesellschaft (AG)",
    bfRegisteredBusinessman: "Eingetragener Kaufmann (e.K.)",
    bfRegisteredAssociation: "eingetragener Verein (e. V.)",
    bfSoleTrader: "Einzelkaufmann",
    bfSoleProprietorship: "Einzelunternehmer",
    bfFreelancer: "Freiberufler",
    bfCivilLawSociety: "Gesellschaft bürgerlichen Rechts (GbR)",
    bfCompanyWithLimitedLiability:
      "Gesellschaft mit beschränkter Haftung (GmbH)",
    bfPrivateCitizen: "Privatperson",
    bfEntrepreneurship: "UG (Unternehmergesellschaft)",
    bfOpenTradingCompany: "Offene Handelsgesellschaft (OHG)",
    bfOther: "Sonstiges",
    /* CONTENT CLOUD */
    contentCloudIntro:
      "Eine stetig gepflegte Social Media Präsenz (z.B. facebook oder Instagram) trägt heute maßgeblich zum Unternehmenserfolg bei. Deshalb ist es sehr wichtig, die richtigen Beiträge auf deinen Social Media Kanälen zu platzieren. Die Content Cloud liefert dir hierzu eine Vielzahl an handerlesenen Best-Practice-Beispielen, um erfolgreiches Content Marketing für dein Unternehmen zu betreiben.",
    contentCloudGeneralInfo:
      "<strong>Beachte die folgenden Punkte wenn du erfolgreich einen Beitrag erstellen willst:</strong></p><p>Alle Mitarbeiter (die bei Facebook angemeldet sind) liken den Beitrag innerhalb der ersten 10 Minuten (Gefällt mir klicken). Die Mitarbeiter können den Beitrag auch teilen. Kommentare von Mitarbeitern jedoch bitte nur in Bezug auf Kommentare von Mitgliedern. Ganz Wichtig: Kein Mitarbeiter soll Werbetexte als Kommentar verfassen. Dies wirkt gestellt und verringert die Authentizität und somit die positive Wirkung.</p><p>Ideal ist es, wenn mehrere Mitglieder (inklusive des abgebildeten Testimonials) auch innerhalb der ersten Minuten nach der Veröffentlichung den Beitrag mit ihren Erfolgserlebnissen kommentieren.</p><p>Auf die Kommentare der Mitglieder MUSS durch Mitarbeiter reagiert werden (im Sinne einer positiven Bestärkung). Im Club dreht man sich ja auch nicht einfach weg wenn man von einem Mitglied angesprochen wird. Wer nicht auf Kommentare reagiert schadet seinem Online Marketing nachhaltig.</p>",
    reduceFluctuations: "Fluktuationen senken",
    arouseInterest: "Interesse wecken",
    increaseSales: "Umsatz steigern",
    productStaging: "Produktinszenierung",
    increaseCustomerLoyalty: "Kundenbindung steigern",
    viewPost: "Beitrag anschauen",
    noNotesExist: "Aktuell sind keine Notizen vorhanden.",
    /* ADDONS */
    videoLibrary: "Video-Mediathek",

    annual_marketing: {
      title: "Jahresmarketing Flex",
      description:
        "Hier findest du deine persönliche Jahresmarketing-Planung. Sofern sich deine nächste anstehende Kampagne noch nicht in Produktion befindet, kannst du jederzeit Änderungen an deiner Jahresplanung vornehmen.",
    },

    /* HELP */
    helpDashboard: {
      title: "Dashboard",
      content:
        "<p>Du befindest dich auf dem Dashboard, der Kommando-Zentrale des mitfit-Systems. Hier hast du alles direkt im Überblick. Am linken Bildschirmrand findest du das Menü, welches dich zu den jeweiligen Bereichen navigiert.</p><p>Sofern du Multi-Administrator bist, kannst du per Klick auf deinen Studionamen (oberhalb Dashboard) zwischen deinen Standorten wechseln.</p>",
    },
    helpJmp: {
      title: "Jahresmarketing",
      content:
        "<p>Das Herzstück deiner professionellen Marketingplanung!<br/>Hier kannst du deine von uns bereits vorkonfigurierte Jahresplanung verwalten und jederzeit Änderungen vornehmen.</p><p>Die Übersicht zeigt dir den aktuellen Änderungs- und Planungsstatus deiner Jahresplanung an. Mit Klick auf den Vorschau-Button kannst du deine derzeitige Planung betrachten.</p>",
    },
    helpLeads: {
      title: "LEADS",
      content:
        "<p>Alle Leads, die über die Kampagnen generiert werden, sind hier in einer Gesamtübersicht dargestellt. Mit Klick auf das Auge auf der rechten Seite gelangst du zum Personen-Datensatz.</p><p>Über den Button <strong>Einen Lead manuell anlegen +</strong> kannst du sogar externe Leads zum System hinzufügen. Durch die Angabe der Herkunft (z.B. Walk-In/Call-In, Social-Media) gehen dir keine Interessenten mehr verloren. So kannst du mitfit beispielsweise auch optimal für Promotion-Stände nutzen, bei denen du Leads digital festhalten willst. Weitere Informationen zur Person wie E-Mailadresse oder Telefonnummer kannst du bei Bedarf jederzeit nachträglich ergänzen.</p>",
    },
    helpCampaigns: {
      title: "LEADS",
      content:
        "<p>Unter LEADS werden alle Kampagnen zur Leadgenerierung (auch HR-Recruitment Kampagnen) aufgelistet. Die Statusanzeige gibt dir dabei an, ob eine Kampagne abgeschlossen, in Planung oder gerade aktiv ist. Mit Klick auf das Auge (Anschauen-Button) auf der rechten Seite gelangst du zur Detail-Ansicht der jeweiligen Kampagne.</p>",
    },
    helpCampaignsDetail: {
      title: "Kampagnen",
      content:
        "<p>Zu jeder Kampagne steht dir ein übersichtliches Dashboard zur Verfügung, sodass du schnell einen Gesamtüberblick erhältst.</p><p>Unterhalb des Dashboards werden die Teilnehmer der Kampagne aufgelistet. Durch das farbliche Ampelsystem erkennst du auf einen Blick den aktuellen Bearbeitungsstatus. Mit Klick auf das Auge auf der rechten Seite gelangst du zum jeweiligen Personen-Datensatz zur individuellen Bearbeitung.</p><p>Neben den Stammdaten kannst du direkte Anmerkungen zur Person machen. Diese helfen dir und deinem Team zur besseren, persönlichen Beratung. Je mehr Angaben dir zu einer Person zur Verfügung stehen, desto zielführender ist die anschließende Beratungssituation mit dem Kunden. Top-Berater kennen Ihre Interessenten ganz genau!</p><p>Kampagnenbezogene Infos zur Person (z.B. Bringt den Freund zum Termin mit) kannst du unterhalb im Notizbereich der Lead Aktivitäten festhalten. In den Lead-Aktivitäten werden alle Informationen zu Kampagnen-Teilnahmen sowie dem Bearbeitungsstatus dargestellt.</p><p>Die Schieberegler dokumentieren den Kundenstatus direkt unterhalb in den Notizen ohne weiteren Aufwand. Der dokumentierte Bearbeitungszeitpunkt sowie die Angabe zum verantwortlichen Mitarbeiter sorgt ebenfalls für Klarheit bei größeren Teams zur optimalen Lead-Bearbeitung.</p>",
    },
    helpMitfitPay: {
      title: "mitfitPAY",
      content: `<h3>Verkäufe</h3>
      <p>Hier findest du die Übersicht der Verkäufe. Der Status verrät dir, ob ein Kauf abgeschlossen, oder abgebrochen wurde. Sobald ein Verkauf abgeschlossen wurde, kannst du dir die entsprechende Rechnung herunterladen, indem du auf den Download-Button klickst.</p> 
      <h3>Produkte</h3>
      <p>Hier kannst du die Produkte deines Shops erstellen und verwalten.</p>
      <p>Zudem kannst du für jedes Produkt individuell einstellen, ob das Produkt verfügbar ist oder nicht. Die Option 'Nur für Angebote' bedeutet, dass das entsprechende Produkt ausschließlich im Kontext eines Angebotes gekauft werden kann. So markierte Produkte werden nicht im Shop-Bereich gelistet.</p>
      <h3>PayPal</h3>
      <p>Hier siehst du die API-Zugangsdaten zu deinem Paypal+ Account. Updates der Daten können aktuell nur von den Administratoren der FACEFORCE vorgenommen werden.</p>
      <h3>Kampagnen-Angebote</h3>
      <p>Angebote sind Zusammenstellungen von Produkten. Diese Zusammenstellungen finden aktuell ausschließlich im Kontext einer FACEFORCE-Kampagne Verwendung. Du kannst einer Kampagne bereits erstellte Produkte zuweisen, sie inaktiv schalten und auch individuelle Texte zuweisen.</p>
      `,
    },
    helpCompany: {
      title: "Mein Unternehmen",
      content: `<p>Alle Angaben zu deinem Unternehmen kannst du hier einsehen.</p>
      <h3>Mitarbeiter</h3>
      <p>Unter Mitarbeiter kannst du neue Mitarbeiter hinzufügen und individuelle Berechtigungen festlegen. Beachte, dass Mitarbeiter-Rechte stets standortbezogen sind und fest einem Standort zugeordnet werden. Soll die Person mehrere Standorte verwalten, so kann hierfür ein Multi-Administrator angelegt werden.</p>
      <h3>Unternehmensdaten</h3>
      <p>Hier kannst du die Unternehmensadresse sowie rechtliche Angaben zum Unternehmen einsehen. Sollte etwas falsch sein, kontaktiere uns bitte.</p>
      `,
    },
    helpGdpr: {
      title: "DSGVO",
      content:
        "<p>Der Bereich DSGVO ist ähnlich aufgebaut wie der Lead-Bereich. Hier werden alle Datensätze aufgelistet, sodass du auf Wunsch von Interessenten jederzeit deiner gesetzlichen Auskunftspflicht als Unternehmen nachkommen kannst. Zusätzlich hast du hier die Möglichkeit, Personen-Datensätze DSGVO-konform zu löschen.</p>",
    },
    helpService: {
      title: "Service",
      content: `<p>Im Service-Bereich findest du wichtige Informationen, wie beispielsweise Ablaufbeschreibungen oder Telefonleitfäden.</p>
      <p>Darüber hinaus unterstützen wir im Rahmen des Jahresmarketing-Pakets dein Unternehmen kostenfrei mit unserer Social-Media Content Cloud. Diese wertvolle Sammlung erleichtert die Planung und Erstellung guter Social-Media-Beiträge auf Facebook, Instagram & Co., immer passend zu den jeweiligen Kernbereichen.</p>`,
    },
    helpSettings: {
      title: "Einstellungen",
      content: `<p>Hier kannst du dein Profilbild ändern und ein neues Kennwort für deinen persönlichen mitfit-Zugang vergeben.</p>`,
    },
  },

  en: {
    legacyBlockNote:
      "You have an Admin 3.0 account. 3.0 Admins are not able to create or edit data created in the deprecated version 2.0. Please contact us for further information.",
    hello: "hello world",

    $vuetify: {
      dataFooter: {
        rowsPerPageText: "Items per page:",
        pageText: "{0}-{1} of {2}",
      },
    },
    plugins: {
      xmasPresent: {
        name: "Christmas gift",
        title: "Your FACEFORCE Christmas present",
        description:
          "Here is the introtext for the Christmas present 2021 ... the voucher codes for the meditation app.",
        dashboardMessage:
          "<h2 class='mb-2'>@:plugins.xmasPresent.title</h2><p>The special gift for your members: outa - meditation & sleep, 1 month premium membership free!<br/>You have 100 promotional codes at your disposal.</p>",
        infoText: `<p>You can give away the codes listed here directly to your members. Each code includes a 30-day free premium membership for the brand new meditation & sleep app "outa".</p>
        <p>After you have marked a code with "@: plugins.xmasPresent.Assign", you can download the appropriate info PDF for the code and send it to your member.</p>`,
        Code: "Code",
        YourCodes: "Your codes",
        Assign: "Assigned",
        PdfDownload: "PDF download",
        GetYourCodes: "Get your voucher codes",
        Tutorial: "Tutorial (german)",
      },
    },
    funnel: {
      sent: "Sent",
      open: "Open",
      cancelled: "Cancelled",
      goalReached: "Goal reached",
      branchSwitch: "Branch switch",
      optOut: "Opt-out",
      error: "Error",
    },

    /* Page descriptions */
    pageDescriptions: {
      eventBookings: "Here you can manage your event bookings.",
      dashboard: "Everything essential at a glance.",
      am: "Here you can find your personal annual marketing plan. If your next upcoming campaign is not yet in production, you can make changes to your annual plan at any time.",
      amTemplates:
        "Here you can find your personal annual marketing templates. You can copy these templates into the plans of the companies you oversee.",
      amTemplate:
        "Here you can edit your annual marketing plan template. When you're done, you can then transfer these to your existing plans.",
      leads:
        "Here you can find an overview of the contacts generated by the campaigns.",
      campaigns: "Your campaigns at a glance.",
      campaignsStatistik: "Current statistics on the campaign",
      payProducts:
        "Here you can manage your products, your orders, PayPal data and offers.",
      payContracts: "Here you can manage your contracts and sales.",
      addonsShops: "Here you can manage your products and your shop.",
      payEmbedCodes:
        "In order to be able to offer your contracts on your homepage, simply copy the code below and paste it into a suitable place on your website.",
      company: "Here you can find all the relevant settings for your company.",
      companyTeam: "Here you can create and manage employees.",
      companyData: "Here you can manage the basic data for your company.",
      companyTerms:
        "Here you can store the general terms and conditions for your company.",
      companyApis:
        "External providers can be connected here. The selection is growing steadily.",
      gdpr: "Here you will find options to provide information about personal data and to delete data.",
      service:
        "Here you get the concentrated load of information as well as exclusive services for your companys success.",
      serviceInfo:
        "Here you will find all the important information so that you can get the most out of your campaigns.",
      serviceCc:
        "A constantly maintained social media presence (e.g. Facebook or Instagram) contributes significantly to the company's success today. That is why it is very important to place the right posts on your social media channels. The Content Cloud provides you with a variety of hand-picked best-practice examples for successful content marketing for your company.",
      settings: "Here you can make settings on your account.",
      settingsPassword: "Here you can assign yourself a new password",
      settingsAvatar: "Here you can upload a new profile picture.",
      planHelp:
        '<strong> Our support for queries. </strong> Should you need help with the processing of your marketing planner, you can contact us at any time via the support email <a href="mailto:support@face-force.de">support@face-force.de</a>. Any adjustments you require, e.g. when setting profits in campaigns or in the time frame of test person campaigns, can also be communicated via the support email if necessary.',
      xmasPresent:
        "Hier steht der Introtext für das Weihnachtsgeschenk 2021 ... die Voucher-Codes für die Meditations-App.",
      connections:
        "Here you can find an overview of all connections to external providers.",
    },
    annualMarketingAd: {
      title: "Annual marketing Flex - join or switch now!",
      text: "HOLISTIC SOLUTIONS - STATE OF THE ART! The success of the annual marketing is based on the use and coupling of various developed mechanisms and strategies that make the annual marketing package a real all-in-one solution for healthcare providers.",
    },
    branches: {
      fitness: "Fitness industry",
      physio: "Physio industry",
      ems: "EMS industry",
      golf: "Golf industry",
    },
    /* ROUTES */
    dashboard: "Dashboard",
    jmp_fitness: "Annual marketing",
    EventBookings: "Event bookings",
    mitfitSHOPS: "mitfitSHOPS",
    jmp_fitness_desc:
      "Here you will find your personal annual marketing plan. If your next upcoming campaign is not yet in production, you can always make changes to your annual plan.",
    jmp_templates_desc:
      "Here you can find your personal annual marketing templates. You can copy these templates into the plans of the companies you oversee.",
    jmp_plan_desc:
      "Here you can find your personal annual marketing plan. If your next upcoming campaign is not yet in production, you can make changes to your annual plan at any time.",
    jmp_template_desc:
      "Here you can edit your annual marketing plan template. When you're done, you can then transfer these to your existing plans.",

    jmp_golf: "Annual marketing",
    leads: "Leads",
    campaigns: "Campaigns",
    mitfitMEMBER: "mitfitMEMBER",
    shop: "Shop",
    company: "My Business",
    gdpr: "GDPR",
    service: "Service",
    settings: "Settings",
    mitfitPAY: "mitfitPAY",
    xmas: "Christmas present",
    connections: "Connections",

    /* JMP */
    annualMarketingPlan: "Annual Marketing Plan",
    planInformation: "Information about the plan",
    jmp: "Annual marketing package",
    jmppd: "Annual marketing package Premium Dynamic",
    raffleCampaign: "Raffle-Campaign",
    bonusCampaign: "Bonus-Campaign",
    dynamicCampaign: "Dynamic-Campaign",
    testersCampaign: "Testers-Campaign",
    physiodynamic: "Physio-Dynamic",
    physioeventkampagne: "Physio-Eventcampaign",
    physiooptional: "Optional-Campaign",
    nocampaign: "Pause",
    advertisingBudget: "Advertising budget",
    inactivePlanMessage:
      "<strong>PLAN INACTIVE:</strong> By activating the marketing plan, all of your campaigns including the start times will be entered in a binding manner according to our recommendations and created on the respective stored date. If you want to make changes, you can now edit your marketing plan manually after activation and change campaigns and start times at any time.",
    activatePlanMessage:
      "<strong>Attention:</strong> Your marketing plan will now be activated. However, you can view your plan immediately afterwards and make changes at any time.",
    activatePlan: "Activate plan",
    messages: "Messages",
    /* CONTROLS */
    edit: "Edit",
    search: "Search",
    searchterm: "search term",
    status: "Status",
    updatedate: "Last update",
    actions: "Actions",
    createNewTemplate: "Create a new template",
    createNewTerms: "Create new terms",
    getMore: "Get more",
    /* PHRASES */
    bonusCalendarBonus: "Bonus-Calendar Bonus",
    cancelOrder: "Cancel order",
    cancelOrderMessage: "Are you sure you want to cancel this order?",
    orderIsCancelled: "The order has been cancelled.",
    cancelSuccessMessage: "The order has been successfully cancelled.",
    cancelReason: "Reason for cancellation",
    leadIsOptOutMessage: "The lead has opted out of the mailer.",
    furtherParticipations: "Further participations",
    furtherParticipationsInfo: "The actual campaign is not included.",
    mitfitLeads: "mitfitLEADS",
    Persons: "Persons",
    Activity: "Activity",
    underageParticipant: "Underage participant",
    underage: "Underage",
    adSource: "Ad source",
    address: "Address",
    deleted: "Deleted",
    Promotion: "Promotion",
    promoTitle: "Promotion title",
    promoPrice: "Promotion price",
    Meta: "Meta",
    Details: "Details",
    UseAnExternProvider: "Use an external provider",
    startAfterPromo: "Start after promotion",
    apiProviders_id: "Provider ID",
    apiProvidersContractId: "Vendor Contract ID",
    externalProvider: "External Provider",
    Payments: "Payments",
    Payment: "Payment",
    PaymentStatus: "Payment status",
    PaymentReminder: "Payment reminder",
    membershipGrowth: "Membership growth",
    allowSepa: "Allow SEPA payments",
    noPayment: "No payment",
    noPaymentText: "Text to be displayed when payment is not possible.",
    allowDirectDebit: "Allow direct debit payments",
    allowInvoice: "Allow invoice payments",
    allowCreditCard: "Allow credit card payments",
    allowPayPal: "Allow PayPal payments",
    externalProviderInfo: `<p>
    Here you have the option of purchasing with an external
     to synchronize providers.<br />For this you have to
     API credentials of the respective provider in the API area (My
     Company -> API).</p>`,
    promotionInfo: `<p>Do you want to sell the contract for a reduced price for a certain period of time? Here's your chance.</p>`,
    Contract: "Contract",
    Subject: "Subject",
    version: "Version",
    All: "All",
    yes: "Yes",
    no: "No",
    by: "by",
    campaign: "Campaign",
    companyPhrase: "Company",
    oclock: "",
    plannedInfo: "{planned} out of {active} months are planned",
    plannedInfoVideos: "{planned} out of {active} videos are planned",
    pleaseChoose: "Please choose",
    regularType: "Regular",
    manualType: "Manually",
    baseData: "Base Data",
    manuallyCreatedBy: "Manually created by",
    registrationDate: "Registration date",
    participationDate: "Participation date",
    dateOfPurchase: "Date of purchase",
    source: "Source",
    participation: "Participation",
    notes: "Notes",
    payProduct: "mitfitPAY Product",
    addProduct: "Add product",
    addOffer: "Add offer",
    addContract: "Add contract",
    finePrint: "The fineprint",
    vatRate: "Tax rate",
    currency: "Currency",
    image: "Image",
    additionalCosts: "Extra cost",
    contract: "Contract",
    contracts: "Contracts",
    grossPrice: "Gross price",
    totalPrice: "Total price",
    netPrice: "Net price",
    vatValue: "VAT value",
    paymentMethod: "Payment method",
    onlyForOffers: "For offers only",
    isB2B: "B2B",
    active: "Active",
    inactive: "Inactive",
    oClock: "o`clock",
    question: "Question",
    answer: "Answer",
    imageriddleanswer: "Answer picture puzzle",
    win: "Win",
    accessibility: "Accessibility",
    customneeds: "Custom needs",
    pollresults: "Poll results",
    poll: "Poll",
    addNote: "Add note",
    marked: "marked",
    demarked: "demarked",
    areYouSure: "Are you sure?",
    pleaseKeepInMind: "Please keep in mind",
    lostHint: `You want to mark the person as "Lost". Before you give up this prospect, did you take these three points into account when processing?
    <ul>
    <li> Called at least 3 times at different times </li>
    <li> Double call technique used </li>
    <li> Besides e-mail also contacted via other channels (Facebook, Whatsapp) </li>
    </ul>`,
    markAsLost: "Mark as Lost",
    Cancel: "Cancel",
    cancel: "Cancel",
    participant: "Participant",
    participants: "Participants",
    activity: "Activity",
    tool: "Tool",
    info: "Information",
    leadProcessingStatus: "Lead processing status",
    payProcessingStatus: "Pay processing status",
    Unpayed: "Unpayed",
    Payed: "Payed",
    complete: "Complete",
    notComplete: "Not complete",
    leadDevelopment: "Lead development",
    genderDistribution: "Gender distribution",
    runtime: "Runtime",
    newLeads: "New leads",
    contacts: "contacts",
    safe: "Safe",
    safed: "safed",
    Safed: "Safed",
    lastActivity: "Last activity",
    latestLeads: "The latest leads",
    last5campaigns: "The last 5 campaigns",
    leadsLast5Campaigns: "The leads of the last 5 campaigns",
    news: "News",
    employee: "Employee",
    employees: "Employee",
    companyData: "Company data",
    apis: "APIs",
    companyTerms: "Terms of Service (mitfitPAY)",
    contractForOrderProcessing: "Contract for order data processing",
    avatar: "Profile picture",
    permission: "Permissions",
    permissions: "Permission",
    employeePermission: "Employee permissions",
    employeePermissionInfo:
      "Which plugins can the employee manage? Every authorization includes every authorization within the plugin.",
    required: "required",
    Accept: "Accept",
    accept: "accept",
    Reject: "Reject",
    reject: "reject",
    deleteItemConfirmationText:
      "I am aware that the data will be permanently and irreversibly deleted. A later reactivation is not possible.",
    exportItemConfirmationText:
      "I assure you that the data will be handled and managed in compliance with data protection regulations (see GDPR). The exported personal data is entirely my responsibility.",
    error: "Error",
    emailIsAlreadyInUse:
      "The email address is already in use. Please use another one.",
    mailMustBeValid: "The email address must be valid",
    city: "City",
    zipCode: "Postal code",
    businessName: "Business name",
    corporateForm: "Corporate form",
    authorizedToRepresent: "Authorized to represent",
    taxId: "Tax-ID",
    responsibleForTheContent: "Responsible for the content",
    dataProtectionOfficer: "Data protection officer",
    dataProtectionMail: "E-mail address for data protection issues",
    adress: "Adress",
    legalInformation: "Legal informations",
    username: "Username",
    password: "Password",
    url: "Web adress (URL)",
    studioNumber: "Studio number",
    title: "Title",
    thumbnail: "Thumbnail",
    visibility: "Visibility",
    thankYou: "Thank you",
    thankYouMessage: "Thank you text",
    textInMail: "Text in mail",
    emailCustomerIsActive: "Product has its own thank you text",
    thankYouTextFromOffer: "The thank you texts are used from the offer.",
    placeholder: "Placeholder",
    offerProductsInfo: "Products included in this offer.",
    contractsEmbedCodeIntro:
      "In order to be able to offer your contracts on your homepage, simply copy the code below and paste it into a suitable place on your website.",
    contractsEmbedCodeOutro:
      "If you only want to make certain contracts available for selection, you can use the data attribute 'data-contractIds ='. Simply insert the IDs of the contracts that should be made available for selection. Multiple IDs must be separated with a comma.",
    changePassword: "Change password",
    changeAvatar: "Change avatar",
    passwordsMustMatch: "Passwords must match",
    repeatPassword: "Repeat password",
    minimum8Chars: "Use at least 8 characters",
    itemsPerPage: "Items per page",
    of: "of",
    watch: "watch",
    Watch: "Watch",
    Duplicate: "Duplicate",
    duplicate: "duplicate",
    Activate: "Activate",
    showLeads: "Show leads",
    preview: "Preview",
    statistics: "Statistics",
    Export: "Export",
    export: "export",
    exportLeads: "Export leads",
    exportLeadsMessage: "Here you can export participant data.",
    exportLeadsWhichData: "What data do you want to export?",
    passwordProtection: "Password protection",
    Delete: "Delete",
    delete: "delete",
    createLeadManually: "Create lead manually",
    walkin: "Walk-In",
    socialmedia: "Social-Media",
    custom: "Custom",
    note: "Note",
    manualLeadOptIn:
      "I obtained and documented the permission to save the personal data from the data subject.",
    alreadyClient: "Already a customer",
    mailAlreadyExists: "Email address already exists!",
    mailAlreadyExistsInfo:
      "A user with this email address already exists in the system. Please check whether this lead has already been created.",
    noPermissions: "You are currently not authorized to view this data.",
    noData: "There is currently no data here.",
    noAdditionalData: "No additional data is available.",
    quarterVideo: "Quarterly video",
    season: "Season",
    yearplanTemplates: "Annual marketing templates",
    template: "Template",
    AdditionalData: "Additional data",
    ObaResults: "Results of the online needs analysis",
    /* GENDER */
    salutation: "Salutation",
    gender: "Gender",
    mr: "Mr",
    mrs: "Mrs",
    Male: "Male",
    male: "male",
    Female: "Female",
    female: "female",
    Diverse: "Diverse",
    diverse: "diverse",
    divers: "Diverse",
    /* REACHABILLITY */
    inTheMorning: "In the morning",
    noon: "Noon",
    afternoon: "Afternoon",
    atEvening: "In the evening",
    /* PEOPLE */
    fullname: "Name",
    firstName: "First name",
    lastName: "Last name",
    email: "e-mail",
    phone: "Phone",
    mobile: "Mobile",
    reachability: "Reachability",
    contactType: "Contact type",
    client: "Client",
    contacted: "contacted",
    Contacted: "Contacted",
    notContacted: "Not contacted",
    scheduled: "scheduled",
    Scheduled: "Scheduled",
    redeemed: "redeemed",
    Redeemed: "Redeemed",
    begun: "Redeemed",
    bought: "bought",
    Bought: "Boughts",
    lost: "lost",
    Lost: "Lost",
    Appointment: "Appointment",
    Closed: "Closed",
    closed: "closed",
    Open: "Open",
    open: "open",
    incomplete: "incomplete",
    Incomplete: "Incomplete",
    SepaMissing: "SEPA missing",
    Complete: "Complete",
    Close: "Close",
    close: "close",
    unedited: "unedited",
    Unedited: "Unedited",
    unknown: "unknown",
    Unknown: "Unknown",
    addEmployee: "Create employees",
    rating: "Rating",
    Anybody: "Anybody",
    anybody: "anybody",
    Nobody: "Nobody",
    nobody: "nobody",
    AnybodyWithPassword: "Anybody with password",
    uploadAVideo: "Upload a Video",
    description: "Description",
    videoFile: "Video file",
    file: "File",
    files: "Files",
    products: "Products",
    product: "Product",
    orders: "Orders",
    order: "Order",
    invoice: "Invoice",
    sales: "Sales",
    campaignOffers: "Campaign Offers",
    campaignOffer: "Campaign Offer",
    environment: "Environment",
    liveEnvironment: "Live-Environment",
    sandboxEnvironment: "Sandbox-Environment",
    accessData: "Access data",
    emdedCode: "Embed code",
    addons: "Addons",
    billingPeriod: "Billing period",
    promotion: "Promotion",
    features: "Features",
    onePerRow: "One per row",
    bestOffer: "Best offer",
    periodOfTime: "Period of time",
    factor: "Factor",
    message: "Message",
    remarks: "Remarks",
    annotation: "Annotation",
    extraCosts400: "(Additional costs: 400 EUR)",
    NONE: "Uploading",
    TRANSCODING: "Data is being converted",
    DELETE: "Will be deleted",
    THUMBNAIL: "Create thumbnail",
    accountHolder: "Account holder",
    Birthday: "Birthday",
    Terminated: "Terminated",
    recommendationUrl: "Recommendation-URL",
    recommendationUrlHint:
      "Enter a URL here that your customers can pass on to their family, friends and acquaintances.",
    ageVerification: "Age verification",
    minimumAge: "Minimum age",
    excludeUnderage: "Exclude underage",
    underageText: "Text for underage",
    /* Date Time */
    date: "Date",
    startDate: "Startdate",
    startTime: "Starttime",
    endTime: "Endtime",
    activationDate: "Activation date",
    creationDate: "Creation Date",
    month: "Month",
    day: "Day",
    week: "Week",
    year: "Year",
    quarter: "Quarter",
    halfYear: "Half-Year",
    january: "January",
    february: "February",
    march: "March",
    april: "April",
    may: "May",
    june: "June",
    july: "July",
    august: "August",
    september: "September",
    october: "October",
    november: "November",
    december: "December",
    once: "once",
    daily: "daily",
    weekly: "weekly",
    monthly: "monthy",
    quarterly: "quarterly",
    halfYearly: "Half yearly",
    yearly: "yearly",
    chooseDateTimeOfMeeting: "Choose the time of the appointment",
    type: "Type",
    /* MESSAGES */
    messageUpdated: "The data record was updated successfully!",
    messageCreated: "The data record was created successfully!",
    messageDeleted: "The data record was deleted successfully!",
    messageExported: "The data record was exported successfully!",
    messageError:
      "Something went wrong. Please try again or contact beratung@face-force.de directly!",
    messagePlanActivated: "The plan has been activated successfully!",
    /* COUNTRIES */
    country: "Country",
    germany: "Germany",
    austria: "Austria",
    swizerland: "Swizerland",
    netherland: "Netherlands",
    belgium: "Belgium",
    france: "France",
    poland: "Poland",
    hungary: "Hungary",
    denmark: "Denmark",
    england: "England",
    usa: "USA",
    /* BUSINESS-FORMS */
    bfCorporation: "Joint stock company",
    bfRegisteredBusinessman: "Registered merchant",
    bfRegisteredAssociation: "registered association",
    bfSoleTrader: "Sole trader",
    bfSoleProprietorship: "Sole proprietorship",
    bfFreelancer: "Freelancer",
    bfCivilLawSociety: "Society under civil law",
    bfCompanyWithLimitedLiability: "Company with limited liability",
    bfPrivateCitizen: "Private citizen",
    bfEntrepreneurship: "Entrepreneurship",
    bfOpenTradingCompany: "Open trading company",
    bfOther: "Other",
    /* CONTENT CLOUD */
    contentCloudIntro:
      "A constantly maintained social media presence (e.g. Facebook or Instagram) contributes significantly to the company's success today. That is why it is very important to place the right posts on your social media channels. The Content Cloud provides you with a variety of hand-picked best-practice examples for successful content marketing for your company.",
    contentCloudGeneralInfo:
      "<strong> Note the following points if you want to successfully create a post: </strong> </p> <p> All employees (who are logged on to Facebook) like the post within the first 10 minutes (click like it). Employees can also share the post. However, please only comments from employees in relation to comments from members. Very important: no employee should write advertising texts as comments. This looks posed and reduces the authenticity and thus the positive effect. </p> <p> It is ideal if several members (including the testimonials shown) comment on the contribution with their experiences of success within the first minutes after publication. </ p> <p> The comments of the members MUST be responded to by employees (in the sense of positive reinforcement). In the club you don't just turn away when someone speaks to you. Anyone who does not react to comments will damage their online marketing in the long term. </p>",
    reduceFluctuations: "Reduce fluctuations",
    arouseInterest: "To arouse interest",
    increaseSales: "Increase sales",
    productStaging: "Product staging",
    increaseCustomerLoyalty: "Increase customer loyalty",
    viewPost: "View post",
    noNotesExist: "No notes exist",
    /* ADDONS */
    videoLibrary: "Video-Library",

    annual_marketing: {
      title: "Annual marketing Flex",
      description:
        "Here you can find your personal annual marketing plan. If your next upcoming campaign is not yet in production, you can make changes to your annual plan at any time.",
    },

    /* HELP */
    helpDashboard: {
      title: "Dashboard",
      content:
        "<p>Du befindest dich auf dem Dashboard, der Kommando-Zentrale des mitfit-Systems. Hier hast du alles direkt im Überblick. Am linken Bildschirmrand findest du das Menü, welches dich zu den jeweiligen Bereichen navigiert.</p><p>Sofern du Multi-Administrator bist, kannst du per Klick auf deinen Studionamen (oberhalb Dashboard) zwischen deinen Standorten wechseln.</p>",
    },
    helpJmp: {
      title: "Jahresmarketing",
      content:
        "<p>Das Herzstück deiner professionellen Marketingplanung!<br/>Hier kannst du deine von uns bereits vorkonfigurierte Jahresplanung verwalten und jederzeit Änderungen vornehmen.</p><p>Die Übersicht zeigt dir den aktuellen Änderungs- und Planungsstatus deiner Jahresplanung an. Mit Klick auf den Vorschau-Button kannst du deine derzeitige Planung betrachten.</p>",
    },
    helpLeads: {
      title: "LEADS",
      content:
        "<p>Alle Leads, die über die Kampagnen generiert werden, sind hier in einer Gesamtübersicht dargestellt. Mit Klick auf das Auge auf der rechten Seite gelangst du zum Personen-Datensatz.</p><p>Über den Button <strong>Einen Lead manuell anlegen +</strong> kannst du sogar externe Leads zum System hinzufügen. Durch die Angabe der Herkunft (z.B. Walk-In/Call-In, Social-Media) gehen dir keine Interessenten mehr verloren. So kannst du mitfit beispielsweise auch optimal für Promotion-Stände nutzen, bei denen du Leads digital festhalten willst. Weitere Informationen zur Person wie E-Mailadresse oder Telefonnummer kannst du bei Bedarf jederzeit nachträglich ergänzen.</p>",
    },
    helpCampaigns: {
      title: "Kampagnen",
      content:
        "<p>Unter LEADS werden alle Kampagnen zur Leadgenerierung (auch HR-Recruitment Kampagnen) aufgelistet. Die Statusanzeige gibt dir dabei an, ob eine Kampagne abgeschlossen, in Planung oder gerade aktiv ist. Mit Klick auf das Auge (Anschauen-Button) auf der rechten Seite gelangst du zur Detail-Ansicht der jeweiligen Kampagne.</p>",
    },
    helpCampaignsDetail: {
      title: "Kampagnen",
      content:
        "<p>Zu jeder Kampagne steht dir ein übersichtliches Dashboard zur Verfügung, sodass du schnell einen Gesamtüberblick erhältst.</p><p>Unterhalb des Dashboards werden die Teilnehmer der Kampagne aufgelistet. Durch das farbliche Ampelsystem erkennst du auf einen Blick den aktuellen Bearbeitungsstatus. Mit Klick auf das Auge auf der rechten Seite gelangst du zum jeweiligen Personen-Datensatz zur individuellen Bearbeitung.</p><p>Neben den Stammdaten kannst du direkte Anmerkungen zur Person machen. Diese helfen dir und deinem Team zur besseren, persönlichen Beratung. Je mehr Angaben dir zu einer Person zur Verfügung stehen, desto zielführender ist die anschließende Beratungssituation mit dem Kunden. Top-Berater kennen Ihre Interessenten ganz genau!</p><p>Kampagnenbezogene Infos zur Person (z.B. Bringt den Freund zum Termin mit) kannst du unterhalb im Notizbereich der Lead Aktivitäten festhalten. In den Lead-Aktivitäten werden alle Informationen zu Kampagnen-Teilnahmen sowie dem Bearbeitungsstatus dargestellt.</p><p>Die Schieberegler dokumentieren den Kundenstatus direkt unterhalb in den Notizen ohne weiteren Aufwand. Der dokumentierte Bearbeitungszeitpunkt sowie die Angabe zum verantwortlichen Mitarbeiter sorgt ebenfalls für Klarheit bei größeren Teams zur optimalen Lead-Bearbeitung.</p>",
    },
    helpMitfitPay: {
      title: "mitfitPAY",
      content: `<h3>Verkäufe</h3>
      <p>Hier findest du die Übersicht der Verkäufe. Der Status verrät dir, ob ein Kauf abgeschlossen, oder abgebrochen wurde. Sobald ein Verkauf abgeschlossen wurde, kannst du dir die entsprechende Rechnung herunterladen, indem du auf den Download-Button klickst.</p> 
      <h3>Produkte</h3>
      <p>Hier kannst du die Produkte deines Shops erstellen und verwalten.</p>
      <p>Zudem kannst du für jedes Produkt individuell einstellen, ob das Produkt verfügbar ist oder nicht. Die Option 'Nur für Angebote' bedeutet, dass das entsprechende Produkt ausschließlich im Kontext eines Angebotes gekauft werden kann. So markierte Produkte werden nicht im Shop-Bereich gelistet.</p>
      <h3>PayPal</h3>
      <p>Hier siehst du die API-Zugangsdaten zu deinem Paypal+ Account. Updates der Daten können aktuell nur von den Administratoren der FACEFORCE vorgenommen werden.</p>
      <h3>Kampagnen-Angebote</h3>
      <p>Angebote sind Zusammenstellungen von Produkten. Diese Zusammenstellungen finden aktuell ausschließlich im Kontext einer FACEFORCE-Kampagne Verwendung. Du kannst einer Kampagne bereits erstellte Produkte zuweisen, sie inaktiv schalten und auch individuelle Texte zuweisen.</p>
      `,
    },
    helpCompany: {
      title: "Mein Unternehmen",
      content: `<p>Alle Angaben zu deinem Unternehmen kannst du hier einsehen und ändern.</p>
      <h3>Mitarbeiter</h3>
      <p>Unter Mitarbeiter kannst du neue Mitarbeiter hinzufügen und individuelle Berechtigungen festlegen. Beachte, dass Mitarbeiter-Rechte stets standortbezogen sind und fest einem Standort zugeordnet werden. Soll die Person mehrere Standorte verwalten, so kann hierfür ein Multi-Administrator angelegt werden.</p>
      <h3>Unternehmensdaten</h3>
      <p>Hier kannst du die Unternehmensadresse sowie rechtliche Angaben zum Unternehmen einsehen. Sollte etwas falsch sein, kontaktiere uns bitte.</p>
      `,
    },
    helpGdpr: {
      title: "DSGVO",
      content:
        "<p>Der Bereich DSGVO ist ähnlich aufgebaut wie der Lead-Bereich. Hier werden alle Datensätze aufgelistet, sodass du auf Wunsch von Interessenten jederzeit deiner gesetzlichen Auskunftspflicht als Unternehmen nachkommen kannst. Zusätzlich hast du hier die Möglichkeit, Personen-Datensätze DSGVO-konform zu löschen.</p>",
    },
    helpService: {
      title: "Service",
      content: `<p>Im Service-Bereich findest du wichtige Informationen, wie beispielsweise Ablaufbeschreibungen oder Telefonleitfäden.</p>
      <p>Darüber hinaus unterstützen wir im Rahmen des Jahresmarketing-Pakets dein Unternehmen kostenfrei mit unserer Social-Media Content Cloud. Diese wertvolle Sammlung erleichtert die Planung und Erstellung guter Social-Media-Beiträge auf Facebook, Instagram & Co., immer passend zu den jeweiligen Kernbereichen.</p>`,
    },
    helpSettings: {
      title: "Einstellungen",
      content: `<p>Hier kannst du dein Profilbild ändern und ein neues Kennwort für deinen persönlichen mitfit-Zugang vergeben.</p>`,
    },
  },

  nl: {
    legacyBlockNote:
      "U heeft een Admin 3.0-account. 3.0 Beheerders kunnen geen gegevens maken of bewerken die zijn gemaakt in de verouderde versie 2.0. Neem contact met ons op voor meer informatie.",
    hello: "Hallo Wereld",
    plugins: {
      xmasPresent: {
        name: "kerstcadeau",
        title: "Je FACEFORCE kerstcadeau",
        description:
          "Hier is de introtekst voor het kerstcadeau 2021 ... de vouchercodes voor de meditatie-app.",
        dashboardMessage:
          "<h2 class='mb-2'>@:plugins.xmasPresent.title</h2><p>Het speciale cadeau voor je leden: outa - meditatie & slapen, 1 maand premium lidmaatschap gratis!<br/>Je hebt 100 actiecodes tot je beschikking.</p>",
        infoText: `<p> U kunt de hier vermelde codes rechtstreeks aan uw leden weggeven. Elke code bevat een gratis premium lidmaatschap van 30 dagen voor de gloednieuwe meditatie- en slaap-app "outa" </p>
        <p> Nadat je een code hebt gemarkeerd met "@: plugins.xmasPresent.Assign", kun je de juiste info-pdf voor de code downloaden en naar je lid sturen. </p>`,
        Code: "Code",
        YourCodes: "Uw codes",
        Assign: "Toegewezen",
        PdfDownload: "Download PDF",
        GetYourCodes: "Ontvang je vouchercodes",
        Tutorial: "Aandleiding (duits)",
      },
    },
    funnel: {
      sent: "Verzonden",
      open: "Geopend",
      cancelled: "Geannuleerd",
    },
    /* Page descriptions */
    pageDescriptions: {
      eventBookings: "Hier vindt u een overzicht van alle boekingen.",
      dashboard: "Alles wat essentieel is in één oogopslag.",
      am: "Hier vindt u uw persoonlijke marketingjaarplan. Als uw volgende aanstaande campagne nog niet in productie is, kunt u op elk gewenst moment wijzigingen aanbrengen in uw jaarplan.",
      amTemplates:
        "Hier vindt u uw persoonlijke jaarlijkse marketingsjablonen. U kunt deze sjablonen kopiëren naar de plannen van de bedrijven waar u toezicht op houdt.",
      amTemplate:
        "Hier kunt u uw jaarlijkse marketingplansjabloon bewerken. Als je klaar bent, kun je deze vervolgens overzetten naar je bestaande abonnementen.",
      leads:
        "Hier vindt u een overzicht van de contacten die door de campagnes zijn gegenereerd.",
      campaigns: "Uw campagnes in één oogopslag.",
      campaignsStatistik: "Actuele statistieken over de campagne",
      payProducts:
        "Hier kunt u uw producten, uw bestellingen, PayPal-gegevens en aanbiedingen beheren.",
      payContracts: "Hier kunt u uw contracten beheren.",
      addonsShops: "Hier kunt u uw winkels beheren.",
      payEmbedCodes:
        "Om uw contracten op uw homepage aan te kunnen bieden, kopieert u simpelweg onderstaande code en plakt u deze op een geschikte plaats op uw website.",
      company: "Hier vindt u alle relevante instellingen voor uw bedrijf.",
      companyTeam: "Hier kunt u medewerkers aanmaken en beheren.",
      companyData: "Hier kunt u de basisgegevens voor uw bedrijf beheren.",
      companyTerms:
        "Hier kunt u de algemene voorwaarden voor uw bedrijf opslaan.",
      companyApis:
        "Externe providers kunnen hier worden aangesloten. De selectie groeit gestaag.",
      gdpr: "Hier vindt u mogelijkheden om informatie over persoonsgegevens te verstrekken en om gegevens te verwijderen.",
      service:
        "Hier kunt u een volledige lading informatie krijgen, evenals exclusieve diensten voor het succes van uw bedrijf.",
      serviceInfo:
        "Hier vind je alle belangrijke informatie om het maximale uit je campagnes te halen.",
      serviceCc:
        "Een constant onderhouden aanwezigheid op sociale media (bijv. Facebook of Instagram) draagt ​​​​aanzienlijk bij aan het succes van het bedrijf vandaag. Daarom is het erg belangrijk om de juiste posts op je social media kanalen te plaatsen. De Content Cloud biedt u een verscheidenheid aan zorgvuldig geselecteerde best-practicevoorbeelden voor succesvolle contentmarketing voor uw bedrijf.",
      settings: "Hier kunt u instellingen maken voor uw account.",
      settingsPassword: "Hier kunt u uzelf een nieuw wachtwoord toewijzen",
      settingsAvatar: "Hier kun je een nieuwe profielfoto uploaden.",
      planHelp:
        '<strong>Onze ondersteuning voor vragen. </strong> Als u hulp nodig heeft bij de verwerking van uw marketingplanner, kunt u op elk moment contact met ons opnemen via de ondersteuningsmail <a href="mailto:support@face-force.de">support@face-force.de</a>. Eventuele aanpassingen die u nodig heeft, bijvoorbeeld bij het vaststellen van winsten in campagnes of in het tijdsbestek van testpersooncampagnes, kunnen indien nodig ook via de ondersteuningsmail worden gecommuniceerd.',
      xmasPresent:
        "Hier steht der Introtext für das Weihnachtsgeschenk 2021 ... die Voucher-Codes für die Meditations-App.",
      connections:
        "Hier kunt u uw externe accounts koppelen. De selectie groeit gestaag.",
    },
    annualMarketingAd: {
      title: "Jaarlijkse marketing Flex - word lid of stap nu over!",
      text: "HOLISTISCHE OPLOSSINGEN - STAND VAN DE KUNST! Het succes van de jaarmarketing is gebaseerd op het gebruik en koppelen van verschillende ontwikkelde mechanismen en strategieën die het jaarmarketingpakket tot een echte alles-in-één oplossing voor zorgaanbieders maken.",
    },
    branches: {
      fitness: "Fitnessindustrie",
      physio: "Physioindustrie",
      ems: "EMSindustrie",
      golf: "Golfindustrie",
    },
    /* ROUTES */
    dashboard: "Dashboard",
    jmp_fitness: "Jaarlijkse marketing",
    EventBookings: "Evenementenboekingen",
    mitfitSHOPS: "mitfitSHOPS",
    jmp_fitness_desc:
      "Hier vind je jouw persoonlijke marketingplan. Als je volgende campagne nog niet in productie is kan je altijd aanpassingen maken in je jaarlijkse plan.",
    jmp_templates_desc:
      "Hier vindt u uw persoonlijke jaarlijkse marketingsjablonen. U kunt deze sjablonen kopiëren naar de plannen van de bedrijven waar u toezicht op houdt.",
    jmp_plan_desc:
      "Hier vindt u uw persoonlijke marketingjaarplan. Als uw volgende aanstaande campagne nog niet in productie is, kunt u op elk gewenst moment wijzigingen aanbrengen in uw jaarplan.",
    jmp_template_desc:
      "Hier kunt u uw jaarlijkse marketingplansjabloon bewerken. Als u klaar bent, kunt u deze vervolgens overzetten naar uw bestaande plannen.",

    jmp_golf: "Jaarlijkse marketing",
    leads: "Leads",
    campaigns: "Campagnes",
    mitfitMEMBER: "mitfitMEMBER",
    shop: "Shop",
    company: "Mijn zaak",
    gdpr: "AVG",
    service: "Onderhoud",
    settings: "Instellingen",
    mitfitPAY: "mitfitPAY",
    xmas: "Jouw kerstcadeau",
    connections: "Verbindingen",
    /* JMP */
    annualMarketingPlan: "Jaarlijks marketingplan",
    planInformation: "Informatie over het plan",
    jmp: "Jaarlijks marketingpakket",
    jmppd: "Jaarlijks marketingpakket Premium Dynamic",
    raffleCampaign: "Loterij campagne",
    bonusCampaign: "Bonuscampagne",
    dynamicCampaign: "Dynamische campagne",
    testersCampaign: "Testers-campagne",
    physiodynamic: "Physio-Dynamic",
    physioeventkampagne: "Physio-Eventcampaign",
    physiooptional: "Optional-Campaign",
    advertisingBudget: "Reclame budget",
    inactivePlanMessage:
      "<strong>PLAN INACTIEF:</strong> Door het marketingplan te activeren, worden al uw campagnes inclusief de starttijden bindend ingevoerd volgens onze aanbevelingen en gemaakt op de respectieve opgeslagen datum. Als u wijzigingen wilt aanbrengen, kunt u uw marketingplan nu na activering handmatig bewerken en campagnes en starttijden op elk gewenst moment wijzigen.",
    activatePlanMessage:
      "<strong> Let op: </strong> Uw marketingplan wordt nu geactiveerd. U kunt uw plan echter direct daarna bekijken en op elk gewenst moment wijzigingen aanbrengen.",
    activatePlan: "Activeer plan",
    nocampaign: "Pause",
    messages: "gesprek",
    /* CONTROLS */
    edit: "Aanpassen",
    search: "Zoeken",
    searchterm: "Zoekterm",
    status: "Toestand",
    updatedate: "Laatste update",
    actions: "Acties",
    createNewTemplate: "Een nieuwe sjabloon maken",
    createNewTerms: "Nieuwe voorwaarden maken",
    getMore: "Meer laden",
    /* PHRASES */
    bonusCalendarBonus: "Bonuskalenderbonus",
    cancelOrder: "Bestelling annuleren",
    orderIsCancelled: "Bestelling is geannuleerd",
    cancelOrderMessage: "Weet je zeker dat je de bestelling wilt annuleren?",
    cancelSuccessMessage: "De bestelling is succesvol geannuleerd.",
    cancelReason: "Annuleringsreden",
    leadIsOptOutMessage:
      "Deze lead heeft zich afgemeld voor verdere communicatie.",
    furtherParticipations: "Verdere deelnames",
    furtherParticipationsInfo: "De eigenlijke campagne is niet inbegrepen.",
    mitfitLeads: "mitfitLEADS",
    Persons: "Personen",
    Activity: "Activiteit",
    underageParticipant: "Minderjarige deelnemer",
    underage: "Minderjarig",
    adSource: "Advertentiebron",
    address: "Adres",
    deleted: "Verwijderd",
    Promotion: "Promotion",
    promoTitle: "Promotion title",
    promoPrice: "Promotion price",
    Meta: "Meta",
    Details: "Details",
    UseAnExternProvider: "Use an external provider",
    startAfterPromo: "Start after promotion",
    apiProviders_id: "Provider ID",
    apiProvidersContractId: "Vendor Contract ID",
    externalProvider: "External Provider",
    Payments: "Payments",
    Payment: "Payment",
    PaymentStatus: "Payment status",
    PaymentReminder: "Payment reminder",
    membershipGrowth: "Membership growth",
    allowSepa: "Allow SEPA payments",
    noPayment: "No payment",
    noPaymentText: "Text to be displayed when payment is not possible.",
    allowDirectDebit: "Allow direct debit payments",
    allowInvoice: "Allow invoice payments",
    allowCreditCard: "Allow credit card payments",
    allowPayPal: "Allow PayPal payments",
    externalProviderInfo: `<p>
    Here you have the option of purchasing with an external
     to synchronize providers.<br />For this you have to
     API credentials of the respective provider in the API area (My
     Company -> API).</p>`,
    promotionInfo: `<p>Do you want to sell the contract for a reduced price for a certain period of time? Here's your chance.</p>`,
    Contract: "Contract",
    Subject: "Onderwerp",
    version: "Version",
    All: "Alle",
    yes: "Ja",
    no: "Nee",
    by: "door",
    campaign: "Campagne",
    companyPhrase: "Bedrijf",
    oclock: "uur",
    plannedInfo: "{planned} van de {active} maanden zijn gepland",
    plannedInfoVideos: "{planned} van de {active} video's gepland",
    pleaseChoose: "Gelieve te kiezen",
    regularType: "Normaal",
    manualType: "handmatig",
    baseData: "Basisgegevens",
    manuallyCreatedBy: "Handmatig aangemaakt door",
    registrationDate: "Registratie datum",
    participationDate: "Deelnamedatum",
    dateOfPurchase: "Aankoopdatum",
    source: "Bron",
    participation: "Deelname",
    notes: "Notities",
    payProduct: "mitfitPAY Product",
    addProduct: "Product toevoegen",
    addOffer: "Voeg een aanbieding toe",
    addContract: "Contract toevoegen",
    finePrint: "De kleine lettertjes",
    vatRate: "Belastingtarief",
    currency: "Valuta",
    image: "Afbeelding",
    additionalCosts: "Extra kosten",
    contract: "Contract",
    contracts: "Contracts",
    grossPrice: "Bruto prijs",
    totalPrice: "Totale prijs",
    netPrice: "Nettoprijs",
    vatValue: "BTW-waarde",
    paymentMethod: "Betaalmethode",
    onlyForOffers: "Alleen voor aanbiedingen",
    isB2B: "B2B",
    active: "Actief",
    inactive: "Inactief",
    oClock: "uur",
    question: "Vraag",
    answer: "Antwoord geven",
    imageriddleanswer: "Antwoord afbeelding puzzel",
    win: "Winst",
    accessibility: "Toegankelijkheid",
    customneeds: "Aangepaste behoeften",
    pollresults: "Poll resultaten",
    poll: "Poll",
    addNote: "Bijgevoegde opmerking:",
    marked: "gemarkeerd",
    demarked: "gedemarkeerd",
    areYouSure: "Weet je zeker dat?",
    pleaseKeepInMind: "Houd er rekening mee",
    lostHint: `U wilt de persoon markeren als "Verloren". Heeft u, voordat u dit vooruitzicht opgeeft, rekening gehouden met deze drie punten bij de verwerking?
    <ul>
    <li> Minstens 3 keer gebeld op verschillende tijdstippen </li>
    <li> Dubbele oproeptechniek gebruikt </li>
    <li>Naast e-mail ook gecontacteerd via andere kanalen (Facebook, Whatsapp) </li>
    </ul>`,
    markAsLost: "Markeren als verloren",
    Cancel: "Afbreken",
    cancel: "afbreken",
    participant: "Deelnemer",
    participants: "Deelnemer",
    activity: "Activiteiten",
    tool: "Tool",
    info: "Information",
    leadProcessingStatus: "Leiding Verwerkingsstatus",
    payProcessingStatus: "Betaling Verwerkingsstatus",
    Unpayed: "Niet betaald",
    Payed: "Betaald",
    complete: "Voltooid",
    notComplete: "Niet voltooid",
    leadDevelopment: "Leiding Ontwikkeling",
    genderDistribution: "Geslachtsverdeling",
    runtime: "lopende tijd",
    newLeads: "Nieuwe leads",
    contacts: "contacten",
    safe: "Opslaan op computer",
    safed: "gered",
    Safed: "Gered",
    lastActivity: "Laatste Activiteit",
    latestLeads: "De nieuwste leads",
    last5campaigns: "De laatste 5 campagnes",
    leadsLast5Campaigns: "De leads van de laatste 5 campagnes",
    news: "Nieuws",
    employee: "Medewerker",
    employees: "Medewerker",
    companyData: "Bedrijfsgegevens",
    apis: "APIs",
    companyTerms: "Servicevoorwaarden",
    contractForOrderProcessing: "Contract voor de verwerking van ordergegevens",
    avatar: "Profielfoto",
    permission: "Rechten",
    permissions: "Toestemming",
    employeePermission: "Werknemersrechten",
    employeePermissionInfo:
      "Welke plugins kan de medewerker beheren? Elke autorisatie omvat elke autorisatie binnen de plug-in.",
    required: "verplicht",
    Accept: "Aanvaarden",
    accept: "aanvaarden",
    Reject: "Afwijzen",
    reject: "afwijzen",
    deleteItemConfirmationText:
      "Ik ben me ervan bewust dat de gegevens permanent en onomkeerbaar worden verwijderd. Een latere heractivering is niet mogelijk.",
    exportItemConfirmationText:
      "Ik verzeker u dat de gegevens worden behandeld en beheerd in overeenstemming met de voorschriften voor gegevensbescherming (zie AVG). De geëxporteerde persoonsgegevens zijn geheel mijn verantwoordelijkheid.",
    error: "Mislukking",
    emailIsAlreadyInUse:
      "Dit e-mailadres is al in gebruik. Gebruik een andere.",
    mailMustBeValid: "Het e-mailadres moet geldig zijn",
    city: "Stad",
    zipCode: "Postcode",
    businessName: "Bedrijfsnaam",
    corporateForm: "bedrijfsvorm",
    authorizedToRepresent: "Bevoegd om te vertegenwoordigen",
    taxId: "BTW-nummer",
    responsibleForTheContent: "Verantwoordelijk voor de inhoud",
    dataProtectionOfficer: "Functionaris voor gegevensbescherming",
    dataProtectionMail: "E-mailadres voor problemen met gegevensbescherming",
    adress: "adres",
    legalInformation: "Legale informatie",
    username: "Gebruikersnaam",
    password: "Wachtwoord",
    url: "Webadres (URL)",
    studioNumber: "Studio nummer",
    title: "Titel",
    thumbnail: "Miniatuur",
    visibility: "Zichtbaarheid",
    thankYou: "Bedankt",
    thankYouMessage: "Bedankt tekst",
    textInMail: "Sms in e-mail",
    emailCustomerIsActive: "Product heeft een eigen bedanktekst",
    thankYouTextFromOffer: "De bedankteksten zijn gebruikt uit het aanbod.",
    placeholder: "Tijdelijke aanduiding",
    offerProductsInfo: "Producten inbegrepen in deze aanbieding.",
    contractsEmbedCodeIntro:
      "Om uw contracten op uw homepage aan te kunnen bieden, kopieert u simpelweg onderstaande code en plakt u deze op een geschikte plaats op uw website.",
    contractsEmbedCodeOutro:
      "Als u alleen bepaalde contracten voor selectie beschikbaar wilt stellen, kunt u het data-attribuut 'data-contractIds =' gebruiken. Voer eenvoudig de ID's in van de contracten die beschikbaar moeten worden gesteld voor selectie. Meerdere ID's moeten worden gescheiden door een komma.",
    changePassword: "Verander wachtwoord",
    changeAvatar: "Profielfoto veranderen",
    passwordsMustMatch: "De wachtwoorden moeten overeenkomen",
    repeatPassword: "Herhaal wachtwoord",
    minimum8Chars: "Gebruik minimaal 8 tekens",
    itemsPerPage: "Elementen per pagina",
    of: "von",
    watch: "kijk naar",
    Watch: "Kijk naar",
    Duplicate: "Duplicaat",
    duplicate: "duplicaat",
    Activate: "Activeren",
    showLeads: "Toon leads",
    preview: "Voorbeeld",
    statistics: "Statistieken",
    Export: "Exporteren",
    export: "exporteren",
    exportLeads: "Leads exporteren",
    exportLeadsMessage: "Hier kunt u deelnemersgegevens exporteren.",
    exportLeadsWhichData: "Welke gegevens wilt u exporteren?",
    passwordProtection: "Wachtwoord beveiliging",
    Delete: "Verwijderen",
    delete: "verwijderen",
    createLeadManually: "Handmatig een lead maken",
    walkin: "Walk-In",
    socialmedia: "Social-Media",
    custom: "Custom",
    note: "Opmerking",
    manualLeadOptIn:
      "Ik heb toestemming gekregen en gedocumenteerd om persoonsgegevens van de betrokkene op te slaan.",
    alreadyClient: "Gebruiker is al klant",
    mailAlreadyExists: "E-mailadres bestaat al!",
    mailAlreadyExistsInfo:
      "Er bestaat al een gebruiker met dit e-mailadres in het systeem. Controleer of deze lead al is aangemaakt.",
    noPermissions: "U bent momenteel niet bevoegd om deze gegevens in te zien.",
    noData: "Er zijn hier momenteel geen gegevens.",
    noAdditionalData: "Er zijn geen aanvullende gegevens beschikbaar.",
    quarterVideo: "Driemaandelijkse video",
    season: "Seizoen",
    yearplanTemplates: "Jaarlijkse marketingsjablonen",
    template: "Jablone",
    AdditionalData: "Aanvullende gegevens",
    ObaResults: "Resultaten van de online behoefteanalyse",
    /* GENDER */
    salutation: "begroeting",
    gender: "geslacht",
    mr: "Dhr",
    mrs: "Mvr",
    Male: "Mannelijk",
    male: "mannelijk",
    Female: "Vrouwelijk",
    female: "vrouwelijk",
    Diverse: "Verschillend",
    diverse: "verschillend",
    divers: "verschillend",
    /* REACHABILLITY */
    inTheMorning: "s ochtends",
    noon: "Middag",
    afternoon: "Namiddag",
    atEvening: "s avonds",
    /* PEOPLE */
    fullname: "Naam",
    firstName: "Voornaam",
    lastName: "Achternaam",
    email: "e-mail",
    phone: "telefoon",
    mobile: "Mobiel",
    contactType: "Contact type",
    client: "Klant",
    contacted: "gecontacteerd",
    Contacted: "Gecontacteerd",
    notContacted: "Niet gecontacteerd",
    scheduled: "beëindigd",
    Scheduled: "Beëindigd",
    redeemed: "ingewisseld",
    Redeemed: "Ingewisseld",
    begun: "Ingewisseld",
    Bought: "Gekocht",
    lost: "lost",
    Lost: "Lost",
    Appointment: "Afspraak",
    Closed: "Gesloten",
    closed: "gesloten",
    Open: "Ruimdenkende",
    open: "ruimdenkende",
    incomplete: "incompleet",
    Incomplete: "Incompleet",
    SepaMissing: "SEPA ontbreekt",
    Complete: "Compleet",
    Close: "Concluderen",
    close: "concluderen",
    unedited: "onbewerkt",
    Unedited: "Onbewerkt",
    unknown: "onbekend",
    Unknown: "Onbekend",
    addEmployee: "Maak medewerkers aan",
    rating: "Beoordeling",
    Anybody: "Iemand",
    anybody: "iemand",
    Nobody: "Niemand",
    nobody: "niemand",
    AnybodyWithPassword: "Iedereen met een wachtwoord",
    uploadAVideo: "Upload een video",
    description: "Omschrijving",
    videoFile: "Video bestand",
    file: "Datei",
    files: "Dateien",
    products: "Producten",
    product: "Product",
    invoice: "Factuur",
    sales: "verkoop",
    campaignOffers: "Campagne-aanbiedingen",
    campaignOffer: "Campagne aanbieding",
    environment: "Omgeving",
    liveEnvironment: "Live-omgeving",
    sandboxEnvironment: "Sandbox-omgeving",
    accessData: "Toegangsgegevens",
    emdedCode: "Embed code",
    addons: "Addons",
    billingPeriod: "Factureringsperiode",
    promotion: "Promotie",
    features: "kenmerken",
    onePerRow: "Een per regel",
    bestOffer: "beste aanbieding",
    periodOfTime: "Periode",
    factor: "factor",
    message: "Bericht",
    remarks: "Opmerkingen",
    annotation: "Annotatie",
    extraCosts400: "(Bijkomende kosten: 400 EUR)",
    NONE: "Uploading",
    TRANSCODING: "Gegevens worden geconverteerd",
    DELETE: "Zal worden verwijderd",
    THUMBNAIL: "Miniatuur maken",
    accountHolder: "Rekeninghouder",
    Birthday: "Verjaardag",
    Terminated: "Ontslag nemen",
    recommendationUrl: "Aanbeveling-URL",
    recommendationUrlHint:
      "Voer hier een URL in die uw klanten kunnen doorgeven aan hun familie, vrienden en kennissen.",
    ageVerification: "Leeftijdsverificatie",
    minimumAge: "Minimumleeftijd",
    excludeUnderage: "Minderjarigen uitsluiten",
    underageText: "Tekst voor minderjarigen",
    /* Date Time */
    date: "Datum",
    startDate: "Startdatum",
    startTime: "Starttijd",
    endTime: "Eindtijd",
    activationDate: "Activeer datum",
    creationDate: "Aanmaakdatum",
    month: "Maand",
    day: "Dag",
    week: "Week",
    year: "Jaar",
    quarter: "kwartaal",
    halfYear: "Half jaar",
    january: "January",
    february: "February",
    march: "March",
    april: "April",
    may: "May",
    june: "June",
    july: "July",
    august: "August",
    september: "September",
    october: "October",
    november: "November",
    december: "December",
    once: "uniek",
    daily: "Elke dag",
    weekly: "wekelijks",
    monthly: "maandelijks",
    quarterly: "per kwartaal",
    halfYearly: "halfjaarlijks",
    yearly: "jaarlijks",
    chooseDateTimeOfMeeting: "Kies het tijdstip van de afspraak",
    type: "Type",
    /* MESSAGES */
    messageUpdated: "Het gegevensrecord is succesvol bijgewerkt!",
    messageCreated: "Het gegevensrecord is succesvol aangemaakt!",
    messageDeleted: "Het gegevensrecord is succesvol verwijderd!",
    messageExported: "Het gegevensrecord is succesvol geëxporteerd!",
    messageError:
      "Er is iets fout gegaan. Probeer het opnieuw of neem rechtstreeks contact op met beratung@face-force.de",
    messagePlanActivated: "Het plan is succesvol geactiveerd!",
    /* COUNTRIES */
    country: "Land",
    germany: "Duitsland",
    austria: "Oostenrijk",
    swizerland: "Zwitserland",
    netherland: "Nederland",
    belgium: "België",
    france: "Frankrijk",
    poland: "Polen",
    hungary: "Hongarije",
    denmark: "Denemarken",
    england: "Engeland",
    usa: "USA",
    /* BUSINESS-FORMS */
    bfCorporation: "bedrijf",
    bfRegisteredBusinessman: "Geregistreerde handelaar",
    bfRegisteredAssociation: "geregistreerde vereniging",
    bfSoleTrader: "Eenmanszaak",
    bfSoleProprietorship: "Eenmanszaak",
    bfFreelancer: "freelancer",
    bfCivilLawSociety: "Maatschappij naar burgerlijk recht",
    bfCompanyWithLimitedLiability: "Bedrijf met beperkte aansprakelijkheid",
    bfPrivateCitizen: "particulier",
    bfEntrepreneurship: "UG (Ondernemerschap)",
    bfOpenTradingCompany: "Open handelsmaatschappij",
    bfOther: "Diversen",
    /* CONTENT CLOUD */
    contentCloudIntro:
      "Een constant onderhouden aanwezigheid op sociale media (bijv. Facebook of Instagram) draagt ​​​​aanzienlijk bij aan het succes van het bedrijf vandaag. Daarom is het erg belangrijk om de juiste posts op je social media kanalen te plaatsen. De Content Cloud biedt u een verscheidenheid aan zorgvuldig geselecteerde best-practicevoorbeelden voor succesvolle contentmarketing voor uw bedrijf.",
    contentCloudGeneralInfo:
      "<strong>Let op de volgende punten als je succesvol een bericht wilt maken: </strong> </p> <p> Alle medewerkers (die zijn ingelogd op Facebook) vinden het bericht leuk binnen de eerste 10 minuten (klik op 'vind ik leuk'). Medewerkers kunnen de post ook delen. Gelieve echter alleen opmerkingen van medewerkers te maken met betrekking tot opmerkingen van leden. Heel belangrijk: geen enkele werknemer mag reclameteksten als commentaar schrijven. Dit lijkt geposeerd en vermindert de authenticiteit en daarmee het positieve effect. </p> <p> Het is ideaal als meerdere leden (inclusief de getoonde getuigenissen) binnen de eerste minuten na publicatie reageren op de bijdrage met hun succesverhalen.</ p > <p> Op de opmerkingen van de leden MOET gereageerd worden door medewerkers (in de zin van positieve bekrachtiging). In de club wend je je niet zomaar af als iemand tegen je praat. Iedereen die niet op reacties reageert, schaadt op den duur zijn online marketing. </p>",
    reduceFluctuations: "Fluctuaties verminderen",
    arouseInterest: "Interesse wekken",
    increaseSales: "Verkoop verhogen",
    productStaging: "Productfasering",
    increaseCustomerLoyalty: "Verhoog de klantloyaliteit",
    viewPost: "Bekijk bericht",
    /* ADDONS */
    videoLibrary: "Videobibliotheek",

    annual_marketing: {
      title: "Jaarlijkse marketing Flex",
      description:
        "Hier vindt u uw persoonlijke marketingjaarplan. Als uw volgende aankomende campagne nog niet in productie is, kunt u op elk moment wijzigingen aanbrengen in uw jaarplan.",
    },

    /* HELP */
    helpDashboard: {
      title: "Dashboard",
      content:
        "<p>Du befindest dich auf dem Dashboard, der Kommando-Zentrale des mitfit-Systems. Hier hast du alles direkt im Überblick. Am linken Bildschirmrand findest du das Menü, welches dich zu den jeweiligen Bereichen navigiert.</p><p>Sofern du Multi-Administrator bist, kannst du per Klick auf deinen Studionamen (oberhalb Dashboard) zwischen deinen Standorten wechseln.</p>",
    },
    helpJmp: {
      title: "Jahresmarketing",
      content:
        "<p>Das Herzstück deiner professionellen Marketingplanung!<br/>Hier kannst du deine von uns bereits vorkonfigurierte Jahresplanung verwalten und jederzeit Änderungen vornehmen.</p><p>Die Übersicht zeigt dir den aktuellen Änderungs- und Planungsstatus deiner Jahresplanung an. Mit Klick auf den Vorschau-Button kannst du deine derzeitige Planung betrachten.</p>",
    },
    helpLeads: {
      title: "LEADS",
      content:
        "<p>Alle Leads, die über die Kampagnen generiert werden, sind hier in einer Gesamtübersicht dargestellt. Mit Klick auf das Auge auf der rechten Seite gelangst du zum Personen-Datensatz.</p><p>Über den Button <strong>Einen Lead manuell anlegen +</strong> kannst du sogar externe Leads zum System hinzufügen. Durch die Angabe der Herkunft (z.B. Walk-In/Call-In, Social-Media) gehen dir keine Interessenten mehr verloren. So kannst du mitfit beispielsweise auch optimal für Promotion-Stände nutzen, bei denen du Leads digital festhalten willst. Weitere Informationen zur Person wie E-Mailadresse oder Telefonnummer kannst du bei Bedarf jederzeit nachträglich ergänzen.</p>",
    },
    helpCampaigns: {
      title: "Kampagnen",
      content:
        "<p>Unter LEADS werden alle Kampagnen zur Leadgenerierung (auch HR-Recruitment Kampagnen) aufgelistet. Die Statusanzeige gibt dir dabei an, ob eine Kampagne abgeschlossen, in Planung oder gerade aktiv ist. Mit Klick auf das Auge (Anschauen-Button) auf der rechten Seite gelangst du zur Detail-Ansicht der jeweiligen Kampagne.</p>",
    },
    helpCampaignsDetail: {
      title: "Kampagnen",
      content:
        "<p>Zu jeder Kampagne steht dir ein übersichtliches Dashboard zur Verfügung, sodass du schnell einen Gesamtüberblick erhältst.</p><p>Unterhalb des Dashboards werden die Teilnehmer der Kampagne aufgelistet. Durch das farbliche Ampelsystem erkennst du auf einen Blick den aktuellen Bearbeitungsstatus. Mit Klick auf das Auge auf der rechten Seite gelangst du zum jeweiligen Personen-Datensatz zur individuellen Bearbeitung.</p><p>Neben den Stammdaten kannst du direkte Anmerkungen zur Person machen. Diese helfen dir und deinem Team zur besseren, persönlichen Beratung. Je mehr Angaben dir zu einer Person zur Verfügung stehen, desto zielführender ist die anschließende Beratungssituation mit dem Kunden. Top-Berater kennen Ihre Interessenten ganz genau!</p><p>Kampagnenbezogene Infos zur Person (z.B. Bringt den Freund zum Termin mit) kannst du unterhalb im Notizbereich der Lead Aktivitäten festhalten. In den Lead-Aktivitäten werden alle Informationen zu Kampagnen-Teilnahmen sowie dem Bearbeitungsstatus dargestellt.</p><p>Die Schieberegler dokumentieren den Kundenstatus direkt unterhalb in den Notizen ohne weiteren Aufwand. Der dokumentierte Bearbeitungszeitpunkt sowie die Angabe zum verantwortlichen Mitarbeiter sorgt ebenfalls für Klarheit bei größeren Teams zur optimalen Lead-Bearbeitung.</p>",
    },
    helpMitfitPay: {
      title: "mitfitPAY",
      content: `<h3>Verkäufe</h3>
      <p>Hier findest du die Übersicht der Verkäufe. Der Status verrät dir, ob ein Kauf abgeschlossen, oder abgebrochen wurde. Sobald ein Verkauf abgeschlossen wurde, kannst du dir die entsprechende Rechnung herunterladen, indem du auf den Download-Button klickst.</p> 
      <h3>Produkte</h3>
      <p>Hier kannst du die Produkte deines Shops erstellen und verwalten.</p>
      <p>Zudem kannst du für jedes Produkt individuell einstellen, ob das Produkt verfügbar ist oder nicht. Die Option 'Nur für Angebote' bedeutet, dass das entsprechende Produkt ausschließlich im Kontext eines Angebotes gekauft werden kann. So markierte Produkte werden nicht im Shop-Bereich gelistet.</p>
      <h3>PayPal</h3>
      <p>Hier siehst du die API-Zugangsdaten zu deinem Paypal+ Account. Updates der Daten können aktuell nur von den Administratoren der FACEFORCE vorgenommen werden.</p>
      <h3>Kampagnen-Angebote</h3>
      <p>Angebote sind Zusammenstellungen von Produkten. Diese Zusammenstellungen finden aktuell ausschließlich im Kontext einer FACEFORCE-Kampagne Verwendung. Du kannst einer Kampagne bereits erstellte Produkte zuweisen, sie inaktiv schalten und auch individuelle Texte zuweisen.</p>
      `,
    },
    helpCompany: {
      title: "Mein Unternehmen",
      content: `<p>Alle Angaben zu deinem Unternehmen kannst du hier einsehen und ändern.</p>
      <h3>Mitarbeiter</h3>
      <p>Unter Mitarbeiter kannst du neue Mitarbeiter hinzufügen und individuelle Berechtigungen festlegen. Beachte, dass Mitarbeiter-Rechte stets standortbezogen sind und fest einem Standort zugeordnet werden. Soll die Person mehrere Standorte verwalten, so kann hierfür ein Multi-Administrator angelegt werden.</p>
      <h3>Unternehmensdaten</h3>
      <p>Hier kannst du die Unternehmensadresse sowie rechtliche Angaben zum Unternehmen einsehen. Sollte etwas falsch sein, kontaktiere uns bitte.</p>
      `,
    },
    helpGdpr: {
      title: "DSGVO",
      content:
        "<p>Der Bereich DSGVO ist ähnlich aufgebaut wie der Lead-Bereich. Hier werden alle Datensätze aufgelistet, sodass du auf Wunsch von Interessenten jederzeit deiner gesetzlichen Auskunftspflicht als Unternehmen nachkommen kannst. Zusätzlich hast du hier die Möglichkeit, Personen-Datensätze DSGVO-konform zu löschen.</p>",
    },
    helpService: {
      title: "Service",
      content: `<p>Im Service-Bereich findest du wichtige Informationen, wie beispielsweise Ablaufbeschreibungen oder Telefonleitfäden.</p>
      <p>Darüber hinaus unterstützen wir im Rahmen des Jahresmarketing-Pakets dein Unternehmen kostenfrei mit unserer Social-Media Content Cloud. Diese wertvolle Sammlung erleichtert die Planung und Erstellung guter Social-Media-Beiträge auf Facebook, Instagram & Co., immer passend zu den jeweiligen Kernbereichen.</p>`,
    },
    helpSettings: {
      title: "Einstellungen",
      content: `<p>Hier kannst du dein Profilbild ändern und ein neues Kennwort für deinen persönlichen mitfit-Zugang vergeben.</p>`,
    },
  },
};
