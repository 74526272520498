<template>
  <v-card :loading="loading" class="mx-auto">
    <template slot="progress">
      <v-progress-linear
        color="primary"
        height="5"
        indeterminate
      ></v-progress-linear>
    </template>

    <v-card-title
      class="mitfitDark white--text caption font-weight-bold text-uppercase"
      >{{ $t("last5campaigns") }}</v-card-title
    >

    <v-card-text class="mt-4" v-if="hasPermission">
      <table class="default-table" v-if="data.length > 0" style="width:100%">
        <thead>
          <tr>
            <th>Status</th>
            <th>Kampagne</th>
            <th>Teilnehmer</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, i) in data.slice(0, 5)" :key="i">
            <td>
              <v-icon small>{{
                getState(item.page_startdate, item.page_enddate)
              }}</v-icon>
            </td>
            <td><strong v-html="item.page_title"></strong></td>
            <td>{{ item.memberCount }}</td>

            <td>
              <v-btn
                fab
                dark
                x-small
                color="indigo"
                depressed
                @click="statsItem(item.id, item.version, item.hasMitfitPay)"
                class="mx-1"
              >
                <v-icon dark>mdi-chart-pie</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
        <tfoot></tfoot>
      </table>

      <p v-else>{{ $t("noData") }}</p>
    </v-card-text>

    <v-card-text class="mt-4" v-else>
      {{ $t("noPermissions") }}
    </v-card-text>
  </v-card>
</template>

<script>
import manageApi from "@/mixins/manageApi";
import { mapGetters } from "vuex";

export default {
  name: "Last5Campaigns",

  data() {
    return {
      hasPermission: false,
      drawer: null,
      activeItem: null,
      router: this.$router.options.routes,
      currentCompanyId: this.$store.getters["auth/current_sid"],
      data: [],
      loading: false,
      currentTimestamp: Math.floor(Date.now() / 1000),
    };
  },

  props: [],

  created: function() {
    this.$store.watch(
      () => this.$store.getters["auth/current_sid"],
      (value) => {
        this.currentCompanyId = value;
        this.getData();
      }
    );

    this.getData();
  },

  methods: {
    statsItem(id, version, hasMitfitPay) {
      if (version === 1) {
        this.$router.push({ name: "ocampaigns:id", params: { id: id } });
      } else if (version === 2) {
        if (hasMitfitPay === "1") {
          // pay campaign
          this.$router.push({ name: "pay-campaigns:id", params: { id: id } });
        } else {
          // regular campaign
          this.$router.push({ name: "campaigns:id", params: { id: id } });
        }
      }
    },

    async getData() {
      this.loading = true;
      let response = await this.getRequest(
        "companies/" + this.currentCompanyId + "/campaigns"
      );
      this.loading = false;

      if (response.status === 200) {
        this.hasPermission = true;
        this.data = response.data.data.campaign;
      } else {
        this.hasPermission = false;
        this.data = [];
      }
    },

    getState(startdate, enddate) {
      let status = "";
      if (this.currentTimestamp < startdate) {
        status = "mdi-clock-time-eight-outline";
      } else if (enddate < this.currentTimestamp) {
        status = "mdi-check-bold";
      } else {
        status = "mdi-lightning-bolt";
      }
      return status;
    },
  },

  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user",
    }),
  },
  mixins: [manageApi],
};
</script>
