<template>
  <span></span>
  <!-- 
    Vorrübergehend entfernt, da Logo noch vom Legacy-System geholt wurde
    <figure id="logo" class="white px-4 py-4 d-flex justify-center align-center">
    <img :src="logoUrl" alt="logo" />
  </figure> -->
</template>

<script>
import store from "@/store"; // needed for the token
export default {
  name: "CompanyHeadLogo",

  data() {
    return {
      currentCompanyId: this.$store.getters["auth/current_sid"],
      loading: false,
      logo: store.getters["company/logo"],
    };
  },

  created: function () {
    /* WATCH VUEX FOR CHANGES */
    this.$store.watch(
      () => this.$store.getters["company/logo"],
      (value) => {
        this.logo = value;
      }
    );
  },
};
</script>

<style scoped>
#logo {
  position: absolute;
  top: 100%;
  left: calc(100% - 170px);
  width: 150px;
  margin-top: -1px;
  border-radius: 0 0 1rem 1rem;
  border: 1px solid rgba(0, 0, 0, 0.12) !important;
  border-top: 1px solid white !important;
}

#logo img {
  max-height: 30px;
  max-width: 130px;
}
</style>
