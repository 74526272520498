<template>
  <span>
    <v-btn
      v-if="!isDisabledForNoneFaceforceStaff()"
      @click="askForDeleteLead"
      :disabled="id ? false : true"
      small
      depressed
      color="blue"
      class="white--text"
    >
      <v-icon left> mdi-skull-outline </v-icon>

      Lead löschen
    </v-btn>
  </span>
</template>

<script>
import store from "@/store";
import helpers from "@/mixins/helpers";
import manageApi from "@/mixins/manageApi"; // import mixin (for api requests)
export default {
  name: "DeleteLead",
  props: ["id"],
  data() {
    return {
      fetchinData: false,
      companyID: store.getters["auth/current_sid"],
      adminID: store.getters["auth/adminid"],
    };
  },

  methods: {
    async deleteLead() {
      this.fetchinData = true;
      let response = await this.deleteRequest("leads/" + this.id);
      this.fetchinData = false;

      if (response.status === 200) {
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText = "Lead wurde gelöscht";
        this.$root.snackbarGlobal.snackbarColor = "primary";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;

        location.reload();
      }
    },

    async askForDeleteLead2() {
      const userChoice = await this.$dialog.confirm({
        text: `Alle Daten des Leads werden unwiderruflich gelöscht! Nutze diese Funktion nur, wenn Du Dir absolut sicher bist.`,
        title: "Letzte Warnung!",
        waitForResult: true,
      });

      if (userChoice) {
        this.deleteLead();
      }
    },

    async askForDeleteLead() {
      const userChoice = await this.$dialog.confirm({
        text: `Soll der Lead tatsächlich gelöscht werden?`,
        title: "Achtung!",
        waitForResult: true,
      });

      if (userChoice) {
        this.askForDeleteLead2();
      }
    },
  },

  mixins: [manageApi, helpers],
};
</script>
