<template>
  <div class="mt-4">
    <h1 v-if="pageTitle" class="mb-6 mt-4">
      {{ pageTitle }}
    </h1>

    <v-img
      v-if="mediaUrl"
      :src="mediaUrl"
      aspect-ratio="1.7"
      class="mb-4"
    ></v-img>

    <vue-markdown v-if="pageContent" :source="pageContent"></vue-markdown>
  </div>
</template>

<script>
import store from "@/store";
import VueMarkdown from "vue-markdown/src/VueMarkdown"; // neuer Weg (s. oben)
export default {
  name: "NewsDetail",

  props: ["id", "data"],

  components: {
    VueMarkdown,
  },

  data() {
    return {
      pageTitle: this.data.title,
      pageContent: this.data.content,
      mediaUrl: this.data.mediaUrl,
      companyID: store.getters["auth/current_sid"],
    };
  },
};
</script>
