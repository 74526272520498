/* 
POWERED BY:
https://www.smashingmagazine.com/2020/10/authentication-in-vue-js/
https://www.youtube.com/watch?v=Vd1YwbHrY1Q&list=PLfdtiltiRHWF1jqLcNO_2jWJXj9RuSDvY&t=0s
*/

const state = {
  id: null,
  name: null,
  jmp: null,
  fitness_jmp: null,
  golf_jmp: null,
  physio_jmp: null,
  logo: null,
  shop: null,
  ems_jmp: null,
  video_library: null
};

const getters = {

  /* Getters are functionalities to get the state. It can be used in multiple components to get the current state. 
  The isAuthenticatated function checks if the state.user is defined or null and returns true or false respectively. 
  StatePosts and StateUser return state.posts and state.user respectively value. */

  id(state) {
    return state.id
  },

  name(state) {
    return state.name
  },

  jmp(state) {
    return state.jmp
  },

  fitness_jmp(state) {
    return state.fitness_jmp
  },

  golf_jmp(state) {
    return state.golf_jmp
  },

  physio_jmp(state) {
    return state.physio_jmp
  },

  logo(state) {
    return state.logo
  },

  shop(state) {
    return state.shop
  },

  ems_jmp(state) {
    return state.ems_jmp
  },

  video_library(state) {
    return state.video_library
  },

  current_company(state) {

    let company = { "id": state.id, "name": state.name, "blocked": 0 }
    return company
  },

};

const actions = {

  /* Actions are functions that are used to commit a mutation to change the state or can be used to dispatch i.e calls another action. 
  It can be called in different components or views and then commits mutations of our state; */

  async CompanySetup() {
    console.log("CompanySetup");
  },

};

const mutations = {

  SET_COMPANY(state, data) {
    state.id = data.id,
      state.name = data.name,
      state.jmp = data.jmp,
      state.fitness_jmp = data.fitness_jmp,
      state.golf_jmp = data.golf_jmp,
      state.physio_jmp = data.physio_jmp,
      state.logo = data.logo,
      state.shop = data.shop,
      state.ems_jmp = data.ems_jmp,
      state.video_library = data.video_library
  }

};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};