<template>
  <header id="page-header">
    <CompanyInfoChip v-if="managedCompanies > 1"></CompanyInfoChip>
    <h1>{{ pageTitle }}</h1>
    <span v-if="pageDescription && pageDescription !== ''" id="page-intro">{{
      pageDescription
    }}</span>
  </header>
</template>

<script>
import store from "@/store";
import CompanyInfoChip from "@/components/ui/CompanyInfoChip";
export default {
  name: "PageHeader",
  components: {
    CompanyInfoChip,
  },
  props: ["pageTitle", "pageDescription"],
  data() {
    return {
      managedCompanies: store.getters["auth/clients"].length,
    };
  },
};
</script>
