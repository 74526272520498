<template>
  <v-card v-if="!fetchinData">
    <v-toolbar dense dark color="dark">
      <v-toolbar-title>Feedback</v-toolbar-title>
    </v-toolbar>

    <v-card-text>
      <div class="caption">
        <v-icon small>mdi-information-outline</v-icon>
        <span> Schulnoten-System: 1 - 6 (1 = sehr gut, 6 = ungenügend) </span>
      </div>

      <template v-if="data.length > 0">
        <v-card v-for="set in data" :key="set.id" elevation="0">
          <v-card-text>
            <span class="subtitle-1 font-weight-bold">{{
              set.set.headline
            }}</span>

            <v-simple-table>
              <thead>
                <tr>
                  <th scope="col">Frage</th>
                  <th scope="col">Antwort</th>
                  <th scope="col">Note</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="answer in set.results.details" :key="answer.id">
                  <td>{{ answer.feedbackQuestions_text }}</td>
                  <td>{{ answer.feedbackAnswers_text }}</td>
                  <td>
                    <div
                      :class="`note-bubble ${whatColor(
                        answer.feedbackEvaluation
                      )}`"
                    >
                      {{ answer.feedbackEvaluation }}
                    </div>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>

            <template v-if="set.results.message">
              <span class="font-weight-bold">Nachricht:</span>
              {{ set.results.message }}
            </template>
            <br /><span class="font-weight-bold">Durchschnitt:</span>
            {{ set.results.average }} <br /><span class="font-weight-bold"
              >Datum:</span
            >
            {{ formatDate(set.results.created) }}
          </v-card-text>
        </v-card>
      </template>
      <template v-else>
        <span class="caption">Noch kein Feedback vorhanden</span>
      </template>
    </v-card-text>
  </v-card>
</template>

<script>
import helpers from "@/mixins/helpers"; // import mixin (for api requests)
import manageApi from "@/mixins/manageApi"; // import mixin (for api requests)

export default {
  name: "LeadFeedbacks",

  props: ["id"],

  components: {},

  data() {
    return {
      data: [],
      fetchinData: false, // should be true when fetching data
    };
  },

  mounted() {
    this.getFeedbacks();
  },

  methods: {
    whatColor(note) {
      if (note === 1) {
        return "green accent-4";
      } else if (note === 2) {
        return "light-green";
      } else if (note === 3) {
        return "cyan";
      } else if (note === 4) {
        return "amber";
      } else if (note === 5) {
        return "orange";
      } else if (note === 6) {
        return "red";
      } else {
        return "gray";
      }
    },

    async getFeedbacks() {
      this.fetchinData = true;
      const response = await this.getRequest(
        `feedbackResults/leads/${this.id}`,
        true
      );
      this.data = response.data.data;
      this.fetchinData = false;
      return response;
    },
  },

  mixins: [helpers, manageApi],
};
</script>

<style>
.note-bubble {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  font-weight: bold;
  color: white;
  margin: 0 auto;
}
</style>
