<template>
  <div v-if="leadData.obaUuid">
    <ObaResults :leadId="leadData.id" />
  </div>
</template>

<script>
import store from "@/store";
import apiRequest from "@/mixins/apiRequest"; // import mixin (for api requests)
import helpers from "@/mixins/helpers"; // import mixin (for api requests)
import ObaResults from "@/components/lead/manage/ObaResults";
export default {
  name: "LeadAdditionalData",
  components: { ObaResults },
  props: ["leadData", "campaign"],
  data() {
    return {
      fetchinData: false, // should be true when fetching data
      companyID: store.getters["auth/current_sid"],
    };
  },
  mixins: [apiRequest, helpers],
};
</script>
