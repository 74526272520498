<template>
  <v-chip class="mb-2" label small>
    <v-icon left>mdi-label</v-icon>
    {{ name }}
  </v-chip>
</template>

<script>
import store from "@/store";

export default {
  name: "CompanyInfoFooter",

  components: {},

  data() {
    return {
      id: store.getters["company/id"],
      name: store.getters["company/name"],
    };
  },

  created: function () {
    /* starts immediatly when component was created */

    /* VUEX store watcher. It fires when current_sid (current studioID) changes */
    this.$store.watch(
      () => store.getters["auth/current_sid"],
      (value) => {
        this.id = value;
      }
    );

    this.$store.watch(
      () => store.getters["company/name"],
      (value) => {
        this.name = value;
      }
    );
  },

  methods: {},
};
</script>
