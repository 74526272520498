<template>
  <v-card v-if="!fetchinData" class="align-self-stretch" height="100%">
    <v-toolbar dark color="dark">
      <v-toolbar-title>{{ $t("baseData") }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <EditBasedata
        class="align-content-end"
        :data="data"
        @updatedBaseData="$emit('updatedBaseData', true)"
      />
    </v-toolbar>

    <v-card-text>
      <table class="default-table" style="width:100%">
        <tbody>
          <tr>
            <td class="font-weight-bold" style="width:100px">ID</td>
            <td>{{ data.id }}</td>
          </tr>

          <tr>
            <td class="font-weight-bold">
              {{ $t("status") }}
            </td>

            <td
              v-if="
                data.person_client === 'Ja' ||
                  data.person_client === 'ja' ||
                  data.person_client === '1'
              "
            >
              <v-chip color="green" text-color="white">
                <v-icon small left>mdi-trophy-variant</v-icon>
                {{ $t("client") }} </v-chip
              ><br />
              <!-- <span class="caption" v-if="data.person_client_since"
                >{{ $t("since") }} {{ data.person_client_since }}
                {{ $t("oClock") }}</span
              > -->
            </td>
            <td v-else>
              <v-chip color="gray" text-color="black">
                <v-icon small left>mdi-trophy-variant-outline</v-icon>
                {{ $t("participant") }} </v-chip
              ><br />
            </td>
          </tr>

          <tr>
            <td class="font-weight-bold">{{ $t("gender") }}</td>
            <td>{{ $t(data.person_sex) }}</td>
          </tr>

          <tr>
            <td class="font-weight-bold">{{ $t("fullname") }}</td>
            <td>{{ data.person_firstname }} {{ data.person_lastname }}</td>
          </tr>

          <tr>
            <td class="font-weight-bold">{{ $t("email") }}</td>
            <td v-if="data.person_email">
              <a :href="'mailto:' + data.person_email">{{
                data.person_email
              }}</a>
            </td>
            <td v-else>-</td>
          </tr>

          <tr>
            <td class="font-weight-bold">{{ $t("phone") }}</td>
            <td>{{ data.person_telefon }}</td>
          </tr>

          <tr>
            <td class="font-weight-bold">{{ $t("source") }}</td>
            <td v-if="data.campaignsManually">
              <strong>{{ data.campaignsManually[0].manual_contacttype }}</strong
              ><br />
              <span class="caption"
                >{{ $t("manuallyCreatedBy") }}
                {{ data.campaignsManually[0].manual_data_admin_name }}</span
              >
            </td>
            <td v-else>{{ $t("participation") }}</td>
          </tr>

          <tr>
            <td class="font-weight-bold">{{ $t("registrationDate") }}</td>
            <td v-if="data.person_registerdate">
              {{ this.formatDate(data.person_registerdate) }}
            </td>
          </tr>
        </tbody>
      </table>
    </v-card-text>
  </v-card>
</template>

<script>
import store from "@/store";
import helpers from "@/mixins/helpers"; // import mixin (for api requests)
import EditBasedata from "@/components/lead/old/EditBasedata";
export default {
  name: "LeadBaseDataCard",

  components: {
    EditBasedata,
  },

  props: ["id", "data"],

  data() {
    return {
      pageTitle: "LeadBaseData",
      pageDescription:
        "Pellentesque in ipsum id orci porta dapibus. Cras ultricies ligula sed magna dictum porta.",
      fetchinData: false, // should be true when fetching data
      companyID: store.getters["auth/current_sid"],
    };
  },

  mixins: [helpers],
};
</script>
