<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="white" light v-bind="attrs" v-on="on" elevation="0">
        <v-avatar color="primary" size="36" class="mr-2">
          <span class="white--text text-h6">{{ firstLetter() }}</span>
        </v-avatar>

        <!-- <v-icon v-else left>mdi-account</v-icon> -->

        {{ firstname }}

        <v-icon
          v-if="adminType === 'faceforce'"
          color="primary"
          title="FACEFORCE-Admin"
        >
          mdi-shield-key
        </v-icon>
      </v-btn>
    </template>

    <v-list>
      <v-list-item
        v-for="(item, index) in items"
        :key="index"
        @click="whatsToDo(item.click)"
      >
        <v-list-item-title
          ><v-icon left>{{ item.icon }}</v-icon>
          {{ item.title }}</v-list-item-title
        >
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import store from "@/store";
import { mapActions } from "vuex";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      adminType: store.getters["auth/adminType"],
      items: [
        { title: "Settings", click: "settings", icon: "mdi-account-cog" },
        { title: "Logout", click: "logout", icon: "mdi-logout-variant" },
      ],
    };
  },

  methods: {
    ...mapActions({
      // spread actions in to component methods (LogIn is a method in auth.js actions)
      LogOut: "auth/LogOut",
    }),

    firstLetter() {
      return this.firstname.charAt(0);
    },

    whatsToDo(what) {
      if (what === "logout") {
        this.LogOut();

        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText =
          "Du hast Dich erfolgreich ausgeloggt!";
        this.$root.snackbarGlobal.errorMessage =
          "<br/>melde Dich erneut an, wenn Du fortfahren möchtest.";
        this.$root.snackbarGlobal.snackbarColor = "primary";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      } else if (what === "settings") {
        this.$router.push("/settings").catch(() => {});
      }
    },
  },

  computed: {
    ...mapGetters({
      firstname: "auth/user",
      avatar: "auth/avatar",
    }),
  },

  props: ["username"],
};
</script>
