<template>
  <v-app style="background-color: #f5f5f5">
    <div v-if="authenticated">
      <NavigationBar
        :currentCompanyId="current_sid"
        :maintenanceMode="maintenanceMode"
      ></NavigationBar>

      <v-main>
        <v-container class="px-10 py-15 mx-0" style="max-width: 1500px" fluid>
          <transition name="fade" mode="out-in">
            <router-view></router-view>
          </transition>
        </v-container>
        <Help />
      </v-main>
    </div>

    <div v-else>
      <AuthForm></AuthForm>
    </div>

    <v-snackbar
      v-model="$root.snackbarGlobal.snackbar"
      :timeout="$root.snackbarGlobal.snackbarTimeout"
      :multi-line="true"
      :color="$root.snackbarGlobal.snackbarColor"
      @input="snackbarEvent"
      elevation="24"
    >
      {{ $root.snackbarGlobal.snackbarText }}
      <span
        v-if="$root.snackbarGlobal.errorMessage !== ''"
        style="font-size: 0.7rem"
        v-html="$root.snackbarGlobal.errorMessage"
      ></span>

      <template v-slot:action="{ attrs }">
        <v-btn
          class="mx-2"
          fab
          x-small
          color="white"
          v-bind="attrs"
          @click="$root.snackbarGlobal.snackbar = false"
        >
          <v-icon small dark :color="$root.snackbarGlobal.snackbarColor">
            mdi-close
          </v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import NavigationBar from "@/components/ui/NavigationBar";
import AuthForm from "@/components/user/AuthForm";
import Help from "@/components/ui/Help";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    NavigationBar,
    AuthForm,
    Help,
  },

  data() {
    return {
      systemRunning: false,
      checkingStatus: false,
      componentKey: 0,
      maintenanceMode: false,
      interval: null,
    };
  },

  mounted() {
    // wait 1s and check if the system is running
    setTimeout(() => {
      this.checkInterval(); // start an interval and check every 60 seconds if the system is running
      this.checkStatus(); // check if the system is running
    }, 2000);
  },

  beforeDestroy() {
    clearInterval(this.interval);
  },

  // watch if route changes
  watch: {
    $route() {
      this.checkStatus();
    },
  },

  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user",
      adminid: "auth/adminid",
      current_sid: "auth/current_sid",
    }),
  },
  methods: {
    ...mapActions({
      // spread actions in to component methods (LogIn is a method in auth.js actions)
      LogOut: "auth/LogOut",
    }),

    checkInterval() {
      this.interval = setInterval(async () => {
        this.checkStatus();
      }, 600000);
    },

    async checkStatus() {
      if (this.authenticated !== null) {
        this.checkingStatus = true;
        let response = await this.getRequest("public/status"); // await data from mixin
        if (response.status === 200) {
          const result = response.data.data;
          const mode = result.mode;
          const status = result.status;
          if (
            mode.admin === "regular" &&
            mode.api === "regular" &&
            status === 200
          ) {
            this.systemRunning = true;
            this.maintenanceMode = false;
          } else {
            if (
              this.adminid === "1" ||
              this.adminid === "106" ||
              this.adminid === "1267"
            ) {
              // check users usergroup in store getters (auth.js) users.usergroup by using mapGetters
              this.systemRunning = true;
              this.maintenanceMode = true;
              console.log(
                "+++ APP IS IN MAINTENANCEMODE BUT USER IS DEVELOPER +++"
              );
            } else {
              // logout
              console.log("LOGOUT");
              this.LogOut();
              this.componentKey += 1;
            }
          }
          this.checkingStatus = false;
        } else {
          this.systemRunning = false;
          this.fetchingData = false;
        }
      }
    },

    snackbarEvent(value) {
      if (!value) {
        // reset snackbar text when closing
        this.$root.snackbarGlobal.snackbarText = "";
        this.$root.snackbarGlobal.errorMessage = "";
      }
    },
  },
  mixins: [apiRequest],
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

header#page-header {
  display: block;
  width: 100%;
  height: auto;
  margin-bottom: 20px;
}

#page-intro {
  display: inline-block;
  width: 100%;
  margin: 10px 0;
  font-size: 1em;
  color: gray;
  font-style: italic;
}
</style>
