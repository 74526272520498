<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <h2>TAG manuell hinzufügen (TESTS)</h2>
      </v-col>
      <v-col cols="auto">
        <v-select
          v-if="!fetchingData"
          v-model="tag_id"
          :items="tags"
          label="Tag"
          item-text="name"
          item-value="id"
          required
          hide-details
          chips
          dense
        ></v-select>
      </v-col>
      <v-col cols="auto">
        <v-checkbox v-model="toIdentity" label="Identity-Tag"></v-checkbox>
      </v-col>
      <v-col cols="12">
        <v-btn
          @click="addTag"
          :disabled="tag_id ? false : true"
          small
          color="success"
          >Add Tag</v-btn
        >
      </v-col>

      <v-col v-if="!fetchingData" cols="12">
        <h3 class="mb-4">Tags des Leads</h3>
        <v-chip v-for="tag in leadTags" :key="tag.id" class="ma-1">{{
          tag.name
        }}</v-chip>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import store from "@/store";
import helpers from "@/mixins/helpers"; // import mixin (for api requests)
import manageApi from "@/mixins/manageApi"; // import mixin (for api requests)

export default {
  name: "LeadAddTag",

  props: ["id"],

  components: {},

  data() {
    return {
      tag_id: "",
      toIdentity: false,
      fetchingData: false, // should be true when fetching data
      adminType: store.getters["auth/adminType"],
      leadTags: [],
      tags: [],
      companyID: store.getters["auth/current_sid"],
    };
  },

  async mounted() {
    this.getLeadTags();
    this.tags = await this.getTags();
  },

  methods: {
    async getLeadTags() {
      this.fetchingData = true;
      const response = await this.getRequest(`leads/${this.id}/tags`, true);
      this.leadTags = response.data.data;
      this.fetchingData = false;
    },

    async getTags() {
      // GET leadTags?filter[companies_id]=1
      const response = await this.getRequest(`leadTags?filter[type]=status`);
      const response2 = await this.getRequest(
        `leadTags?filter[companies_id]=${this.companyID}`
      );

      const tagsStatus = response.data.data;
      const tagsCompany = response2.data.data;
      const tags = tagsStatus.concat(tagsCompany);

      return tags;
    },

    async addTag() {
      console.log("Add TAG:", this.tag_id, "toIdentity:", this.toIdentity);

      /* 
      POST leads/{leadId}/tagReference
      mit Parameter als "form-data" tag_id={leadTags_id}
      Wenn "useLeadIdentity" mitgegeben wird, wird der Tag zu der Identity (per leads.email ermittelt) referenziert.
      */

      // form data
      const formData = new FormData();
      formData.append("tag_id", this.tag_id);
      if (this.toIdentity) {
        formData.append("useLeadIdentity", true);
      }

      const response = await this.postRequest(
        `leads/${this.id}/tagReference`,
        null,
        formData
      );

      this.tag_id = "";
      this.toIdentity = false;

      this.getTags();
      this.getLeadTags();
      return response;
    },
  },

  mixins: [helpers, manageApi],
};
</script>
