<template>
  <v-card height="100%">
    <v-toolbar dark color="dark">
      <v-toolbar-title>{{ $t("notes") }}</v-toolbar-title>
    </v-toolbar>

    <v-card-text>
      <v-row v-if="newAdmin !== '1'" no-gutters class="mt-4">
        <v-col cols="11">
          <v-form
            ref="form"
            v-model="isFormValid"
            v-on:submit.prevent="safeNote()"
          >
            <v-text-field
              v-model="note"
              :label="$t('addNote')"
              :rules="[rules.required, rules.min]"
              outlined
              clearable
            ></v-text-field>
          </v-form>
        </v-col>
        <v-col cols="1">
          <v-btn
            v-if="isFormValid"
            class="mx-2"
            fab
            dark
            small
            color="green"
            @click="safeNote"
          >
            <v-icon dark>mdi-floppy</v-icon>
          </v-btn>

          <v-btn v-else class="mx-2" fab small color="green" disabled>
            <v-icon dark>mdi-floppy</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-row v-else no-gutters class="mt-4">
        {{ $t("legacyBlockNote") }}
      </v-row>

      <v-list max-height="300px" min-height="300px" style="overflow: auto">
        <template v-for="(note, index) in data">
          <v-list-item :key="note.id">
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold">
                {{ note.admin_firstname }} {{ note.admin_lastname }},
                {{ note.datum }} {{ $t("oClock") }}
              </v-list-item-title>
              <span v-html="note.note" class="mt-1"></span>
            </v-list-item-content>
          </v-list-item>
          <v-divider :key="index"></v-divider>
        </template>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import store from "@/store";
import apiRequest from "@/mixins/apiRequest"; // import mixin (for api requests)

export default {
  name: "LeadNotesCard",

  components: {
    //
  },

  data() {
    return {
      fetchinData: false, // should be true when fetching data
      companyID: store.getters["auth/current_sid"],
      newAdmin: store.getters["auth/newAdmin"],
      data: [], // wrapper for fetched data
      addNote: this.$t("addNote"),
      note: "",
      isFormValid: false,
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 3 || "Min 3 characters",
      },
    };
  },

  props: ["id"],

  created: function () {
    /* starts immediatly when component was created */

    /* VUEX store watcher. It fires when current_sid (current studioID) changes */
    this.$store.watch(
      () => store.getters["auth/current_sid"],
      (value) => {
        this.companyID = value;
        this.getData();
      }
    );

    this.getData();
  },

  mounted: function () {
    console.log("RENDER");
  },

  methods: {
    async safeNote() {
      if (this.isFormValid) {
        const formData = new FormData();
        formData.append("note", this.note);

        this.fetchinData = true;
        let response = await this.postRequest(
          "person_activity/" + this.companyID + "/" + this.id + "/0",
          "",
          formData
        ); // await data from mixin
        this.fetchinData = false;

        if (response.data.status === "ok") {
          this.$root.snackbarGlobal.snackbar = true;
          this.$root.snackbarGlobal.snackbarText = this.$t("messageUpdated");
          this.$root.snackbarGlobal.errorMessage = "";
          this.$root.snackbarGlobal.snackbarColor = "primary";
          this.$root.snackbarGlobal.snackbarTimeout = 5000;

          this.note = "";
          this.$refs.form.resetValidation();

          this.getData();
        }
      }
    },

    async getData() {
      /* fetch data from api */
      this.fetchinData = true;
      let response = await this.getRequest(
        "companies/" +
          this.companyID +
          "/legacy-personActivity/" +
          this.id +
          "/0"
      ); // await data from mixin
      this.fetchinData = false;
      this.data = response.data.result.person_game_data.personNotes;
    },
  },

  /* all requests by mixin */
  mixins: [apiRequest],
};
</script>
