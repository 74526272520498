import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import VueI18n from "vue-i18n";
import vuetify from "./plugins/vuetify";
import VuetifyDialog from "vuetify-dialog";
import "vuetify-dialog/dist/vuetify-dialog.css";
import "vuejs-dialog/dist/vuejs-dialog.min.css";
import translations from "./assets/translations.js"; // import the translation obj
import DatetimePicker from "vuetify-datetime-picker";
import VueConfetti from "vue-confetti";
import { TiptapVuetifyPlugin } from "tiptap-vuetify"; /* https://github.com/iliyaZelenko/tiptap-vuetify#installation */
import AvatarCropper from "vue-avatar-cropper"; /* https://github.com/overtrue/vue-avatar-cropper */
import "tiptap-vuetify/dist/main.css";
import "./assets/scss/custom.scss"; /* import custom scss */
import VueMarkdownEditor from "@kangc/v-md-editor";
import "@kangc/v-md-editor/lib/style/base-editor.css";
import vuepressTheme from "@kangc/v-md-editor/lib/theme/vuepress.js";
import "@kangc/v-md-editor/lib/theme/style/vuepress.css";
// Prism
import Prism from "prismjs";
import appData from "../package.json";

axios.defaults.withCredentials = false; // this is needed because by default cookies are not passed by Axios.
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_V2;

/* define this variable in the prototype. 
Get it via: this.$manageApiUrl */
Vue.prototype.$appVersion = appData.version;
Vue.prototype.$manageApiUrl = process.env.VUE_APP_API_BASE_V2;
Vue.prototype.$apiBaseURL = process.env.VUE_APP_API_BASE;
Vue.prototype.$landingpageUrl = process.env.VUE_APP_FRONTEND_URL;
Vue.prototype.$enviroment = process.env.VUE_APP_ENVIROMENT;

Vue.config.productionTip = false;

Vue.use(VueI18n);

Vue.use(DatetimePicker);

Vue.use(VueConfetti);

Vue.use(VuetifyDialog, {
  context: {
    vuetify,
  },
  confirm: {
    actions: {
      false: "NEIN",
      true: {
        text: "JA",
        color: "primary",
      },
    },
    icon: false, // to disable icon just put false
    width: 500,
  },
  warning: {},
  error: {},
  prompt: {},
});

Vue.use(TiptapVuetifyPlugin, {
  // the next line is important! You need to provide the Vuetify Object to this place.
  vuetify, // same as "vuetify: vuetify"
  // optional, default to 'md' (default vuetify icons before v2.0.0)
  iconsGroup: "mdi",
});

Vue.use(AvatarCropper);

const messages = translations; // should have been messages

/* Create VueI18n instance with options
{{ $t('hello') }} */

const i18n = new VueI18n({
  locale: store.getters["auth/lang"], // get locale from store
  fallbackLocale: "de",
  messages, // set locale messages
});

import enUS from "@kangc/v-md-editor/lib/lang/de-DE";
VueMarkdownEditor.lang.use("de-DE", enUS);

/* import createEmojiPlugin from '@kangc/v-md-editor/lib/plugins/emoji/index';
import '@kangc/v-md-editor/lib/plugins/emoji/emoji.css';
VueMarkdownEditor.use(createEmojiPlugin()); */
VueMarkdownEditor.use(vuepressTheme, {
  Prism,
});

Vue.use(VueMarkdownEditor);

new Vue({
  store,
  router,
  vuetify,
  i18n,
  data: {
    snackbarGlobal: {
      snackbar: false,
      snackbarText: "",
      snackbarColor: "primary",
      snackbarTimeout: 2000,
      errorMessage: "",
    },
  },
  render: (h) => h(App),
}).$mount("#app");

/* The data object in new Vue:
Hier definiere ich globale Variablen, die ich von überall aus mutieren kann.
Das geht dann so: 
$root.snackbarGlobal.snackbar = true;
$root.snackbarGlobal.snackbarText = 'Eine Fehler ist aufgetreten';
*/
