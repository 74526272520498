import Vuex from 'vuex';
import Vue from 'vue';
import createPersistedState from "vuex-persistedstate";
import auth from './modules/auth';
import nav from './modules/nav';
import company from './modules/company';
import news from './modules/news';
import companyChangeOption from './modules/companyChangeOption';

// Load Vuex
Vue.use(Vuex);
// Create store
export default new Vuex.Store({
  modules: {
    auth,
    nav,
    company,
    news,
    companyChangeOption
  },
  plugins: [createPersistedState()]
});