import store from "@/store";

export default {
  methods: {
    isFaceForce() {
      // Ermitteln, ob der Admin ein FACEFORCE-Mitarbeiter ist
      const adminId = store.getters["auth/adminid"];
      if (
        adminId === "1" ||
        adminId === "106" ||
        adminId === "836" ||
        adminId === "869" ||
        adminId === "868" ||
        adminId === "2169" ||
        adminId === "7" ||
        adminId === "2379" ||
        adminId === "2499" ||
        adminId === "2694" ||
        adminId === "2355" ||
        adminId === "2107" ||
        adminId === "2792" ||
        adminId === "2419" ||
        adminId === "847" ||
        adminId === "861" ||
        adminId === "845"
      ) {
        return true;
      }
      return false;
    },

    isDisabledForNoneFaceforceStaff() {
      // get adminType from store
      const adminType = store.getters["auth/adminType"];
      // Ermitteln, ob der Admin ein FACEFORCE-Mitarbeiter ist
      if (adminType === "faceforce") {
        return false;
      }
      return true;
    },

    zeroPad(num, places) {
      var zero = places - num.toString().length + 1;
      return Array(+(zero > 0 && zero)).join("0") + num;
    },

    addZero(i) {
      if (i < 10) {
        i = "0" + i;
      }
      return i;
    },

    timestampToDate(ts) {
      // Converts a timestamp to Date
      ts = parseInt(ts * 1000);
      var currentDate = new Date(ts);
      var date = this.zeroPad(currentDate.getDate(), 2);
      var month = this.zeroPad(currentDate.getMonth() + 1, 2);
      var year = currentDate.getFullYear();
      var formattedTime = date + "." + month + "." + year;
      return formattedTime;
    },

    formatDate(date) {
      date = date.replace(/-/g, "/"); // Safari Issue. https://www.evernote.com/shard/s74/nl/14459636/a6d4cd8e-312e-452b-9393-0a7057285e6b?title=Safari%20Date%20Issue
      const dt = new Date(date);
      const d = this.addZero(dt.getDate()); //Month from 0 to 11
      const m = this.addZero(dt.getMonth() + 1); //Month from 0 to 11
      const y = dt.getFullYear();
      const h = this.addZero(dt.getHours());
      const i = this.addZero(dt.getMinutes());
      //const s = this.addZero(dt.getSeconds());
      return d + "." + m + "." + y + " " + h + ":" + i + " Uhr";
    },

    formatDateNoTime(date) {
      date = date.replace(/-/g, "/"); // Safari Issue. https://www.evernote.com/shard/s74/nl/14459636/a6d4cd8e-312e-452b-9393-0a7057285e6b?title=Safari%20Date%20Issue
      const dt = new Date(date);
      const d = this.addZero(dt.getDate()); //Month from 0 to 11
      const m = this.addZero(dt.getMonth() + 1); //Month from 0 to 11
      const y = dt.getFullYear();
      //const s = this.addZero(dt.getSeconds());
      return d + "." + m + "." + y;
    },

    downloadDocument(getRoute) {
      // use the getRoute to download the document via fetch-API GET and window.open()
      this.generatingDocument = true;
      fetch(this.$manageApiUrl + getRoute, {
        method: "GET",
        headers: {
          Token: store.getters["auth/manageToken"],
        },
      })
        .then((response) => {
          // response is a file (pdf)
          return response.blob();
        })
        .then((data) => {
          this.generatingDocument = false;
          const file = new Blob([data], { type: "application/pdf" });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        });
    },
  },
};
