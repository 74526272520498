var render = function render(){var _vm=this,_c=_vm._self._c;return _c('table',{staticClass:"default-table font-weight-regular",staticStyle:{"width":"100%"}},[_c('tr',[_c('td',{staticClass:"font-weight-bold"},[_vm._v("Kauf")]),_c('td',{domProps:{"innerHTML":_vm._s(_vm.showBoughtItems())}})]),_c('tr',[_c('td',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t("status")))]),_c('td',[(
          _vm.data.order.included.payments &&
          _vm.data.order.included.payments.status === 'completed' &&
          _vm.data.order.isTerminated !== '1' &&
          _vm.data.order.isTerminated !== 1
        )?_c('v-chip',{staticClass:"white--text",attrs:{"color":"green"}},[_vm._v(" "+_vm._s(_vm.$t("Complete"))+" ")]):_c('v-chip',{staticClass:"white--text",attrs:{"color":_vm.data.underageParticipant === '1' ? 'orange' : 'light-green'}},[_vm._v(" "+_vm._s(_vm.$t("SepaMissing"))+" ")]),(
          _vm.data.order.included.payments &&
          _vm.data.order.included.payments.status !== 'completed' &&
          _vm.data.order.isTerminated !== '1' &&
          _vm.data.order.isTerminated !== 1 &&
          _vm.data.underageParticipant !== '1'
        )?[_c('v-btn',{staticClass:"ml-3",attrs:{"depressed":"","small":"","color":"primary","loading":_vm.progress},on:{"click":function($event){return _vm.sendReminder(_vm.data.order)}}},[_vm._v(" Reminder senden ")]),(_vm.data.order.reminderDates)?_c('table',{staticClass:"default-table"},[_c('tbody',[_c('tr',[_c('td',[_vm._v("Erinnerungen")]),_c('td',{domProps:{"innerHTML":_vm._s(_vm.remindesDates(_vm.data.order.reminderDates))}})])])]):_vm._e()]:_vm._e(),(_vm.data.underageParticipant === '1')?_c('span',{staticClass:"caption mx-2"},[_vm._v(" ("+_vm._s(_vm.$t("underageParticipant"))+") ")]):_vm._e()],2)]),(_vm.leadType === 'payContract')?_c('tr',[(_vm.data.order.isTerminated !== '1' && _vm.data.order.isTerminated !== 1)?_c('td',{attrs:{"colspan":"2"}},[_c('v-btn',{attrs:{"small":"","color":"error","loading":_vm.progress},on:{"click":function($event){return _vm.terminateContract(_vm.data.order.id)}}},[_vm._v(" Vertrag kündigen ")])],1):_c('td',{attrs:{"colspan":"2"}},[_vm._v(" Der Vertrag wurde am "+_vm._s(_vm.formatDate(_vm.data.order.isTerminatedDate))+" gekündigt. ")])]):_vm._e(),(_vm.data.order.transferred === '1' || _vm.data.order.transferred === 1)?_c('tr',[_c('td',{attrs:{"colspan":"2"}},[_vm._v("Der Vertrag wurde an den Anbieter übertragen.")])]):_vm._e(),(_vm.leadType === 'payContract' || _vm.leadType === 'payProduct')?_c('tr',[_c('td',{attrs:{"colspan":"2"}},[(_vm.isDisabledForNoneFaceforceStaff)?_c('v-btn',{attrs:{"small":"","color":"error","loading":_vm.progress},on:{"click":function($event){return _vm.deleteOrder(_vm.data.order.id)}}},[_vm._v(" Bestellung löschen ")]):_vm._e(),_c('br'),_c('span',{staticClass:"caption"},[_vm._v("Nur für FF-Admins möglich")])],1)]):_vm._e(),(
      _vm.data.order.included.payments &&
      _vm.data.order.included.payments.status === 'completed'
    )?[_c('tr',[_c('td',[_vm._v("PAYMENT")]),_c('td',[(_vm.campaign)?_c('table',{staticClass:"default-table"},[_c('tbody',[(_vm.data.order.included.payments.created)?_c('tr',[_c('td',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t("date")))]),_c('td',[_vm._v(" "+_vm._s(this.formatDate(_vm.data.order.included.payments.created))+" ")])]):_vm._e(),(_vm.data.order.included.payments.bankName)?_c('tr',[_c('td',{staticClass:"font-weight-bold"},[_vm._v("Bank")]),_c('td',[_vm._v(" "+_vm._s(_vm.data.order.included.payments.bankName)+" ")])]):_vm._e(),(_vm.data.order.included.payments.accountHolder)?_c('tr',[_c('td',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t("accountHolder"))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.data.order.included.payments.accountHolder)+" ")])]):_vm._e(),(_vm.data.order.included.payments.iban)?_c('tr',[_c('td',{staticClass:"font-weight-bold"},[_vm._v("IBAN")]),_c('td',[_vm._v(_vm._s(_vm.data.order.included.payments.iban))])]):_vm._e(),(_vm.data.order.included.payments.bic)?_c('tr',[_c('td',{staticClass:"font-weight-bold"},[_vm._v("BIC")]),_c('td',[_vm._v(_vm._s(_vm.data.order.included.payments.bic))])]):_vm._e(),(_vm.data.order.included.payments.payment_providers_id)?_c('tr',[_c('td',{staticClass:"font-weight-bold"},[_vm._v("PROVIDER")]),_c('td',[_vm._v(" "+_vm._s(_vm.data.order.included.payments.payment_providers_id)+" ")])]):_vm._e()])]):_vm._e()])])]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }