<template>
  <table class="default-table font-weight-regular" style="width: 100%">
    <tr>
      <td class="font-weight-bold">Kauf</td>
      <td v-html="showBoughtItems()"></td>
    </tr>
    <tr>
      <td class="font-weight-bold">{{ $t("status") }}</td>
      <td>
        <v-chip
          v-if="
            data.order.included.payments &&
            data.order.included.payments.status === 'completed' &&
            data.order.isTerminated !== '1' &&
            data.order.isTerminated !== 1
          "
          color="green"
          class="white--text"
        >
          {{ $t("Complete") }}
        </v-chip>

        <v-chip
          v-else
          :color="data.underageParticipant === '1' ? 'orange' : 'light-green'"
          class="white--text"
        >
          {{ $t("SepaMissing") }}
        </v-chip>

        <!-- PAYMENT REMINDER -->
        <template
          v-if="
            data.order.included.payments &&
            data.order.included.payments.status !== 'completed' &&
            data.order.isTerminated !== '1' &&
            data.order.isTerminated !== 1 &&
            data.underageParticipant !== '1'
          "
        >
          <v-btn
            depressed
            small
            color="primary"
            @click="sendReminder(data.order)"
            :loading="progress"
            class="ml-3"
          >
            Reminder senden
          </v-btn>

          <table v-if="data.order.reminderDates" class="default-table">
            <tbody>
              <tr>
                <td>Erinnerungen</td>
                <td v-html="remindesDates(data.order.reminderDates)"></td>
              </tr>
            </tbody>
          </table>
        </template>

        <span v-if="data.underageParticipant === '1'" class="caption mx-2">
          ({{ $t("underageParticipant") }})
        </span>
      </td>
    </tr>

    <tr v-if="leadType === 'payContract'">
      <!-- Vertrag kündigen -->
      <td
        colspan="2"
        v-if="data.order.isTerminated !== '1' && data.order.isTerminated !== 1"
      >
        <v-btn
          small
          color="error"
          :loading="progress"
          @click="terminateContract(data.order.id)"
        >
          Vertrag kündigen
        </v-btn>
      </td>

      <td v-else colspan="2">
        Der Vertrag wurde am
        {{ formatDate(data.order.isTerminatedDate) }} gekündigt.
      </td>
    </tr>

    <tr v-if="data.order.transferred === '1' || data.order.transferred === 1">
      <td colspan="2">Der Vertrag wurde an den Anbieter übertragen.</td>
    </tr>

    <tr v-if="leadType === 'payContract' || leadType === 'payProduct'">
      <!-- Bestellung löschen -->
      <td colspan="2">
        <v-btn
          v-if="isDisabledForNoneFaceforceStaff"
          small
          color="error"
          :loading="progress"
          @click="deleteOrder(data.order.id)"
        >
          Bestellung löschen
        </v-btn>
        <br /><span class="caption">Nur für FF-Admins möglich</span>
      </td>
    </tr>

    <template
      v-if="
        data.order.included.payments &&
        data.order.included.payments.status === 'completed'
      "
    >
      <tr>
        <td>PAYMENT</td>
        <td>
          <table v-if="campaign" class="default-table">
            <tbody>
              <!-- SHOW PAYMENT DATA -->
              <tr v-if="data.order.included.payments.created">
                <td class="font-weight-bold">{{ $t("date") }}</td>
                <td>
                  {{ this.formatDate(data.order.included.payments.created) }}
                </td>
              </tr>
              <tr v-if="data.order.included.payments.bankName">
                <td class="font-weight-bold">Bank</td>
                <td>
                  {{ data.order.included.payments.bankName }}
                </td>
              </tr>
              <tr v-if="data.order.included.payments.accountHolder">
                <td class="font-weight-bold">
                  {{ $t("accountHolder") }}
                </td>
                <td>
                  {{ data.order.included.payments.accountHolder }}
                </td>
              </tr>
              <tr v-if="data.order.included.payments.iban">
                <td class="font-weight-bold">IBAN</td>
                <td>{{ data.order.included.payments.iban }}</td>
              </tr>
              <tr v-if="data.order.included.payments.bic">
                <td class="font-weight-bold">BIC</td>
                <td>{{ data.order.included.payments.bic }}</td>
              </tr>
              <tr v-if="data.order.included.payments.payment_providers_id">
                <td class="font-weight-bold">PROVIDER</td>
                <td>
                  {{ data.order.included.payments.payment_providers_id }}
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </template>
  </table>
</template>

<script>
import store from "@/store";
import manageApi from "@/mixins/manageApi"; // import mixin (for api requests)
import helpers from "@/mixins/helpers"; // import mixin (for api requests)
export default {
  name: "LeadActivityMemberSection",
  props: ["data", "campaign"],

  data() {
    return {
      fetchinOrderData: false,
      leadType: this.data.type, // lead type (regular, payContract, payProduct (neu))
      hasMitfitPay: null,
      hasMitfitMember: null,
      hasOrdered: null,
      orderId: null,
      fetchinData: false, // should be true when fetching data
      progress: false, // should be true when fetching data
      companyID: store.getters["auth/current_sid"],
      textFieldProps: {
        prependIcon: "mdi-calendar-month",
      },

      noteComponentKey: 0,
      hasFeedbackResults: false,
      feedbackResults: null,
    };
  },

  created: function () {
    console.log("DATA", this.data);
    // mitfitPAY
    if (this.campaign) {
      this.hasMitfitPay = this.campaign.hasMitfitPay;
      this.hasMitfitMember = this.campaign.hasMitfitMember;
      if (
        this.campaign.hasMitfitMember === "1" ||
        this.campaign.hasMitfitPay === "1"
      ) {
        this.hasMitfitPay = "1";
      }
    }
  },

  methods: {
    remindesDates(string) {
      let dates = "<ul>";

      if (string) {
        const myArr = string.split(",");
        myArr.forEach((date) => {
          dates += "<li>" + this.formatDate(date) + "</li>";
        });

        dates += "</ul>";
      }

      return dates;
    },

    async deleteOrder(orderId) {
      const userChoice = await this.$dialog.confirm({
        text: `Soll die Bestellung #${orderId} wirklich gelöscht werden?`,
        title: "Achtung!",
        waitForResult: true,
      });

      if (userChoice) {
        // do it
        // Die neue Route DELETE /pay/orders/{id} nutzt die neue Methode App\Domain1\Pay\Orders->delete() zum Löschen.
        this.progress = true;
        let response = await this.deleteRequest(`pay/orders/${orderId}`);
        this.progress = false;

        if (response.status === 200) {
          // emit updated
          this.$emit("orderTerminated", true);

          /* CALL SNACKBAR */
          this.$root.snackbarGlobal.snackbar = true;
          this.$root.snackbarGlobal.snackbarText =
            "Die Bestellung wurde erfolgreich gelöscht!";
          this.$root.snackbarGlobal.errorMessage = "";
          this.$root.snackbarGlobal.snackbarColor = "primary";
          this.$root.snackbarGlobal.snackbarTimeout = 5000;
        }
      }
    },

    async sendReminder(order) {
      // Die Route POST pay/orders/{id}/sendReminder
      const orderId = order.id;

      this.progress = true;
      let response = await this.postRequest(
        `pay/orders/${orderId}/sendReminder`
      );
      this.progress = false;

      if (response.status === 200) {
        /* CALL SNACKBAR */
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText =
          "Der Reminder wurde erfolgreich versendet!";
        this.$root.snackbarGlobal.errorMessage = "";
        this.$root.snackbarGlobal.snackbarColor = "primary";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      }
    },

    async terminateWithMail(orderId) {
      const userChoice = await this.$dialog.confirm({
        text: `Soll eine E-Mail an den Nutzer verschickt werden?`,
        title: "Achtung!",
        waitForResult: true,
      });

      let sendMail = 0;
      if (userChoice) {
        sendMail = 1;
      }

      // PATCH /pay/orders/{id}/terminate
      const params = new URLSearchParams();
      params.append("sendEmailConfirmation", sendMail);

      this.progress = true;
      let response = await this.patchRequest(
        `pay/orders/${orderId}/terminate`,
        params
      );
      this.progress = false;

      if (response.status === 200) {
        this.$emit("orderTerminated", true);

        /* CALL SNACKBAR */
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText =
          "Der Vertrag wurde erfolgreich gekündigt!";
        this.$root.snackbarGlobal.errorMessage = "";
        this.$root.snackbarGlobal.snackbarColor = "primary";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      }
    },

    async terminateContract(orderId) {
      const userChoice = await this.$dialog.confirm({
        text: `Soll dieser Vertrag wirklich gekündigt werden?`,
        title: "Achtung!",
        waitForResult: true,
      });

      if (userChoice) {
        this.terminateWithMail(orderId);
      }

      return;
    },

    buildPromoSection(item) {
      let promoSection = "";
      let promoPeriodFactor = null;
      let promoTitle = "Promotion";

      if (item.promoTitle) {
        promoTitle = item.promoTitle;
      }

      if (item.promoRuntimePeriod) {
        if (item.promoRuntimePeriod === "daily") {
          promoPeriodFactor = "Tage";
        } else if (item.promoRuntimePeriod === "weekly") {
          promoPeriodFactor = "Wochen";
        } else if (item.promoRuntimePeriod === "monthly") {
          promoPeriodFactor = "Monate";
        } else if (item.promoRuntimePeriod === "yearly") {
          promoPeriodFactor = "Jahre";
        } else {
          promoPeriodFactor = item.promoRuntimePeriod;
        }
      }

      if (item.promoPrice) {
        promoSection += `
          <tr>
          <td>${promoTitle}</td>
          <td>${item.promoPrice} € für ${item.promoRuntimeCount} ${promoPeriodFactor}</td>
          </tr>`;
      }

      return promoSection;
    },

    buildItemSection(item) {
      let priceSection = "";
      let periodFactor = null;
      let fileDownload = "";
      let finePrint = "";
      let fee = "";
      // feePrice

      if (item.feePrice) {
        fee = `<tr>
        <td>${this.$t("additionalCosts")}</td>
        <td>${item.feeTitle} ${item.feePrice} € ${item.feeDescription}</td>
        </tr>`;
      }

      if (item.finePrint) {
        finePrint = `<tr>
        <td>${this.$t("finePrint")}</td>
        <td class="caption">${item.finePrint}</td>
        </tr>`;
      }

      if (item.fileLink) {
        /* GET /pay/contracts/{orderedContractID}/file */
        fileDownload = `<tr>
        <td>Download</td>
        <td><a href="${item.fileLink}" target="_blank">Vertrag-PDF</a></td>
        </tr>`;
      }

      if (item.runtimePeriod) {
        if (item.runtimePeriod === "daily") {
          periodFactor = "Tag";
        } else if (item.runtimePeriod === "weekly") {
          periodFactor = "Woche";
        } else if (item.runtimePeriod === "monthly") {
          periodFactor = "Monat";
        } else if (item.runtimePeriod === "yearly") {
          periodFactor = "Jahr";
        } else {
          periodFactor = item.runtimePeriod;
        }
      }

      priceSection += `<table><tbody>
        <tr>
        <td>Vertrag</td>
        <td>${item.title}</td>
        </tr>
        <tr>
        <td>Kosten</td>
        <td>${item.priceGross} EUR / ${periodFactor}</td>
        </tr>`;

      priceSection += this.buildPromoSection(item);

      priceSection += `<tr>
        <td>Vertragsstart</td>
        <td>${this.formatDateNoTime(item.runtimeStart)}</td>
        </tr>`;

      priceSection += `${fee}${finePrint}${fileDownload}</tbody></table>`;

      return priceSection;
    },

    showBoughtItems() {
      console.log(this.hasMitfitPay, this.hasMitfitMember);
      let itemsList = "";
      const orderedItems = this.data.order.included.items;

      orderedItems.forEach((item) => {
        itemsList += this.buildItemSection(item);
      });
      return itemsList;
    },

    async dateTimeChanged(d) {
      let day = d.getDate();
      let month = d.getMonth() + 1; // January is 0!
      let year = d.getFullYear();
      let h = d.getHours(); //Get the hour (0-23)
      let m = d.getMinutes(); //Get the minute (0-59)
      let s = d.getSeconds(); //Get the second (0-59)

      // leading zero
      if (month.toString().length < 2) month = "0" + month;
      if (day.toString().length < 2) day = "0" + day;
      if (h.toString().length < 2) h = "0" + h;
      if (m.toString().length < 2) m = "0" + m;
      if (s.toString().length < 2) s = "0" + s;

      const dateTime =
        year + "-" + month + "-" + day + " " + h + ":" + m + ":" + s;

      const params = new URLSearchParams();
      params.append("action", "updateScheduled");
      params.append("value", "1");
      params.append("date", dateTime);

      this.fetchinData = true;
      const response = await this.putRequest(
        "person_activity/" +
          this.companyID +
          "/" +
          this.data.games_data_gamerId +
          "/" +
          this.data.games_data_gameid,
        params
      );
      this.fetchinData = false;

      if (response.data.status === "ok") {
        this.data.games_data_gamer_isScheduledDate =
          response.data.result.dateAfter;

        /* CALL SNACKBAR */
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText = this.$t("messageUpdated");
        this.$root.snackbarGlobal.errorMessage = "";
        this.$root.snackbarGlobal.snackbarColor = "primary";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      }
    },

    emitted(value) {
      /* kommt von ActivityNotes. via emit gebe ich das event zurück, da ich mangels daten den array nicht mutieren kann.
        durch das emit (2 ebenen) starte ich den fetch erneut */

      if (value === "noteAdded") {
        this.$emit("emitted", "noteAdded");
      }
      this.noteComponentKey += 1; // reRender notes component
    },
  },

  computed: {
    dateTimeValue: {
      get() {
        /* 
        We got: 2021-09-28 15:50:00
        We need: Tue Sep 28 2021 15:50:00 GMT+0200 (Mitteleuropäische Sommerzeit) 
        */

        const value = this.data.games_data_gamer_isScheduledDate;

        if (!value || value === "") {
          return null;
        }
        const dateTimeValue = new Date(value);
        return dateTimeValue;
      },

      set: function () {
        return this.data.games_data_gamer_isScheduledDate;
      },
    },
  },

  mixins: [manageApi, helpers],
};
</script>

<style>
ul.itemslist {
  list-style-type: none !important;
  padding: 0;
}
</style>
