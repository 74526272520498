<template>
  <div class="mt-4" v-if="!fetchinData">
    <v-row>
      <v-col cols="12" md="6">
        <LeadBaseDataCard
          :id="id"
          :data="leadData"
          @updatedBaseData="componentKey += 1"
        ></LeadBaseDataCard>
      </v-col>

      <v-col cols="12" md="6">
        <LeadActivity
          :id="id"
          :key="componentKey"
          :data="leadData"
          :campaign="campaign"
          :context="context"
          @orderDeleted="orderDeleted()"
          @orderTerminated="orderTerminated()"
        ></LeadActivity>
      </v-col>

      <v-col cols="12" md="6">
        <LeadFunnelStats :id="leadData.id" />
      </v-col>

      <v-col cols="12" md="6">
        <LeadFeedbacks :id="leadData.id" />
      </v-col>

      <v-col cols="12" md="6">
        <LeadAdditionalData
          :key="componentKey"
          :leadData="leadData"
          :campaign="campaign"
        ></LeadAdditionalData>
      </v-col>

      <v-col v-if="identityInfo" cols="12" md="12">
        <IdentityHistory
          :name="rando"
          :identityUuid="leadData.identityUuid"
          :motherLeadId="leadData.id"
          :key="componentKey"
          :leadName="leadData.firstName + ' ' + leadData.lastName"
        />
      </v-col>
    </v-row>
  </div>
  <div v-else>
    <v-row class="my-6">
      <v-col cols="12" md="6">
        <v-skeleton-loader
          type="list-item-avatar-three-line, image, article"
        ></v-skeleton-loader>
      </v-col>
      <v-col cols="12" md="6">
        <v-skeleton-loader
          type="list-item-avatar-three-line, image, article"
        ></v-skeleton-loader>
      </v-col>
      <v-col cols="12">
        <v-skeleton-loader
          type="list-item-avatar-three-line, image, article"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
  </div>
</template>

<script>
/* 

Hier fetche ich die Daten UND Campaign-Data, da diese im Leads-Kontext nicht da sind.
Ich habe nämlich die campaignUuid in den Lead Daten. Deswegen verwende ich hier die public Route 

*/
import store from "@/store";
import LeadBaseDataCard from "@/components/lead/manage/LeadBaseDataCard";
import LeadActivity from "@/components/lead/manage/LeadActivity";
import LeadAdditionalData from "@/components/lead/manage/LeadAdditionalData";
import LeadFeedbacks from "@/components/lead/manage/LeadFeedbacks";
import LeadFunnelStats from "@/components/lead/manage/LeadFunnelStats";
export default {
  name: "LeadDetail",

  components: {
    LeadBaseDataCard,
    LeadActivity,
    LeadAdditionalData,
    LeadFeedbacks,
    LeadFunnelStats,
    IdentityHistory: () => import("@/components/lead/manage/IdentityHistory"),
  },

  props: {
    id: {
      type: String,
      required: true,
    },
    leadData: {
      type: Object,
      required: true,
    },
    campaign: {
      type: Object,
      required: true,
    },
    context: {
      type: String,
      required: false,
      default: "leads",
    },
    identityInfo: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      rando: `IdentityHistory${Math.random().toString(36).substring(2, 10)}`,
      rand: this.generateRandomString(),
      pageTitle: null,
      pageDescription: null,
      fetchinData: false, // should be true when fetching data
      companyID: store.getters["auth/current_sid"],
      componentKey: 0,
    };
  },

  mounted() {
    this.componentKey += 1;
    this.rand = this.generateRandomString();
  },

  methods: {
    orderDeleted() {
      this.$emit("orderDeleted", true);
    },
    orderTerminated() {
      this.$emit("orderTerminated", true);
    },
    generateRandomString() {
      return (
        Math.random().toString(36).substring(2, 10) +
        Math.random().toString(36).substring(2, 10)
      );
    },
  },
};
</script>
