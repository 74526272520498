import Vue from "vue";
import VueRouter from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import VueCookies from "vue-cookies";
/* import store from "@/store"; // needed for the token */

Vue.use(VueRouter);
Vue.use(VueCookies);

let routes = [
  {
    path: "/",
    name: "dashboard",
    icon: "mdi-monitor-dashboard",
    inNav: true,
    component: Dashboard,
    meta: {
      help: "helpDashboard",
      requiresAuth: true,
    },
  },

  {
    path: "/addons/annual-marketing",
    name: "annual_marketing.title",
    icon: "mdi-calendar-heart",
    inNav: true,
    component: () =>
      import("@/views/addons/annual-marketing/AnnualMarketing.vue"),
    meta: {
      permission: false,
      help: "helpJmp",
      requiresAuth: true,
    },
  },

  {
    path: "/annual-marketing/:id",
    name: "annual-marketing:id",
    icon: "",
    inNav: false,
    component: () => import("@/views/addons/annual-marketing/Plan.vue"),
    meta: {
      permission: false,
      help: "helpJmp",
      requiresAuth: true,
    },
  },

  {
    path: "/leads",
    name: "mitfitLeads",
    icon: "mdi-trophy",
    inNav: true,
    component: () => import("@/views/campaigns/Campaigns.vue"),
    meta: {
      permission: "5",
      help: "helpCampaigns",
      requiresAuth: true,
    },
  },

  {
    path: "/ocampaigns/:id",
    name: "ocampaigns:id",
    icon: "mdi-rocket-launch",
    inNav: false,
    component: () => import("@/views/campaigns/Campaign.vue"),
    meta: {
      permission: "5",
      help: "helpCampaignsDetail",
      requiresAuth: true,
    },
  },

  {
    path: "/campaigns/:id",
    name: "campaigns:id",
    icon: "mdi-rocket-launch",
    inNav: false,
    component: () => import("@/views/campaigns/CampaignManage.vue"),
    meta: {
      permission: "5",
      help: "helpCampaignsDetail",
      requiresAuth: true,
    },
  },

  {
    path: "/addons/member",
    name: "mitfitMEMBER",
    icon: "mdi-file-document-edit",
    inNav: true,
    component: () => import("@/views/addons/addons-member/AddonMember.vue"),
    meta: {
      permission: "12",
      help: false,
      requiresAuth: true,
    },
  },

  {
    path: "/addons/shops",
    name: "mitfitSHOPS",
    icon: "mdi-cart",
    inNav: true,
    component: () => import("@/views/addons/addons-shop/AddonShops.vue"),
    meta: {
      permission: "12",
      help: false,
      requiresAuth: true,
    },
  },

  {
    path: "/persons",
    name: "Persons",
    icon: "mdi-account-group",
    inNav: true,
    component: () => import("@/views/leads/Leads.vue"),
    meta: {
      permission: "4",
      help: "helpLeads",
      requiresAuth: true,
    },
  },

  {
    path: "/events/bookings",
    name: "EventBookings",
    icon: "mdi-calendar-account",
    inNav: true,
    component: () => import("@/views/events/bookings/EventBookings.vue"),
    meta: {
      permission: "4",
      help: "helpLeads",
      requiresAuth: true,
    },
  },

  {
    path: "/company",
    name: "company",
    icon: "mdi-office-building",
    inNav: true,
    component: () => import("@/views/company/company.vue"),
    meta: {
      permission: "6",
      help: "helpCompany",
      requiresAuth: true,
    },
  },

  {
    path: "/connections",
    name: "connections",
    icon: "mdi-lan-connect",
    inNav: true,
    component: () => import("@/views/connections/connections.vue"),
    meta: {
      permission: "6",
      help: false,
      requiresAuth: true,
    },
  },

  {
    path: "/gdpr",
    name: "gdpr",
    icon: "mdi-security",
    inNav: true,
    component: () => import("@/views/gdpr/Gdpr.vue"),
    meta: {
      permission: "4",
      help: "helpGdpr",
      requiresAuth: true,
    },
  },

  {
    path: "/settings",
    name: "settings",
    icon: "mdi-account-cog",
    inNav: true,
    component: () => import("@/views/settings/Settings.vue"),
    meta: {
      permission: "9",
      help: false,
      requiresAuth: true,
    },
  },

  {
    path: "/settings/change-password",
    name: "changePassword",
    icon: "",
    inNav: false,
    component: () => import("@/views/settings/ChangePassword.vue"),
    meta: {
      permission: false,
      help: false,
      requiresAuth: true,
    },
  },

  {
    path: "/404",
    name: "404",
    icon: "",
    inNav: false,
    component: () => import("@/views/404.vue"),
    meta: {
      permission: false,
      help: false,
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: routes,
});

export default router;
