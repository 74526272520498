import store from "@/store"; // needed for the token
import { mapActions } from "vuex"; // import actions from vuex (eg. store/modules/auth.js)
import axios from "axios";

export default {
  methods: {
    ...mapActions({
      // spread actions in to component methods (LogIn is a method in auth.js actions)
      LogOut: "auth/LogOut",
    }),

    getRequest(route) {
      let that = this;
      return new Promise((resolve) => {
        axios
          .get(this.$manageApiUrl + route, {
            headers: {
              Token: store.getters["auth/manageToken"],
            },
          })
          .then(function (response) {
            // handle success
            resolve(response);
          })
          .catch(function (error) {
            // handle error

            let errorMessage = "Die API meldet einen Fehler!";
            let errorDetails =
              "<br/>" +
              error +
              " | Route: " +
              route +
              ". Bitte kontaktiere den SiM-Support.";

            if (error && error.response && error.response.status === 401) {
              errorMessage = "Deine Sitzung ist abgelaufen!";
              errorDetails = "<br/>Bitte logge Dich erneut ein.";
              that.LogOut();
            }

            /* start snackbar */
            that.$root.snackbarGlobal.snackbar = true;
            that.$root.snackbarGlobal.snackbarText = errorMessage;
            that.$root.snackbarGlobal.errorMessage = errorDetails;
            that.$root.snackbarGlobal.snackbarColor = "red";
            that.$root.snackbarGlobal.snackbarTimeout = 10000;

            resolve(false);
          });
      });
    },

    postRequest(route, body, data) {
      if (!body) {
        body = "";
      }

      if (!data) {
        data = "";
      }

      let that = this;
      return new Promise((resolve) => {
        axios
          .post(this.$manageApiUrl + route, data, {
            headers: {
              Token: store.getters["auth/manageToken"],
            },
          })
          .then(function (response) {
            // handle success
            resolve(response);
          })
          .catch(function (error) {
            let errorMessage = "Die API meldet einen Fehler!";
            let errorDetails =
              "<br/>" +
              error +
              " | Route: " +
              route +
              ". Bitte kontaktiere den SiM-Support.";

            if (error && error.response && error.response.status === 401) {
              errorMessage = "Deine Sitzung ist abgelaufen!";
              errorDetails = "<br/>Bitte logge Dich erneut ein.";
              that.LogOut();
            }

            /* start snackbar */
            that.$root.snackbarGlobal.snackbar = true;
            that.$root.snackbarGlobal.snackbarText = errorMessage;
            that.$root.snackbarGlobal.errorMessage = errorDetails;
            that.$root.snackbarGlobal.snackbarColor = "red";
            that.$root.snackbarGlobal.snackbarTimeout = 10000;

            resolve(false);
          });
      });
    },

    putRequest(route, params, type = null) {
      if (!params) {
        params = "";
      }

      let contentType = "application/x-www-form-urlencoded";

      if (type === "json") {
        contentType = "application/json";
      }

      let that = this;
      return new Promise((resolve) => {
        axios
          .put(this.$manageApiUrl + route, params, {
            headers: {
              Token: store.getters["auth/manageToken"],
              "Content-Type": contentType,
            },
          })
          .then(function (response) {
            // handle success
            resolve(response);
          })
          .catch(function (error) {
            let errorMessage = "Die API meldet einen Fehler!";
            let errorDetails =
              "<br/>" +
              error +
              " | Route: " +
              route +
              ". Bitte kontaktiere den SiM-Support.";

            if (error && error.response && error.response.status === 401) {
              errorMessage = "Deine Sitzung ist abgelaufen!";
              errorDetails = "<br/>Bitte logge Dich erneut ein.";
              that.LogOut();
            }

            /* start snackbar */
            that.$root.snackbarGlobal.snackbar = true;
            that.$root.snackbarGlobal.snackbarText = errorMessage;
            that.$root.snackbarGlobal.errorMessage = errorDetails;
            that.$root.snackbarGlobal.snackbarColor = "red";
            that.$root.snackbarGlobal.snackbarTimeout = 10000;

            resolve(false);
          });
      });
    },

    patchRequest(route, params) {
      let that = this;
      return new Promise((resolve) => {
        axios
          .patch(this.$manageApiUrl + route, params, {
            headers: {
              Token: store.getters["auth/manageToken"],
              "Content-Type": "application/x-www-form-urlencoded",
            },
          })
          .then(function (response) {
            // handle success
            resolve(response);
          })
          .catch(function (error) {
            let errorMessage = "Die API meldet einen Fehler!";
            let errorDetails =
              "<br/>" +
              error +
              " | Route: " +
              route +
              ". Bitte kontaktiere den SiM-Support.";

            if (error && error.response && error.response.status === 401) {
              errorMessage = "Deine Sitzung ist abgelaufen!";
              errorDetails = "<br/>Bitte logge Dich erneut ein.";
              that.LogOut();
            }

            /* start snackbar */
            that.$root.snackbarGlobal.snackbar = true;
            that.$root.snackbarGlobal.snackbarText = errorMessage;
            that.$root.snackbarGlobal.errorMessage = errorDetails;
            that.$root.snackbarGlobal.snackbarColor = "red";
            that.$root.snackbarGlobal.snackbarTimeout = 5000;

            resolve(false);
          });
      });
    },

    deleteRequest(route) {
      let that = this;
      return new Promise((resolve) => {
        axios
          .delete(this.$manageApiUrl + route, {
            headers: {
              Token: store.getters["auth/manageToken"],
            },
          })
          .then(function (response) {
            // handle success
            resolve(response);
          })
          .catch(function (error) {
            let errorMessage = "Die API meldet einen Fehler!";
            let errorDetails =
              "<br/>" +
              error +
              " | Route: " +
              route +
              ". Bitte kontaktiere den SiM-Support.";

            if (error && error.response && error.response.status === 401) {
              errorMessage = "Deine Sitzung ist abgelaufen!";
              errorDetails = "<br/>Bitte logge Dich erneut ein.";
              that.LogOut();
            }

            /* start snackbar */
            that.$root.snackbarGlobal.snackbar = true;
            that.$root.snackbarGlobal.snackbarText = errorMessage;
            that.$root.snackbarGlobal.errorMessage = errorDetails;
            that.$root.snackbarGlobal.snackbarColor = "red";
            that.$root.snackbarGlobal.snackbarTimeout = 10000;

            resolve(false);
          });
      });
    },
  },
};
