import store from "@/store"; // needed for the token
import { mapActions } from "vuex"; // import actions from vuex (eg. store/modules/auth.js)
import axios from "axios";

export default {
  methods: {
    ...mapActions({
      // spread actions in to component methods (LogIn is a method in auth.js actions)
      LogOut: "auth/LogOut",
    }),

    getRequest(route) {
      let that = this;
      return new Promise((resolve) => {
        // Nutze hier die alte Route

        axios
          .get(this.$manageApiUrl + route, {
            headers: {
              Token: store.getters["auth/manageToken"],
            },
          })
          .then(function (response) {
            // handle success
            resolve(response);
          })
          .catch(function (error) {
            // handle error

            if (error.response.status !== 400) {
              // dont show error @ no permission

              let errorMessage = "Die API meldet einen Fehler!";
              let errorDetails =
                "<br/>" +
                error +
                " | Route: " +
                route +
                ". Bitte kontaktiere den SiM-Support.";

              if (error && error.response && error.response.status === 401) {
                errorMessage = "Deine Sitzung ist abgelaufen!";
                errorDetails = "<br/>Bitte logge Dich erneut ein.";
                that.LogOut();
              }

              /* start snackbar */
              that.$root.snackbarGlobal.snackbar = true;
              that.$root.snackbarGlobal.snackbarText = errorMessage;
              that.$root.snackbarGlobal.errorMessage = errorDetails;
              that.$root.snackbarGlobal.snackbarColor = "red";
              that.$root.snackbarGlobal.snackbarTimeout = 10000;
            }

            resolve(false);
          });
      });
    },

    getPayRequest(route) {
      let that = this;
      return new Promise((resolve) => {
        axios
          .get(this.$manageApiUrl + route, {
            headers: {
              Token: store.getters["auth/token"],
            },
          })
          .then(function (response) {
            // handle success
            resolve(response);
          })
          .catch(function (error) {
            // handle error

            let errorMessage = "Die API meldet einen Fehler!";
            let errorDetails =
              "<br/>" +
              error +
              " | Route: " +
              route +
              ". Bitte kontaktiere den SiM-Support.";

            if (error && error.response && error.response.status === 401) {
              errorMessage = "Deine Sitzung ist abgelaufen!";
              errorDetails = "<br/>Bitte logge Dich erneut ein.";
              that.LogOut();
            }

            /* start snackbar */
            that.$root.snackbarGlobal.snackbar = true;
            that.$root.snackbarGlobal.snackbarText = errorMessage;
            that.$root.snackbarGlobal.errorMessage = errorDetails;
            that.$root.snackbarGlobal.snackbarColor = "red";
            that.$root.snackbarGlobal.snackbarTimeout = 10000;

            resolve(false);
          });
      });
    },

    postRequest(route, body, data) {
      if (!body) {
        body = "";
      }

      if (!data) {
        data = "";
      }

      let that = this;
      return new Promise((resolve) => {
        // Nutze hier die alte Route

        axios
          .post(this.$manageApiUrl + route, data, {
            headers: {
              Token: store.getters["auth/token"],
            },
          })
          .then(function (response) {
            // handle success
            resolve(response);
          })
          .catch(function (error) {
            let errorMessage = "Die API meldet einen Fehler!";
            let errorDetails =
              "<br/>" +
              error +
              " | Route: " +
              route +
              ". Bitte kontaktiere den SiM-Support.";

            if (error && error.response && error.response.status === 401) {
              errorMessage = "Deine Sitzung ist abgelaufen!";
              errorDetails = "<br/>Bitte logge Dich erneut ein.";
              that.LogOut();
            }

            /* start snackbar */
            that.$root.snackbarGlobal.snackbar = true;
            that.$root.snackbarGlobal.snackbarText = errorMessage;
            that.$root.snackbarGlobal.errorMessage = errorDetails;
            that.$root.snackbarGlobal.snackbarColor = "red";
            that.$root.snackbarGlobal.snackbarTimeout = 10000;

            resolve(false);
          });
      });
    },

    postPayRequest(route, data) {
      if (!data) {
        data = "";
      }

      let that = this;
      return new Promise((resolve) => {
        axios
          .post(this.$manageApiUrl + route, data, {
            headers: {
              Token: store.getters["auth/token"],
            },
          })
          .then(function (response) {
            // handle success
            resolve(response);
          })
          .catch(function (error) {
            let errorMessage = "Die API meldet einen Fehler!";
            let errorDetails =
              "<br/>" +
              error +
              " | Route: " +
              route +
              ". Bitte kontaktiere den SiM-Support.";

            if (error && error.response && error.response.status === 401) {
              errorMessage = "Deine Sitzung ist abgelaufen!";
              errorDetails = "<br/>Bitte logge Dich erneut ein.";
              that.LogOut();
            }

            /* start snackbar */
            that.$root.snackbarGlobal.snackbar = true;
            that.$root.snackbarGlobal.snackbarText = errorMessage;
            that.$root.snackbarGlobal.errorMessage = errorDetails;
            that.$root.snackbarGlobal.snackbarColor = "red";
            that.$root.snackbarGlobal.snackbarTimeout = 10000;

            resolve(false);
          });
      });
    },

    putRequest(route, params) {
      if (!params) {
        params = "";
      }

      let that = this;
      return new Promise((resolve) => {
        // Nutze hier die alte Route

        axios
          .put(this.$manageApiUrl + route, params, {
            headers: {
              Token: store.getters["auth/token"],
              "Content-Type": "application/x-www-form-urlencoded",
            },
          })
          .then(function (response) {
            // handle success
            resolve(response);
          })
          .catch(function (error) {
            let errorMessage = "Die API meldet einen Fehler!";
            let errorDetails =
              "<br/>" +
              error +
              " | Route: " +
              route +
              ". Bitte kontaktiere den SiM-Support.";

            if (error && error.response && error.response.status === 401) {
              errorMessage = "Deine Sitzung ist abgelaufen!";
              errorDetails = "<br/>Bitte logge Dich erneut ein.";
              that.LogOut();
            }

            /* start snackbar */
            that.$root.snackbarGlobal.snackbar = true;
            that.$root.snackbarGlobal.snackbarText = errorMessage;
            that.$root.snackbarGlobal.errorMessage = errorDetails;
            that.$root.snackbarGlobal.snackbarColor = "red";
            that.$root.snackbarGlobal.snackbarTimeout = 10000;

            resolve(false);
          });
      });
    },

    putPayRequest(route, params) {
      let that = this;

      return new Promise((resolve) => {
        axios({
          method: "put",
          url: this.$manageApiUrl + route,
          headers: {
            Token: store.getters["auth/token"],
            "Content-Type": "application/json",
          },
          data: params,
        })
          .then(function (response) {
            // handle success
            resolve(response);
          })
          .catch(function (error) {
            let errorMessage = "Die API meldet einen Fehler!";
            let errorDetails =
              "<br/>" +
              error +
              " | Route: " +
              route +
              ". Bitte kontaktiere den SiM-Support.";

            if (error && error.response && error.response.status === 401) {
              errorMessage = "Deine Sitzung ist abgelaufen!";
              errorDetails = "<br/>Bitte logge Dich erneut ein.";
              that.LogOut();
            }

            this.$root.snackbarGlobal.snackbar = true;
            this.$root.snackbarGlobal.snackbarText = errorMessage;
            this.$root.snackbarGlobal.errorMessage = errorDetails;
            this.$root.snackbarGlobal.snackbarColor = "red";
            this.$root.snackbarGlobal.snackbarTimeout = 5000;

            resolve(false);
          });
      });
    },

    deletePayRequest(route) {
      let that = this;

      return new Promise((resolve) => {
        axios({
          method: "delete",
          url: this.$manageApiUrl + route,
          headers: {
            Token: store.getters["auth/token"],
            "Content-Type": "application/json",
          },
        })
          .then(function (response) {
            // handle success
            resolve(response);
          })
          .catch(function (error) {
            let errorMessage = "Die API meldet einen Fehler!";
            let errorDetails =
              "<br/>" +
              error +
              " | Route: " +
              route +
              ". Bitte kontaktiere den SiM-Support.";

            if (error && error.response && error.response.status === 401) {
              errorMessage = "Deine Sitzung ist abgelaufen!";
              errorDetails = "<br/>Bitte logge Dich erneut ein.";
              that.LogOut();
            }

            this.$root.snackbarGlobal.snackbar = true;
            this.$root.snackbarGlobal.snackbarText = errorMessage;
            this.$root.snackbarGlobal.errorMessage = errorDetails;
            this.$root.snackbarGlobal.snackbarColor = "red";
            this.$root.snackbarGlobal.snackbarTimeout = 5000;

            resolve(false);
          });
      });
    },

    patchRequest(route, params) {
      let that = this;
      return new Promise((resolve) => {
        // Nutze hier die alte Route

        axios
          .patch(this.$manageApiUrl + route, params, {
            headers: {
              Token: store.getters["auth/token"],
              "Content-Type": "application/x-www-form-urlencoded",
            },
          })
          .then(function (response) {
            // handle success
            resolve(response);
          })
          .catch(function (error) {
            let errorMessage = "Die API meldet einen Fehler!";
            let errorDetails =
              "<br/>" +
              error +
              " | Route: " +
              route +
              ". Bitte kontaktiere den SiM-Support.";

            if (error && error.response && error.response.status === 401) {
              errorMessage = "Deine Sitzung ist abgelaufen!";
              errorDetails = "<br/>Bitte logge Dich erneut ein.";
              that.LogOut();
            }

            /* start snackbar */
            that.$root.snackbarGlobal.snackbar = true;
            that.$root.snackbarGlobal.snackbarText = errorMessage;
            that.$root.snackbarGlobal.errorMessage = errorDetails;
            that.$root.snackbarGlobal.snackbarColor = "red";
            that.$root.snackbarGlobal.snackbarTimeout = 5000;

            resolve(false);
          });
      });
    },

    deleteRequest(route) {
      let that = this;
      return new Promise((resolve) => {
        // Nutze hier die alte Route

        axios
          .delete(this.$manageApiUrl + route, {
            headers: {
              Token: store.getters["auth/token"],
            },
          })
          .then(function (response) {
            // handle success
            resolve(response);
          })
          .catch(function (error) {
            let errorMessage = "Die API meldet einen Fehler!";
            let errorDetails =
              "<br/>" +
              error +
              " | Route: " +
              route +
              ". Bitte kontaktiere den SiM-Support.";

            if (error && error.response && error.response.status === 401) {
              errorMessage = "Deine Sitzung ist abgelaufen!";
              errorDetails = "<br/>Bitte logge Dich erneut ein.";
              that.LogOut();
            }

            /* start snackbar */
            that.$root.snackbarGlobal.snackbar = true;
            that.$root.snackbarGlobal.snackbarText = errorMessage;
            that.$root.snackbarGlobal.errorMessage = errorDetails;
            that.$root.snackbarGlobal.snackbarColor = "red";
            that.$root.snackbarGlobal.snackbarTimeout = 10000;

            resolve(false);
          });
      });
    },

    /* MANAGE API */

    getManageRequest(route) {
      let that = this;
      return new Promise((resolve) => {
        axios
          .get(this.$manageApiUrl + route, {
            headers: {
              Token: store.getters["auth/token"],
            },
          })
          .then(function (response) {
            // handle success
            resolve(response);
          })
          .catch(function (error) {
            // handle error

            let errorMessage = "Die API meldet einen Fehler!";
            let errorDetails =
              "<br/>" +
              error +
              " | Route: " +
              route +
              ". Bitte kontaktiere den SiM-Support.";

            if (error && error.response && error.response.status === 401) {
              errorMessage = "Deine Sitzung ist abgelaufen!";
              errorDetails = "<br/>Bitte logge Dich erneut ein.";
              that.LogOut();
            }

            /* start snackbar */
            that.$root.snackbarGlobal.snackbar = true;
            that.$root.snackbarGlobal.snackbarText = errorMessage;
            that.$root.snackbarGlobal.errorMessage = errorDetails;
            that.$root.snackbarGlobal.snackbarColor = "red";
            that.$root.snackbarGlobal.snackbarTimeout = 10000;

            resolve(false);
          });
      });
    },
  },
};
