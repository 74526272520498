<template>
  <v-card v-if="!fetchinData">
    <v-toolbar dense dark color="dark">
      <v-toolbar-title>Funnel-Statistiken</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-simple-table class="caption">
        <thead>
          <tr>
            <th class="text-left">Status</th>
            <th class="text-left">Info</th>
            <th class="text-left">Zweig</th>
            <th class="text-left">Sendedatum</th>
            <th class="text-left">Betreff</th>
            <th v-if="adminType === 'faceforce'" class="text-left">Aktionen</th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="(item, index) in data"
            :key="index"
            :class="whatColor(item.status)"
          >
            <template v-if="!item.expiryDate">
              <td class="caption">{{ $t("funnel." + item.status) }}</td>
              <td class="caption">
                {{ item.statusReason ? $t("funnel." + item.statusReason) : "" }}
              </td>
              <td class="caption">{{ item.branchName }}</td>
              <td class="caption">{{ formatDate(item.queueDateTime) }}</td>
              <td class="caption">{{ item.subject }}</td>
              <td v-if="adminType === 'faceforce'" class="caption">
                <v-btn
                  v-if="item.status === 'open'"
                  depressed
                  small
                  color="error"
                  :loading="testRunning"
                  :disabled="isDisabled(item)"
                  @click="testQueue(item.queueId, item.funnel_id)"
                >
                  Test
                </v-btn>
              </td>
            </template>
            <template v-else>
              <td v-if="item.status === 'open'" class="caption" colspan="5">
                Statusänderungen werden bis zum
                {{ formatDateNoTime(item.expiryDate) }} beobachtet.
              </td>
              <td
                v-if="adminType === 'faceforce' && item.status === 'open'"
                class="caption"
              >
                <v-btn
                  depressed
                  small
                  color="error"
                  :loading="testRunning"
                  :disabled="isDisabled(item)"
                  @click="testQueue(item.queueId, item.funnel_id)"
                >
                  Test
                </v-btn>
              </td>
            </template>
          </tr>
        </tbody>
      </v-simple-table>
      <LeadAddTag v-if="adminType === 'faceforce'" :id="id"></LeadAddTag>
    </v-card-text>
  </v-card>
</template>

<script>
import store from "@/store";
import helpers from "@/mixins/helpers"; // import mixin (for api requests)
import manageApi from "@/mixins/manageApi"; // import mixin (for api requests)
import LeadAddTag from "@/components/lead/manage/LeadAddTag";

export default {
  name: "LeadFunnelStats",

  props: ["id"],

  components: {
    LeadAddTag,
  },

  data() {
    return {
      data: [],
      fetchinData: false, // should be true when fetching data
      testRunning: false,
      adminType: store.getters["auth/adminType"],
    };
  },

  mounted() {
    this.getData();
  },

  methods: {
    isDisabled(item) {
      // check if item is the first element in this.data, that has status="open".
      // if so, return false, else return true
      const firstOpenItem = this.data.find(
        (dataItem) => dataItem.status === "open"
      );
      return item !== firstOpenItem;
    },

    whatColor(status) {
      if (status === "open") {
        return "white";
      } else if (status === "sent") {
        return "green lighten-5 green--text";
      } else if (status === "canceled") {
        return "grey lighten-4 grey--text";
      } else {
        return "gray";
      }
    },

    async getData() {
      this.fetchinData = true;
      const response = await this.getRequest(
        `leads/${this.id}/funnel/stats`,
        true
      );
      this.data = response.data.data;
      this.fetchinData = false;
      return response;
    },

    async testQueue(queueId, funnel_id) {
      console.log("TEST QUEUE:", queueId, "in Funnel:", funnel_id);
      // POST /campaignFunnel/{funnelId}/queues/{queueId}
      this.testRunning = true;
      const response = await this.postRequest(
        `campaignFunnel/${funnel_id}/queues/${queueId}`,
        true
      );
      this.testRunning = false;
      this.getData();
      return response;
    },
  },

  mixins: [helpers, manageApi],
};
</script>

<style>
.note-bubble {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  font-weight: bold;
  color: white;
  margin: 0 auto;
}
</style>
