import store from "@/store"; // needed for the token
import axios from "axios";

export default {
  methods: {
    async companySetup() {
      let navi = await axios.get(`sessions/navi`, {
        headers: {
          Token: store.getters["auth/token"],
        },
      });

      if (navi.data.status !== "error") {
        this.$store.commit("nav/SET_NAVIGATION", navi.data.data);
      }

      return new Promise((resolve) => {
        let that = this;

        axios
          .get(`companies/${store.getters["auth/current_sid"]}`, {
            headers: {
              Token: store.getters["auth/token"],
            },
          })

          .then(function (response) {
            // handle success
            let data = response.data.data[0];

            /* UPDATE VUEX STORE */
            store.commit("company/SET_COMPANY", {
              id: data.id,
              name: data.name,
              jmp: data.isJmp,
              fitness_jmp: null,
              golf_jmp: null,
              physio_jmp: null,
              logo: data.logo,
              ems_jmp: null,
              shop: null,
              video_library: null,
            });

            resolve(true);
          })
          .catch(function (error) {
            // handle error

            let errorMessage = "Die API meldet einen Fehler!";
            let errorDetails =
              "<br/>" + error + " | Bitte kontaktiere den SiM-Support.";

            if (error && error.response && error.response.status === 401) {
              errorMessage = "Deine Sitzung ist abgelaufen!";
              errorDetails = "<br/>Bitte logge Dich erneut ein.";
              that.LogOut();
            }

            /* start snackbar */
            that.$root.snackbarGlobal.snackbar = true;
            that.$root.snackbarGlobal.snackbarText = errorMessage;
            that.$root.snackbarGlobal.errorMessage = errorDetails;
            that.$root.snackbarGlobal.snackbarColor = "red";
            that.$root.snackbarGlobal.snackbarTimeout = 10000;

            resolve(false);
          });
      });
    },
  },
};
