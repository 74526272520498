<template>
  <span>
    <v-btn
      v-if="!isOptOut"
      @click="askFor"
      :disabled="isOptOut"
      small
      depressed
      color="error"
    >
      E-Mail Opt-Out
    </v-btn>

    <v-alert v-else border="left" color="primary" dark>
      {{ $t("leadIsOptOutMessage") }}
    </v-alert>
  </span>
</template>

<script>
import store from "@/store";
import helpers from "@/mixins/helpers";
import manageApi from "@/mixins/manageApi"; // import mixin (for api requests)
export default {
  name: "DeleteLead",
  props: ["id", "isOptOut"],
  data() {
    return {
      fetchinData: false,
      companyID: store.getters["auth/current_sid"],
      adminID: store.getters["auth/adminid"],
    };
  },

  methods: {
    async optOut() {
      /* Neue Route PATCH /leads/{id}/optOut ohne Parameter. */
      this.fetchinData = true;
      let response = await this.patchRequest(`leads/${this.id}/optOut`);
      this.fetchinData = false;

      if (response.status === 200) {
        this.$emit("updated", 1);
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText =
          "Lead wurde aus allen E-Mail Verteilern entfernt.";
        this.$root.snackbarGlobal.snackbarColor = "primary";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      }
    },

    async askFor() {
      const userChoice = await this.$dialog.confirm({
        text: `Soll der Lead tatsächlich aus allen E-Mail Verteilern entfernt werden werden?`,
        title: "Achtung!",
        waitForResult: true,
      });

      if (userChoice) {
        this.optOut();
      }
    },
  },

  mixins: [manageApi, helpers],
};
</script>
