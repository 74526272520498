<template>
  <div>
    <v-app-bar
      class="v-bar--underline"
      color="white"
      dense
      light
      elevation="0"
      app
      style="border-bottom: 1px solid"
    >
      <div v-if="authenticated">
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        {{ envInfo() }}
        <span v-if="maintenanceMode"><strong>[MAINTENANCE-MODE]</strong></span>
      </div>

      <v-spacer></v-spacer>

      <div v-if="authenticated">
        <Usermenu v-bind:username="user.firstname" />
        <CompanyHeadLogo />
      </div>

      <div v-else>
        <v-btn text to="/login">Login</v-btn>
      </div>
    </v-app-bar>

    <div v-if="authenticated">
      <v-navigation-drawer v-model="drawer" dark app class="dark pb-8">
        <div
          id="logobox"
          class="primary d-flex justify-center align-center white--text headline font-weight-bold font-italic"
        >
          mitfit
        </div>

        <!-- if: is it allowed to change company? -->

        <template v-if="managedCompanies > 1">
          <v-combobox
            v-if="companyChangeOption"
            class="m-0"
            :items="clients"
            v-model="companySelect"
            item-text="name"
            item-value="id"
            :label="$t('company')"
            @change="changeCompany"
            filled
          ></v-combobox>

          <v-select
            v-else
            class="m-0"
            :items="clients"
            v-model="companySelect"
            item-text="name"
            item-value="id"
            :label="$t('company')"
            filled
            disabled
          ></v-select>
        </template>

        <v-list>
          <div v-for="route in router" :key="route.name">
            <div v-if="route.inNav && showsUp(route)">
              <v-list-item :to="{ path: route.path }">
                <v-list-item-icon v-if="route.icon && route.icon !== ''">
                  <v-icon>{{ route.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    v-text="$t(route.name)"
                  ></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </div>
          </div>
        </v-list>

        <span class="caption white--text px-4" style="opacity: 0.2">
          App-Version: {{ this.$appVersion }}
        </span>

        <QuickSupportButton />

        <LangSelector style="margin-bottom: 150px" />
      </v-navigation-drawer>
    </div>
  </div>
</template>

<script>
import store from "@/store"; // needed for the token
import manageApi from "@/mixins/manageApi";
import companyData from "@/mixins/companyData";
import { mapGetters } from "vuex";
import Usermenu from "@/components/user/Usermenu.vue";
import CompanyHeadLogo from "@/components/ui/CompanyHeadLogo.vue";
import LangSelector from "@/components/ui/LangSelector.vue";
import QuickSupportButton from "@/components/ui/QuickSupportButton.vue";
export default {
  name: "AppBarNavigation",

  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user",
      clients: "auth/clients",
      current_studio_id: "auth/current_sid",
      companyChangeOption: "companyChangeOption/allowed",
    }),
  },

  components: {
    Usermenu,
    CompanyHeadLogo,
    LangSelector,
    QuickSupportButton,
  },

  watch: {
    "$store.state.company.id"() {
      this.companySelect = this.$store.getters["company/current_company"];
    },
  },

  props: {
    maintenanceMode: {
      type: Boolean,
      default: false,
    },
    currentCompanyId: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      drawer: null,
      activeItem: null,
      router: this.$router.options.routes,
      managedCompanies: this.$store.getters["auth/clients"].length,
      /* VUEX-Store values */
      companySelect: this.$store.getters["company/current_company"],
      permissions: this.$store.getters["auth/permissions"], // permissions ["1","2" ...]
      adminType: this.$store.getters["auth/adminType"], // admin/employee
      isFitnessJMP: this.$store.getters["company/fitness_jmp"],
      isGolfJMP: this.$store.getters["company/golf_jmp"],
      isPhysioJMP: this.$store.getters["company/physio_jmp"],
      isJMP: this.$store.getters["company/jmp"],
      hasShop: this.$store.getters["company/shop"],
    };
  },

  methods: {
    envInfo() {
      const env = process.env.VUE_APP_ENVIROMENT;

      if (env === "devel") {
        return " DEVELOPMENT";
      } else if (env === "test") {
        return " TEST";
      } else if (env === "preprod") {
        return " PRE-PRODUCTION";
      } else {
        return "";
      }
    },

    storeWatch() {
      return store.getters["company/current_company"];
    },

    showsUp(route) {
      /* Here it is decided whether the company sees the route or not 
      Permissions matter */

      let permission = true;
      let showUp = true;

      if (route.meta.permission) {
        // a permission is needed
        if (this.permissions.indexOf(route.meta.permission) < 0) {
          permission = false; // permission wäre da
        }
      }

      if (this.adminType === "admin" || this.adminType === "faceforce") {
        // admin gets it all
        permission = true;
      }

      // now check, if item shows generally up ...

      /* if (route.name === "mitfitPAY" && this.hasShop !== "1") {
        showUp = false;
      } */

      if (route.name === "mitfitContracts" && this.hasShop !== "1") {
        showUp = false;
      }

      /* if (route.name === 'jmp_fitness' && ((this.isJMP !== '1' && this.isJMP !== '2'))) {
        showUp = false
      } else if (route.name === 'jmp_golf' && (this.isGolfJMP !== '1' || this.isJMP === '')) {
        showUp = false
      } else if (route.name === 'mitfitPAY' && this.hasShop !== '1') {
        showUp = false
      } else if (route.name === 'mitfitContracts' && this.hasShop !== '1') {
        showUp = false
      } else {
        showUp = true
      } */

      if (permission && showUp) {
        return true;
      }

      return false;
    },

    refreshStoreData() {
      /* refresh VUEX-Store values after changeing company*/
      this.isFitnessJMP = this.$store.getters["company/fitness_jmp"];
      this.isGolfJMP = this.$store.getters["company/golf_jmp"];
      this.isPhysioJMP = this.$store.getters["company/physio_jmp"];
      this.isJMP = this.$store.getters["company/jmp"];
      this.hasShop = this.$store.getters["company/shop"];
    },

    async changeCompany() {
      /* tell server that company has changed 
      im Format "Content-Type: application/json". 
      Im Header muss "Token:{token}" mitgeschickt werden, da ja keine öffentliche Route.
      */

      if (this.companySelect) {
        let route = "sessionsByToken/" + store.getters["auth/manageToken"];

        var params = new URLSearchParams();
        params.append("current_companies_id", this.companySelect.id); // das komplette JSON übergeben

        /* USEING REUSEABLE MIXIN METHOD */
        await this.patchRequest(`${route}`, params); // await data from mixin -> Formdata: title, prio,

        this.$store.commit("auth/SET_CURRENTCLIENT", this.companySelect.id);

        await this.companySetup(); // Has to be AWAIT, because the refreshStoreData() method needs complete data in store
        this.refreshStoreData(); // refresh data from store
      }
    },
  },

  mixins: [manageApi, companyData],
};
</script>

<style scoped>
#logobox {
  height: 48px;
  width: 100%;
}
.v-application .white.v-bar--underline {
  border-bottom-color: rgba(0, 0, 0, 0.12) !important;
}
</style>
