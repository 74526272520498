<template>
  <div>
    <v-row v-if="newAdmin !== '1'" no-gutters class="mt-4">
      <v-col cols="11">
        <v-form
          ref="form"
          v-model="isFormValid"
          v-on:submit.prevent="safeNote()"
        >
          <v-text-field
            v-model="note"
            :label="$t('addNote')"
            :rules="[rules.required, rules.min]"
            outlined
            clearable
          ></v-text-field>
        </v-form>
      </v-col>
      <v-col cols="1">
        <v-btn
          v-if="isFormValid"
          class="mx-2"
          fab
          dark
          small
          color="green"
          @click="safeNote"
        >
          <v-icon dark>mdi-floppy</v-icon>
        </v-btn>

        <v-btn v-else class="mx-2" fab small color="green" disabled>
          <v-icon dark>mdi-floppy</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-list max-height="300px" style="overflow: auto" v-if="!fetchingData">
      <template v-for="(note, index) in notelist">
        <v-list-item :key="note.id">
          <v-list-item-content>
            <v-list-item-title class="font-weight-bold">
              {{ note.admin_firstname }} {{ note.admin_lastname }},
              {{ note.datum }} {{ $t("oClock") }}
            </v-list-item-title>

            <span v-html="note.note" class="mt-1"></span>
          </v-list-item-content>
        </v-list-item>
        <v-divider :key="index"></v-divider>
      </template>
    </v-list>
    <v-progress-linear v-else indeterminate color="primary"></v-progress-linear>
  </div>
</template>

<script>
import apiRequest from "@/mixins/apiRequest"; // import mixin (for api requests)
import helpers from "@/mixins/helpers"; // import mixin (for api requests)
import store from "@/store";
export default {
  name: "ActivityNotes",

  components: {
    //
  },

  data() {
    return {
      fetchingData: false, // should be true when fetching data
      companyID: store.getters["auth/current_sid"],
      newAdmin: store.getters["auth/newAdmin"],
      addNote: this.$t("addNote"),
      note: "",
      notelist: [],
      isFormValid: false,
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 3 || "Min 3 characters",
      },
    };
  },

  props: ["notes", "data", "id"],

  created: function () {
    this.getData();
  },

  methods: {
    async getData() {
      /* fetch data from api */

      this.fetchingData = true;
      let response = await this.getRequest(
        "companies/" + this.companyID + "/legacy-personActivity/" + this.id
      ); // await data from mixin
      this.fetchingData = false;
      this.notelist = response.data.data.person_game_data[0].gameNotes;
    },

    async safeNote() {
      if (this.isFormValid) {
        const formData = new FormData();
        formData.append("note", this.note);

        this.fetchingData = true;
        let response = await this.postRequest(
          `companies/${this.companyID}/legacy-personActivity/${this.data.games_data_gamerId}/${this.data.games_data_gameid}`,
          formData
        ); // await data from mixin
        this.fetchingData = false;

        if (response.data.status === "ok") {
          /* CALL SNACKBAR */
          this.$root.snackbarGlobal.snackbar = true;
          this.$root.snackbarGlobal.snackbarText = this.$t("messageUpdated");
          this.$root.snackbarGlobal.errorMessage = "";
          this.$root.snackbarGlobal.snackbarColor = "primary";
          this.$root.snackbarGlobal.snackbarTimeout = 5000;

          this.note = "";
          this.$refs.form.resetValidation();

          /* via emit gebe ich das event zurück, da ich mangels daten den array nicht mutieren kann.
          durch das emit (2 ebenen) starte ich den fetch erneut */
          this.$emit("emitted", "noteAdded");
        }
      }
    },

    transformToTrueFalseState(value) {
      if (!value) {
        return false;
      } else {
        if (value === "nein" || value === "no" || value == null || value == 0) {
          return false;
        } else {
          return true;
        }
      }
    },
  },

  mixins: [apiRequest, helpers],
};
</script>
